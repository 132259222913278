import React, { Fragment, useEffect, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import incoming from "./assets/images/incoming.svg";
import outgoing from "./assets/images/outgoing.svg";
import missed from "./assets/images/missed.svg";
import phone from "./assets/images/phone.svg";

const ConversationConfigTitle = (props) => {
    let { rowData, nameLabelMapper } = props;
    useEffect(() => {
        (async () => {

        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);
    if(rowData === undefined || rowData.length === 0){
        return(
            <Fragment></Fragment>
        )
    }
    let callerAgentLabel = nameLabelMapper['table_250_0_table_250_id'] !== undefined ? nameLabelMapper['table_250_0_table_250_id'] : "";
    let callerNoLabel = nameLabelMapper['Caller_No'] !== undefined ? nameLabelMapper['Caller_No'] : "";
    let callerNumLabel = nameLabelMapper["displayNumber"] !== undefined ? nameLabelMapper['displayNumber'] : "";
    let callerNameLabel = nameLabelMapper['Receiver'] !== undefined ? nameLabelMapper['Receiver'] : "";

    let callDateLabel = nameLabelMapper["Start_DateTime"] !== undefined ? nameLabelMapper['Start_DateTime'] : "";
    let callTypeLabel = nameLabelMapper["Call_Type"] !== undefined ? nameLabelMapper['Call_Type'] : "";
    let durLabel = nameLabelMapper["Durationsec"] !== undefined ? nameLabelMapper['Durationsec'] : "";
    let icon = incoming;
    if (rowData[callTypeLabel].toLowerCase() === "web call" || rowData[callTypeLabel].toLowerCase().includes("outbound")) {
        icon = outgoing;
    } else if (rowData[callTypeLabel].toLowerCase() === "missed"){
        icon = missed;
    }

    let title = (rowData[callerNameLabel] !== undefined && rowData[callerNameLabel] !== "") ? rowData[callerNameLabel] : (rowData[callerNumLabel] !== undefined && rowData[callerNumLabel] !== "") ? rowData[callerNumLabel]: "Web Call" 
    let cTypeVal = "Web Call";
    if(rowData[callTypeLabel].toLowerCase() == "outbound call"){
        cTypeVal = "Outbound";
    }else if(rowData[callTypeLabel].toLowerCase() == "inbound call"){
        cTypeVal = "Inbound";
    }
    return (
        <Fragment>
            <div className="container-heading">
                <span>
                    <span className="user-pic">
                        <img src={icon} />
                    </span>
                    <span className="call-details">
                        <span className="call-details-type">
                            {title}
                        </span>
                        <span className="call-details-direction">{cTypeVal}</span>
                    </span>
                </span>
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationConfigTitle);
