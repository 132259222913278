import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import SplitCommon from '../Common/SplitCommon';
import { prepareLabelNameMapper, showToast,formatTime, getSessionValue, syncOperation } from '../../Common/Appcommon';
import AIAgentsSideBar from './AgentsSideBar';
import { populateFormDetails, getTemplatesFormDetails, loadFormData,getRetelllToken,updatewebAgentId} from '../../Common/Appcommon';
import AgentMainContent from './AgentMainContent';
import {providerConn,getProviderKey,updateBlandAgent,generateBlandAgen,fetchPhNum,generateBlandAgent,getAssistantDetails} from '../Agents/ProviderDetails';
import "./assets/index.css";
import AgentMainHeader from "./AgentMainHeader";
import norecords from "../../assets/utils/images/agent_no_records.png"
import requestApi from '../../services/requestApi.service';
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png"

import call_cancel from "./assets/images/call-cancel.svg";
import men_user from "./assets/images/men_user.svg";
import { Popover} from "reactstrap";
class Agents extends React.Component {
    formname = "table_250";
    constructor(props) {
        super(props);
        this.state = {
            formData : [],
            formDetails: [],
            templates: [],
            selectedTemplate: [],
            listFields: [],
            page: 1,
            limit: 50,
            rowData: {},
            nameLabelMapper: {},
            sideBarLoader : false,
            mainLoader : false,
            currentagentindex: 0,
            isNoDataFound : false,
            currentagentindex: 0,
            assistantId : "",
            popoverOpen: false,
            isCallReady: false,
            callStartTime:"",
            cDur :"",
            showLoading : false,
            providerCon :{},
            provierName : "",
            blandCon : "",
        }
    }

    componentDidMount = async () => {
        let proName = getSessionValue("agent_provider_name");
        let proCon = "";
        if(proName != "bland"){
            proCon = await providerConn(proName);
        }
        
        this.setState({
            sideBarLoader: true,
            mainLoader : true,
            providerCon: proCon,
            provierName :proName,
        });
        await syncOperation("START_AGENT_SYNC");
        await this.fetchFormDetails();
        await this.fetchAIAgents();
        this.setState({
            sideBarLoader: false,
            mainLoader : false,
        });
        let blandCon = this.state.blandCon;
        if(proName.toLowerCase() == "vapi" && proCon !== ""){
            proCon.on('error', (e) => {
                clearInterval(this.state.intervalId);
                proCon.stop();
                this.setState({
                    showLoading : false,
                });
                showToast("ERROR! "+e,'top-right','error');
                this.setCallEndProcess();
            })

            proCon.on("call-end", () => {
                this.setCallEndProcess();
            });
            proCon.on("call-start", () => {
                this.callStartProcess();
            });
    
        }else if(proName.toLowerCase() == "retell" && proCon !== ""){
            proCon.on("error", (error) => {
                clearInterval(this.state.intervalId);
                this.setState({
                    showLoading : false,
                });
                showToast("ERROR! " +error,'top-right','error');
                proCon.stopCall();
                this.setCallEndProcess();
            });

            proCon.on("call_started", () => {
                    this.callStartProcess();

              });

            proCon.on("call_ended", () => {
                this.setCallEndProcess();
            });
              
        }else if(proName.toLowerCase() == "bland" && blandCon !== ""){
            blandCon.on('conversationStarted', (e) => {
            });
            
            blandCon.on('speaking', () => {
            });
        
            blandCon.on('conversationEnded', () => {
                this.setCallEndProcess();
            });

            blandCon.on('error', (error) => {
                showToast("ERROR! An error occurred during the conversation",'top-right','error');
                this.setState({
                    showLoading : false,
                });
                this.setCallEndProcess();
                console.error('An error occurred during the conversation:', error);
            });

        }
    };

    fetchFormDetails = async () => {
        let formDetail = await populateFormDetails(this.formname);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail);
        this.setState({
            formDetails: formDetail,
            templates, 
            selectedTemplate
        })
    };

    fetchAIAgents = async () => {
        let { formDetails,selectedTemplate } = this.state;
        let agentData = await loadFormData(this.formname, formDetails, selectedTemplate);
        if (agentData.issuccess) {
            let { formData, listFields } = agentData;
            let nameLabelMapper = await prepareLabelNameMapper(formDetails[1]);
            let rowData = formData !== undefined && formData[0] !== undefined ? formData[0] : {};
            this.setState({
                formData, listFields, nameLabelMapper, rowData, isNoDataFound: Object.keys(rowData).length === 0
            })
        }
    }

    onAgentChange = (agent, index) => {
        this.setState({
            rowData : agent,
            currentagentindex: index
        })
    }

    getSideBarTitleContent = (agentcount = 0) => {
        return(
            <>
                <span>Agents<span class="count">{agentcount}</span></span>
            </>
        )
    }

    handleDropDownChange = (value,option) => {
        if(value == "webcall"){
            this.startCall();
        }else{
            showToast("Coming Soon!");
        }
    };

    togglePopover = async () => {
        this.setState(prevState => ({
            popoverOpen: !prevState.popoverOpen
        }));
    };


    callStartProcess = () => {
        let startTime = new Date();
        this.setState({ 
            isCallReady: true, 
            callStartTime: startTime, 
            cDur: "0:00",
            showLoading: false,
        }, async () => {
            await this.togglePopover();
        });
        
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        
        let intervalId = setInterval(() => {
            let currentTime = new Date();
            let duration = Math.floor((currentTime - startTime) / 1000);
            let formattedDuration = formatTime(duration);
            this.setState({ cDur: formattedDuration });
        }, 1000);

        this.setState({ intervalId });
    }

    startCall = async () => {
        this.setState({ showLoading: true });
        try{
            let {providerCon,provierName} = this.state;
            if(provierName !== "bland" && providerCon == ""){
                showToast("ERROR! Unable to Establish Web Call Connection",'top-right','error');
                return;
            }else if (this.state.isCallReady) {
                showToast('You’re already on a call! Please finish that one before starting a new web call.');
                return; 
            }
            let {rowData,nameLabelMapper} = this.state;
            let agentId = rowData[nameLabelMapper['Agent_ID']];
            if(provierName == "vapi"){
                let pubKey = getProviderKey(this.state.provierName);
                let agentDetails = await getAssistantDetails(pubKey,agentId);
                if(agentDetails != null){
                    this.initiateVapiCall(providerCon,agentId);
                }
            }else if(provierName == "bland"){
                let agId = rowData[this.formname + "_id"];
                let apiKey = getProviderKey(this.state.provierName);
                let num = await fetchPhNum(agId,this.formname);
                if(typeof agentId !== "undefined" && agentId != null && agentId !== ""){
                    agentId = await updateBlandAgent(apiKey,agentId,num,rowData,nameLabelMapper);
                }else{
                    agentId = await generateBlandAgent(agId,apiKey,num,rowData,nameLabelMapper);
                }
              
                let proCon = await providerConn(provierName,agentId);
                this.setState({
                    blandCon: proCon,
                });
                
                if(proCon != ""){
                    this.initiateBlandCall(proCon);
                }
            }else if(provierName = "retell"){
                this.initiateRetailsAiCall(providerCon,agentId);
            }
        }catch(error){
            this.setState({ showLoading: false });
        }
       
    };


    initiateVapiCall = (proCon,agentId) =>{
        try{
            proCon.start(agentId);
            this.setState({
                showLoading: true,
            });
        }catch(error){
            this.setState(prevState => ({
                showLoading: false
            }));
            console.error("Error during Vapi call:", error.message || error);
        }
    } 

    initiateRetailsAiCall = async (proCon, agentId) => {
        try {
            let apiKey = getProviderKey(this.state.provierName);
            let res = await getRetelllToken(apiKey, agentId);
            if (res.issuccess) {
                let retailsDetails = res.data;
                let retellToken = retailsDetails['access_token'];
                proCon.startCall({ accessToken: retellToken });
            } else {
                this.setState({ showLoading: false });
                showToast("ERROR! Failed to get access token.", 'top-right', 'error');
            }
        } catch (error) {
            this.setState({ showLoading: false });
            console.error("Error during Retail AI call:", error.message || error);
        }
    }
    
    initiateBlandCall = (proCon) => {
        proCon.initConversation({
            sampleRate: 44100
        })
        .then(() => {
            this.callStartProcess();
        })
        .catch((error) => {
            console.error('Error initiating call:', error);
        });
    } 

    stopCall = () => {
        let {providerCon,provierName,blandCon} = this.state;
        if(provierName == "vapi"){
            providerCon.stop();
        }else if(provierName == "bland"){
            blandCon.stopConversation();
            this.setCallEndProcess();
        }else if(provierName == "retell"){
            providerCon.stopCall();
        }
    };

    setCallEndProcess = () => {
        clearInterval(this.state.intervalId);
        this.setState({ 
            isCallReady: false, 
            callStartTime: "", 
            cDur: "0:00",
            showLoading: false,
        },async () => {
            await this.togglePopover();
        });
        showToast("The call has ended.");

    }
    
    submitForm = async (data) => {
        let {nameLabelMapper, formData, currentagentindex} = this.state;
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_AGENT_DETAILS",
            FORMINPUT: data,
            nameLabelMapper: nameLabelMapper,
            isOtherApiReq: true
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                formData[currentagentindex] = data;
                this.setState({
                    rowData: data,
                    formData: formData
                })
                showToast("Success! Updated successfully.")
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }

    handleRowChange = (name, value) => {
        this.setState(prevState => {    
            const updatedRowData = {
                ...prevState.rowData,
                [name]: value,
            };    
            return {
                rowData: updatedRowData
            };
        });
    };

    getTitleContent = () => {
        return(
            <>
                <span>Agents</span>
            </>
        )
    }
    
    render() {
        let {formData, rowData, sideBarLoader, mainLoader, nameLabelMapper, isNoDataFound,cDur,popoverOpen,isCallReady,showLoading} = this.state;
        let agentcount = formData && formData.length ? formData.length : 0;
        if(isNoDataFound && Object.keys(rowData).length === 0){
            return (
                <NoRecordFound 
                    title="AI Agents"
                    titleContent={this.getTitleContent()}
                    img={aiData}
                    content={"No AI Agent Found"}
                />
            )
        }
        return (
            <Fragment>
                <SplitCommon
                    sideBarLoader = {sideBarLoader}
                    mainLoader = {mainLoader}
                    title="AI Agents"
                    sideBarTitleContent={
                        this.getSideBarTitleContent(agentcount)
                    }
                    mainTitleContent={
                        <>
                            {
                                Object.keys(rowData).length > 0 && <AgentMainHeader
                                    rowData={rowData}
                                    fieldNames={nameLabelMapper}
                                    handleDropDownChange={this.handleDropDownChange}
                                    isLoading = {showLoading}
                                />
                            }
                        </>
                    }
                    sideBarContent={
                        <>
                            {
                                agentcount > 0 && Object.keys(rowData).length > 0 && 
                                <AIAgentsSideBar
                                    formname={this.formname}
                                    formData={formData}
                                    curRowId={rowData[this.formname+"_id"]}
                                    onAgentChange={this.onAgentChange}
                                    fieldNames={nameLabelMapper}
                                />
                            }
                        </>
                    }
                    mainContent={
                        <Fragment>
                            {
                                Object.keys(rowData).length > 0 &&
                                <AgentMainContent 
                                    rowData={rowData} 
                                    onChange={this.handleRowChange}
                                    onSubmit={this.submitForm} 
                                    fieldNames={nameLabelMapper}
                                />
                            }
                                <div className='app-footer-right agent-footer'>
                                    <div id="WebCall"></div>
                                        {isCallReady && (
                                            <Popover
                                                className="rm-max-width"
                                                innerClassName="agent-popover"
                                                placement="bottom-start"
                                                fade={false}
                                                hideArrow
                                                isOpen={popoverOpen}
                                                target="WebCall"
                                                toggle={this.togglePopover}
                                            >
                                                <div className='agent-popover-heading'>
                                                    <span className='agents-call-details'>
                                                        <img className='agent-user-img' src = {men_user}/>
                                                        AI Agent
                                                    </span>
                                                    <span>{cDur|| "0:00"}</span>
                                                </div>
                                                <div className='agent-popover-footer'>
                                                        <img className="agent-cancel-call" src={call_cancel} onClick={this.stopCall}/>
                                                </div>
                                            </Popover>
                                        )}
                                        
                                </div>
                        </Fragment>
                    }                           
                />
        </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
