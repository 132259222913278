import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "../../Components/Login";
import Dashboard from "../../Components/Dashboard";
import Agents from "../../Components/Agents";
import Conversations from "../../Components/Conversations";
import Goal from "../../Components/Analysis/Goal";
import ABTest from "../../Components/Analysis/ABTest";
import LostCall from "../../Components/Analysis/LostCall";
import PhoneNumber from "../../Components/Settings/PhoneNumber";
import Users from "../../Components/Settings/Users";
import General from "../../Components/Agency/General";
import Subaccounts from "../../Components/Agency/Subaccounts";
import CustomDomain from "../../Components/Agency/CustomDomain";
import Usage from "../../Components/Usage";
import Profile from "../../Components/Profile";
import SiteLogin from "../../Site/Login/index";
import ResetPassword from "../../Site/ForgotPassword/resetpassword";
import ForgotPassword from "../../Site/ForgotPassword/index";
import PricingList from "../../Components/Agency/PricingList";
import Payment from "../../Components/Agency/Payment";
import Subscription from "../../Components/Subscription";
import InviteForm from "../../Site/Signup";
import Contacts from "../../Components/Contacts/Contacts";
import ActivateSignup from "../../Site/Signup/ActivateSignup";
import Payments from "../../Components/Subscription/Payments";


const AppMain = () => {

    return (
        <Fragment>
            <Route path="/login_check" component={Login}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/agents" component={Agents}/>
            <Route path="/calls" component={Conversations}/>
            <Route path="/goals" component={Goal}/>
            <Route path="/abtesting" component={ABTest}/>
            <Route path="/lostcalls" component={LostCall}/>
            <Route path="/numbers" component={PhoneNumber}/>
            <Route path="/users" component={Users}/>
            <Route path="/general" component={General}/>
            <Route path="/subaccounts" component={Subaccounts}/>
            <Route path="/custom_domain" component={CustomDomain}/>
            <Route path="/pricing" component={PricingList}/>
            <Route path="/payment" component={Payment}/>
            <Route path="/usage" component={Usage}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/subscription" component={Subscription}/>
            <Route path="/contacts" component={Contacts}/>
            <Route path="/payments" component={Payments}/>
            <Route exact path="/" render={() => (
                <Redirect to="/login_check"/>
            )}/> 
            <Route path="/login" component={SiteLogin}/>
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/activate/:activate_code" component={ActivateSignup} />
            <Route path="/invite/:invite_code" component={InviteForm} />
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
