import { Slide, toast } from "react-toastify";
import swal from "sweetalert";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { format } from 'date-fns';
import parseHtml from 'html-react-parser';
import { loadStripe } from "@stripe/stripe-js";
import requestApi from "../services/requestApi.service";
import warningIcon from "../assets/utils/images/exclamation.png";
import dateFormat from 'dateformat';
import info from "../assets/utils/images/info.png";
import warning from "../assets/utils/images/warning.png";
import error from "../assets/utils/images/error.png";
import success from "../assets/utils/images/success.png";
import TooltipItem from "./ToolTip";

class Appcommon {

}
const FIELD_NAME_INDEX = 2;
const FIELD_LABEL_INDEX = 3;

export function showToast(msg, position = "top-right", type = "success", onclosecb, autoclose = 2000, header = "") {
    let icon = success;
    switch (type) {
        case "success":
            header = "Success";
            icon = success;
            break;
        case "error":
            header = "Error";
            icon = error;
            break;
        case "info":
            icon = info;
            header = "Icon";
            break;
        case "warning":
            header = "Warning";
            icon = warning;
    }
    header = "";
    const msgContent = (header, body) => {
        return (
            <div>
                <div>
                    <strong>{header}</strong>
                </div>
                <div>
                    {body}
                </div>
            </div>
        )
    }

    toast(
        msgContent(header, msg)
        , {
            theme: "light",
            position: position,
            autoClose: autoclose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: onclosecb,
            type: type,
            transition: Slide,
            icon: <img src={icon} className="custome-show-toact-icon" />
        });
}


export function setSessionValue(key, value) {
    let prefix = getHostPrefix();
    localStorage.setItem(prefix + key, value);
}

export function getSessionValue(key) {
    let prefix = getHostPrefix();
    let sessionValue = (prefix + key !== undefined && prefix + key !== null && prefix + key !== '') ? prefix + key : key;
    return localStorage.getItem(sessionValue) === null ? '' : localStorage.getItem(sessionValue);
}

export function clearSessionValue() {
    localStorage.clear();
}

export async function confirmBox(title, message, type = 'warning', btntxt = "OK", cancelText = "Cancel") {
    return await swal({
        title: title,
        text: message,
        icon: type,
        buttons: {
            cancel: {
                text: cancelText,
                value: false,
                visible: true,
                className: "btn btn-link",
                closeModal: true,
            },
            confirm: {
                text: btntxt,
                value: true,
                visible: true,
                className: "btn btn-lg text-bold theme-bg-g",
                closeModal: true
            }
        },
        dangerMode: false,
        confirmButtonColor: "green"
    });
}

export async function confirmBoxWithImage(title, message, imageIcon = "", btntxt = "OK", cancelText = "Cancel", cancelBtn = "", confirmBtn = "", closeOnClickOutside = true, closeOnEsc = true, needToClose = false) {
    return await swal({
        title: title,
        text: message,
        icon: imageIcon === "" ? warningIcon : imageIcon,
        buttons: {
            cancel: cancelBtn === "" ? {
                text: cancelText,
                value: false,
                visible: true,
                className: "",
                closeModal: true,
            } : cancelBtn,
            confirm: confirmBtn === "" ? {
                text: btntxt,
                value: true,
                visible: true,
                className: "",
                closeModal: true
            } : confirmBtn,
            closeBtn: {
                text: 'X',
                value: 'Close',
                visible: needToClose === true ? true : false,
                className: "swal-button--cancel custom-cancel-button",
                closeModal: true,
            },
        },
        imageWidth: 50,
        imageHeight: 50,
        imageAlt: 'Warning',
        closeOnClickOutside: closeOnClickOutside,
        closeOnEsc: closeOnEsc,
    });
}

let linkData = [];
export function setLinkData(ld) {
    linkData = ld;
}

export function getLinkData() {
    return linkData;
}

export function getServerURL() {
    let appHost = window.location.hostname;
    let protocol = window.location.protocol;
    let subName = "/dacamweb";
    let baseUrl = protocol + "//" + appHost+"/server";
    if (appHost !== "localhost") {
        // baseUrl = getServerDomainURL();
    }else{
        baseUrl = protocol + "//" + appHost;
    }
    return baseUrl + subName;
}


export function getServerDomainURL() {
    let appHost = window.location.hostname;
    let serverUrl = process.env.REACT_APP_SERVER_URL;
    if (appHost.indexOf("cratio.com") > 0) {
        serverUrl = process.env.REACT_APP_NEW_SERVER_URL;
    }
    return serverUrl;
}

export async function populateFormDetails(formname, subAccountAPPID = "") {
    let params = {
        "ENTITY": "ApplDirectory",
        "OPERATION": "FormDetail",
        "formnames": [formname],
        "Isbuilderreq": false,
        "ISAPIREQ": true,
        "isSubAccount": false,
    }
    if (subAccountAPPID !== "") {
        params['sub_accountId'] = subAccountAPPID;
        params['isSubAccount'] = true;
    }
    let formData = await requestApi.processServerRequest(params);
    if (formData.issuccess && formData.data.length > 0) {
        let formResponse = formData.data;
        if (formResponse[1] !== undefined) {
            let moduleDetails = formResponse[1];
            let formDetail = moduleDetails[2][0];
            return formDetail;
        }
    }

    return [];
}

export async function getTemplatesFormDetails(formDetail, templateName = "") {

    let selectedTemplate = '';
    let templates = [];
    if (formDetail && formDetail.length > 0) {
        templates = formDetail[4];
        let lastTemplate = formDetail[8].length > 0 ? formDetail[8][0] : [];
        let lastSelectedTemplateId = lastTemplate.length > 0 ? lastTemplate[2] : '';
        for (let key in templates) {
            let template = templates[key];
            if (templateName !== "") {
                if (templateName === template[1]) {
                    selectedTemplate = template;
                }
            } else {
                if (lastSelectedTemplateId === template[0]) {
                    selectedTemplate = template;
                } else if (lastSelectedTemplateId === "") {
                    lastSelectedTemplateId = template[0];
                    selectedTemplate = template;
                }
            }
        }
    }

    return { selectedTemplate, templates }
};

export async function getFormDetails(formname, details) {
    let formDetail = [];
    for (let key in details) {
        for (let dupkey in details[key][0]) {
            if (dupkey === formname) {
                formDetail = details[key][0][dupkey];
                return formDetail;
            }
        }
    }
    return formDetail;
}

export function parseJSON(str) {
    let res = {};
    try {
        if (str !== "" && str !== null) {
            res = JSON.parse(str);
            if (typeof res === 'string') {
                res = JSON.parse(res);
            }
        }
    } catch (e) {
        //console.log(e);
    }
    return res;
}

export async function getGeneralSettings(key, refresh = false) {

    let settings = {};
    let response = "";
    let generalSettings = getSessionValue('generalSettings');

    if (generalSettings !== "" && !refresh) {
        try {
            settings = parseJSON(generalSettings);
        } catch (e) { }
    } else {
        //update general settings if not found in session.
        let params = {
            "ENTITY": "WHATSAPPPROCESS",
            "OPERATION": "GET_GENERALSETTINGS"
        }
        let responseData = await requestApi.processServerRequest(params);

        if (responseData.issuccess) {
            response = responseData.data;
            for (let i in response) {
                settings[response[i][1]] = response[i][2];
            }
            setSessionValue('generalSettings', JSON.stringify(settings));
        } else {
            handleErrorResponse(responseData.errormsg);
        }
    }

    if (settings[key] !== undefined) {
        response = settings[key];
    }

    return response;
}

export function handleErrorResponse(message) {
    if (message.trim() !== "") {
        showToast(message, 'top-right', "error");
    }
}

export function getLogoutURL() {
    let appHost = window.location.hostname;
    let baseUrl = 'https://'+appHost+"/#/login";
    //baseUrl = process.env.REACT_APP_CRATIO_LOGIN_URL;
    //if (appHost.indexOf("cratio.com") > 0) {
    //    baseUrl = process.env.REACT_APP_CRATIO_NEW_LOGIN_URL;
    //}
    return baseUrl;
}

export function getHostPrefix() {
    let serverUrl = getServerDomainURL();
    let prefix = "local_";
    if (serverUrl.indexOf("voicemetrics.ai") !== -1) {
        serverUrl = serverUrl.replace("https://", "");
        let urlArr = serverUrl.split(".");
        if (urlArr.length > 1) {
            prefix = urlArr[0] + "_";
        }
    }
    return prefix;
}

export function clearLegacyCookie() {
    let prefix = getHostPrefix();
    document.cookie = prefix + "loginusername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
    document.cookie = prefix + "loginusertableid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
    document.cookie = prefix + "loginappid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.voicemetrics.ai; path=/;";
}

export function getCookieByHost(name) {
    var nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length, c.length);
    }
    return ""

}

export async function validateLogin(history, isRedirectNeeded = true) {
    let prefix = getHostPrefix();
    const appid = getCookieByHost(prefix + 'loginappid');
    const usertableid = getCookieByHost(prefix + 'loginusertableid');
    let params = {
        "ENTITY": "ai_login",
        "APPID": appid,
        "usertableid": usertableid,
    }
    let res = await requestApi.processServerRequest(params);
    if (res.issuccess) {
        let loginres = res.data;
        if (loginres.length > 0) {
            let menus = loginres[5];
            let userName = loginres[1];
            let email = loginres[2];
            let orgData = loginres[4];
            let agencyDetails = loginres[6];
            let apiDetails = loginres[7];
            let defaulturl = loginres[8];
            let errormsg = loginres[9];
            let licenseinfo = loginres[10];
            setSessionValue('menus', JSON.stringify(menus));
            setSessionValue('loginemail', email);
            setSessionValue('loginuser', userName);
            setSessionValue('licenseinfo', licenseinfo);
            updateOrganizationInfo(orgData);
            if(loginres[11] !== undefined && loginres[11] !== null){
                setSessionValue('sessionid', loginres[11]);
            }else{
                setSessionValue('sessionid', '');
            }
            updateAgencyInfo(agencyDetails);
            if(errormsg.length > 0){
                setSessionValue("licenseErrorMsg", errormsg[1] ?? "false");
                setSessionValue("licenseError", errormsg[0] ?? "");
            }
            if (typeof apiDetails !== undefined && apiDetails != null && apiDetails != "") {
                let data = apiDetails['providerDetails'];
                let customProvider = apiDetails['provider'];
                setSessionValue("agent_provider_name",customProvider.toLowerCase());   
                if(data !== undefined && data.length > 0){
                    let key = customProvider.toLowerCase()+'_api_keys';
                    setSessionValue(key, data);  
                }
            }
            if (isRedirectNeeded) {
                history.push(defaulturl);
            }
            let isSubAccountView = getSessionValue("isSubAccountView");
            if(isSubAccountView !== 'true'){
                if(loginres[12]['isValidSession'] == false){
                    clearAllCookies(prefix);
                    window.location = getLogoutURL();
                } 
            }
        } else {
            window.location = getLogoutURL();
        }
    } else {
        window.location = getLogoutURL();
    }
}

export function setGeneralSettingsValues(generalSettings) {
    for (let gs = 0; gs < generalSettings.length; gs++) {
        let propertyName = generalSettings[gs][1];
        let propertyValue = generalSettings[gs][2];
        setSessionValue(propertyName, propertyValue);
    }
}

export function setLoginUserDetails(userData) {
    for (let key in userData) {
        setSessionValue("userInfo_" + key, userData[key]);
    }
}

export function setLicenseInfo(licenseInfo) {
    for (let key in licenseInfo) {
        setSessionValue("licenseInfo_" + key, licenseInfo[key]);
    }
}

export function isAddonModuleEnabled(module) {
    let currentmenu = getSessionValue("menus");
    let isEnabled = false;
    if (currentmenu !== "") {
        currentmenu = JSON.parse(currentmenu);
        let addonForms = currentmenu[currentmenu.length - 2];
        for (let af = 0; af < addonForms.length; af++) {
            if (addonForms[af][0] === module) {
                isEnabled = true;
                break;
            }
        }
    }
    return isEnabled;
}

export function setCookie(name, value, days, domain, hours = 0, minutes = 0, seconds = 0) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    // Set the time to the specified hours, minutes, and seconds
    date.setHours(hours, minutes, seconds, 0);

    // Set the cookie with the calculated expiration time
    const expires = `expires=${date.toUTCString()}`;
    if (domain !== "") {
        document.cookie = `${name}=${value};${expires};domain=${domain};path=/`;
    } else {
        document.cookie = `${name}=${value};${expires};path=/`;
    }
}


export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

export function constructEmitStr(appid, loginuserid, process) {
    let response = "";
    //console.log("APPID: "+(typeof appid));
    //appid = appid.replaceAll("_", "");
    appid = appid.replace(/_/g, "");
    response = appid + "_";
    if (loginuserid !== "") {
        response += loginuserid + "_";
    }
    response = response + process;
    return response;
}

export function isMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);
    return isMobile;
    //return (window.matchMedia && window.matchMedia("(max-width: 480px)").matches);
};

export function clearAllCookies(prefix) {
    document.cookie = prefix + "loginusername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
    document.cookie = prefix + "loginusertableid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
    document.cookie = prefix + "loginappid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.cratiocrm.com; path=/;";
}
export function getAppidFromCookie() {
    let prefix = getHostPrefix();
    let appid = getCookieByHost(prefix + 'loginappid');
    return appid;
}

export function openNewTab(url) {
    if (url) {
        window.open(url, '_blank');
    }
}

export async function loadFormData(formname, formDetail, selectedTemplate, subAccountAPPID = "", manualSearchStr = "", skipAppending = true, prevData = [], searchStr = "") {
    let page = 1;
    let limit = 50;
    let dataResponse = {
        issuccess: true,
        errormsg: "",
    };
    if (formDetail.length > 0) {
        let template = selectedTemplate;
        let formInfo = formDetail[0];
        let fieldDetails = formDetail[1];
        let moduleName = formInfo[0];
        let templateFieldOrders = template[4];
        let predefinedCondition = "";
        let params = {
            ENTITY: "Search_by_filter",
            OPERATION: "FIND",
            FORMNAME: formname,
            RECORDVALUES: limit,
            MODULENAME: moduleName,
            FORMTYPE: "",
            "Template name": template[1],
            "Template Id": template[0],
            PAGENUMBER: page,
            NUMBEROFROW: limit,
            isOtherApiReq: true,
            isotherapi: true,
            ISAPIREQ: true,
            predefinedCondition: predefinedCondition,
            isSubAccount: false,
        };
        if (searchStr !== "") {
            params = {
                ENTITY: "Search form",
                FORMNAME: formname,
                SEARCHFIELDS: searchStr,
                PREDEFINED: "",
                PAGENUMBER: page,
                NUMBEROFROW: limit,
                isOtherApiReq: true,
                isotherapi: true,
                ISAPIREQ: true,
                isSubAccount: false,
            };
        }
        if (manualSearchStr != "") {
            params = {
                ENTITY: "FORMPROCESS",
                OPERATION: "FIND",
                FORMNAME: formname,
                MODULENAME: moduleName,
                FORMTYPE: "",
                LASTSELECTEDTEMPLATE: template[0],
                PAGENUMBER: page,
                NUMBEROFROW: limit,
                SEARCHFIELDS: {
                    advanced_search_key: manualSearchStr,
                    STARTC: true,
                },
                isOtherApiReq: true,
                isotherapi: true,
                ISAPIREQ: true,
                isSubAccount: false,
            };
        }
        if (subAccountAPPID !== "") {
            params["sub_accountId"] = subAccountAPPID;
            params["isSubAccount"] = true;
        }
        let formresponse = await requestApi.processServerRequest(params, "WebV2");

        if (formresponse.issuccess) {
            let formData = formresponse.data;
            if (searchStr !== "") {
                formData = formData[0][2];
            }
            let totalCount = formData[1];
            let rowData = formData[2];
            let rowFieldsInfo = formData[3];
            //let listRowFields = rowFieldsInfo[0][3];
            let listFields = await getListFields(fieldDetails, templateFieldOrders);
            //let tempRowData = await this.reArrangeListRows(listRowFields, fieldDetails, rowData);
            dataResponse = {
                issuccess: true,
                formData: skipAppending
                    ? rowData
                    : [...prevData, ...rowData],
                listFields: listFields[0],
                fieldsSorting: listFields[1],
                totalCount: totalCount,
                showLoader: false,
            }
        } else {
            dataResponse = {
                issuccess: false,
                errormsg: formresponse.errormsg,
            };
        }
    }
    return dataResponse;
}

export async function prepareLabelNameMapper(viewFields) {
    let mapper = {};
    if (viewFields !== undefined && viewFields.length > 0) {
        viewFields.map((field) => {
            let name = field[2];
            let label = field[3];
            mapper[name] = label;
        });
    }
    return mapper;
};

export async function prepareCustomTableData(formData, listFields, isFirstLink = true, isFirstColumnFixed = true, onViewAccountSettings = () => { }) {
    let data = [];
    let column = [];
    let listNameFields = listFields[0] ?? [];
    let listLabelFields = listFields[1] ?? [];
    formData && formData.map((d, i) => {
        let item = {};
        listNameFields.map((fname, index) => {
            let value = d[fname];
            if(fname === "call_cost"){
                value = value === "" || value === null ? 0 : value;
                value = "$"+value;
            }
            item[fname] = value;
        });
        item['data_row'] = d;
        data.push(item);
    });
    listNameFields && listNameFields.map((fname, index) => {
        let columnItem = {};
        let label = listLabelFields[index];
        let name = fname;
        columnItem['name'] = label;
        if (fname === "call_minutes" || fname === "call_cost") {
            if(fname == "call_cost"){
                columnItem['minWidth'] = '106px !important';
            }else{
                columnItem['minWidth'] = '126px !important';
            }
            columnItem['name'] = <TooltipItem id={'1'} text={"Showing last 30 days data"} content={<span className="cursor-pointer head-info custom-display"> {label} <i class="fi fi-rs-info"></i></span>} />;
        }
        if (fname === "account_name") {
            columnItem['minWidth'] = '230px !important';
        }
        if (fname === "last_call_time") {
            columnItem['minWidth'] = '200px !important';
        }
        if (fname === "last_login_at") {
            columnItem['minWidth'] = '200px !important';
        }
        if (fname === "type") {
            columnItem['minWidth'] = '85px !important';
        }
        columnItem['selector'] = row => row[name];
        columnItem['sortable'] = false;
        //columnItem['grow'] = 4;
        if (index === 0 && isFirstLink) {
            columnItem["cell"] = (row) => (
                <div>
                    <a onClick={(e) => onViewAccountSettings(row)} className="cursor-pointer text-d-underline font-bold theme-c-link">
                        {row[name]}
                    </a>
                </div>
            );
            if (isFirstColumnFixed) {
                columnItem['style'] = {
                    zIndex: 1, // To make sure it's above other columns when scrolling
                    position: "sticky",
                    left: 0,
                    backgroundColor: "#fff",
                }
            }
        }
        column.push(columnItem);
    });
    return { data: data, columns: column };
}

export async function prepareTableData(formData, listFields,isFirstColumnFixed = true) {
    let tmpData = [];
    let tmpColumn = [];
    formData.map((d, i) => {
        let item = {};
        listFields.map((fd, index) => {
            let label = fd[FIELD_LABEL_INDEX];
            let name = fd[FIELD_NAME_INDEX];
            let value = d[label];
            item[name] = value;
        });
        tmpData.push(item);
    });
    listFields.map((fd, index) => {
        let columnItem = {};
        let label = fd[FIELD_LABEL_INDEX];
        let name = fd[FIELD_NAME_INDEX];
        columnItem['name'] = label;
        columnItem['alias'] = name;
        columnItem['data_row'] = formData[index];
        columnItem['selector'] = row => row[name];
        columnItem['sortable'] = false;
        if (index === 0 && isFirstColumnFixed) {
            columnItem['style'] = {
                zIndex: 1, // To make sure it's above other columns when scrolling
                position: "sticky",
                left: 0,
                //backgroundColor: "#fff",
            }
        }
        tmpColumn.push(columnItem);
    });
    return { data: tmpData, columns: tmpColumn };
}

export async function getListFields(fieldDetails, viewFieldorders) {
    let fieldOrders = [];
    let fieldSorting = [];
    if (viewFieldorders !== undefined) {
        fieldOrders = await getFieldOrdersList(viewFieldorders);
        fieldSorting = await getFieldSortingList(viewFieldorders);
    }

    let listViewFields = [];
    let viewFields = [];
    for (let fo = 0; fo < fieldOrders.length; fo++) {
        let tempFieldOrder = fieldOrders[fo];
        for (let fi = 0; fi < fieldDetails.length; fi++) {
            let field = fieldDetails[fi];
            let fieldName = field[2];
            let fieldLabel = field[3];
            let fieldType = field[4];
            if (fieldType === "reference_entityid") {
                continue;
            }
            let fieldOrder = field[22];
            if (tempFieldOrder === fieldOrder) {
                viewFields.push(field);
            }
        }
    }
    listViewFields.push(viewFields);
    listViewFields.push(fieldSorting);
    return listViewFields;
}

export async function getFieldOrdersList(viewFieldorders) {
    let viewFieldorderArr = viewFieldorders.split(",");
    let fieldOrderArr = [];
    for (let i = 1; i < viewFieldorderArr.length; i = i + 2) {
        fieldOrderArr.push(viewFieldorderArr[i - 1]);
    }
    return fieldOrderArr;
}

export async function getFieldSortingList(viewFieldorders) {
    let viewFieldorderArr = viewFieldorders.split(",");
    let fieldSortingArr = [];
    for (let i = 1; i < viewFieldorderArr.length; i = i + 2) {
        fieldSortingArr.push(viewFieldorderArr[i]);
    }
    return fieldSortingArr;
}

export function formatDate(datevalue, format = "yyyy-mm-dd HH:MM:ss") {
    return dateFormat(datevalue, format);
}


export function formatTime(duration) {
    const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor(duration % 60);
    //return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

export function getLastCustomDaysRange(dayscount = 30) {
    const today = new Date();
    const endDate = today;
    const startDate = new Date();
    startDate.setDate(today.getDate() - dayscount);

    const options = { month: 'short', day: 'numeric' };
    const formattedStart = startDate.toLocaleDateString('en-US', options);
    const formattedEnd = endDate.toLocaleDateString('en-US', options);

    return `${formattedStart} - ${formattedEnd}`;
}

export function getAgencyInfo(name = "all") {
    let res = "";

    const agencyInfo = parseJSON(getSessionValue('agencyInfo'));
    if (agencyInfo != null && typeof agencyInfo != undefined && Object.keys(agencyInfo).length > 0) {
        if (name === "all") {
            res = agencyInfo;
        } else {
            res = agencyInfo[name];
        }
    }

    return res;
}

export function getOrganizationInfo(name = "all") {
    let res = "";

    const orgInfo = parseJSON(getSessionValue('orgInfo'));
    if (orgInfo != null && typeof orgInfo != undefined && Object.keys(orgInfo).length > 0) {
        if (name === "all") {
            res = orgInfo;
        } else {
            res = orgInfo[name];
        }
    }

    return res;
}

export function updateOrganizationInfo(data, name = "all", value = "") {
    let orgInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            orgInfo = data;
        }
        orgInfo = JSON.stringify(data);
        setSessionValue('orgInfo', orgInfo);
    } else if (name !== "all" && value !== "") {
        orgInfo = getSessionValue('orgInfo');
        orgInfo = JSON.parse(orgInfo);
        orgInfo[name] = value;
        orgInfo = JSON.stringify(data);
        setSessionValue('orgInfo', orgInfo);
    }
}

export function getLicenseInfo(name = "all") {
    let res = "";
    let licenseinfo = getSessionValue('licenseinfo');
    licenseinfo = base64_decode(licenseinfo);
    licenseinfo = parseJSON(licenseinfo);
    if (licenseinfo != null && typeof licenseinfo != undefined && Object.keys(licenseinfo).length > 0) {
        if (name === "all") {
            res = licenseinfo[0];
        } else {
            res = licenseinfo[0][name];
        }
    }

    return res;
}

export function updateLicenseInfo(data, name = "all", value = "") {
    let licenseInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            licenseInfo = data;
        }
        licenseInfo = JSON.stringify(data);
        licenseInfo = base64_encode(licenseInfo);
        setSessionValue('licenseinfo', licenseInfo);
    } else if (name !== "all" && value !== "") {
        licenseInfo = getLicenseInfo();
        licenseInfo[name] = value;
        licenseInfo = JSON.stringify(data);
        licenseInfo = base64_decode(licenseInfo);
        setSessionValue('licenseinfo', licenseInfo);
    }
}

export function getPaymentDetails(name = "all") {
    let res = "";
    let licenseInfo = getLicenseInfo();
    if (licenseInfo != null && typeof licenseInfo != undefined && Object.keys(licenseInfo).length > 0) {
        let paymentdetails = parseJSON(licenseInfo["paymentdetails"]);
        if (name === "all") {
            res = paymentdetails;
        } else {
            res = paymentdetails[name];
        }
    }

    return res;
}

export function updateAgencyInfo(data, name = "all") {
    let agencyInfo = {};

    if (name === "all") {
        if (data !== undefined && data !== "" && data !== null) {
            agencyInfo = data.length > 0 ? data[0] : {};
            agencyInfo = JSON.stringify(agencyInfo);
            setSessionValue('agencyInfo', agencyInfo);
        }

    }
}


export async function uploadFileIntoS3(e, formData, name) {

    const selectedFile = e.target.files[0];
    const fileType = selectedFile.type;
    const fileSize = selectedFile.size;

    let resetInput = false;
    let formInputData = {};

    if (fileType.includes("image/") && fileSize > 5 * 1000 * 1000) {
        showToast("Error! File size must be less than 5MB.", 'top-right', 'error');
        resetInput = true;
    } else if (fileType.includes("video/") && fileSize > 16 * 1000 * 1000) {
        showToast("Error! File size must be less than 16MB.", 'top-right', 'error');
        resetInput = true;
    } else if (fileType.includes("audio/") && fileSize > 16 * 1000 * 1000) {
        showToast("Error! File size must be less than 16MB.", 'top-right', 'error');
        resetInput = true;
    } else if ((fileType.includes("text/") || fileType.includes("application/")) && fileSize > 100 * 1000 * 1000) {
        showToast("Error! File size must be less than 100MB.", 'top-right', 'error');
        resetInput = true;
    } else {
        let params = {
            "ENTITY": "WHATSAPPPROCESS",
            "OPERATION": "FILE_UPLOAD",
            "file": selectedFile,
            "isOtherApiReq": true,
        }
        let res = await requestApi.processFileUpload(params);
        if (res.issuccess) {
            let response = res.data;
            formInputData = formData;
            let fieldData = {};
            fieldData["mediaUrl"] = response.mediaUrl;
            fieldData["mediaType"] = response.mediaType;
            fieldData["fileName"] = response.mediaName;
            formInputData[name] = fieldData;
            showToast("Media Uploaded Successfully.", "top-right");
        } else {
            resetInput = true;
            showToast(res.errormsg, "top-right", "error");
        }
    }

    return { resetInput, formInputData };
}

export async function processImportNumber(importNumber, operation) {

    let page = 1;
    let limit = 50;
    let dataResponse = {
        issuccess: true,
        errormsg: "",
    };
    let formname = "table_247";
    let moduleName = 'Agent details';

    let phoneNumberId = importNumber.table_247_id;
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "PROCESS_IMPORTED_NUMBER",
        SUBOPERATION: operation,
        FORMNAME: formname,
        RECORDVALUES: limit,
        MODULENAME: moduleName,
        RECORDID: phoneNumberId,
        FORMTYPE: "",
        PAGENUMBER: page,
        NUMBEROFROW: limit,
        isOtherApiReq: true,
        isotherapi: true,
        ISAPIREQ: true,
        isSubAccount: false,
    };

    let formresponse = await requestApi.processServerRequest(params, "WebV2");
    if (formresponse.issuccess) {
        let formData = formresponse.data;

        dataResponse = {
            issuccess: true,
            formData: formData,
            showLoader: false,
        }
    } else {
        dataResponse = {
            issuccess: false,
            errormsg: formresponse.errormsg,
        };
    }
    return dataResponse;
}

export async function getReferenceList(refDetails, defaultVal = "", prefix = "") {

    if (refDetails.length > 0) {
        let params = {
            ENTITY: "FORMPROCESS",
            OPERATION: "GET_ALL_REFERENCE",
            REFMODULENAME: refDetails[0],
            REFFORMNAME: refDetails[1],
            REFFIELDNAME: refDetails[2],
            REFNTHINSTANCE: refDetails[3],
            DEFAULTDATA: defaultVal,
            SEARCHVALUE: prefix,
        };
        let response = await requestApi.processServerRequest(params, "WebV2");
        return response;

    }
};

export function isAgentAccount() {
    let account_id = getOrganizationInfo("Account_ID");
    let isAgency = false;
    if (account_id !== "" && account_id !== null && account_id.indexOf("_") > -1) {
        let accountInfo = account_id.split("_");
        if (accountInfo.length > 0 && accountInfo[0].toLowerCase() === "agent") {
            isAgency = true;
        }
    }
    return isAgency;
}

export function maskKey (value){
    value = value.toString();
    let masked = value;
    const firstUnderscore = value.indexOf('_');
    const secondUnderscore = value.indexOf('_', firstUnderscore + 1);    
    if (secondUnderscore !== -1) {
        const prefix = value.slice(0, secondUnderscore + 1);
        const suffix = value.slice(-5);
        const maskLength = value.length - prefix.length - suffix.length;
        masked = `${prefix}${'*'.repeat(maskLength)}${suffix}`;
    }
    return masked;
};

export async function getCredentials(){
    let response = [];
    const params = {
        ENTITY: "STRIPE_PROCESSOR",
        OPERATION: "GET_CREDENTIAL",
        isOtherApiReq: true,
    };
    try {
        let res = await requestApi.processServerRequest(params);
        if (res.issuccess) {
            let decode_data = base64_decode(res.data);
            let credential = parseJSON(decode_data);
            if(credential['pub_key'] !== undefined && credential['pub_key'] !== null && credential['pub_key'] !== ""){
                let pub_key = base64_decode(credential.pub_key);
                pub_key = parseJSON(pub_key);
                if(pub_key !== ""){
                    response = loadStripe(pub_key);
                }
            }
        }else if(res.errormsg){
            showToast(res.errormsg, "top-right", "error");
        }
    } catch (error) {
        console.error("Error fetching plan pricing:", error);
    }
    return response;
}

export function getCurrentDomain(){
    const hostname = window.location.hostname;
    let agencyData = [];
    const parts = hostname.split(".");
    let subDomain = "";
    if (parts.length >= 3) {
      subDomain = parts[0];
    }
    return hostname;
}

export async function fetchAgencyDetails () {
    let agencyData = [];
    let subDomain = getCurrentDomain();
    if (subDomain !== "") {
      let params = {
        'ENTITY': 'AIPROCESSOR',
        'OPERATION': 'AGENCY_INFO',
        'domain': subDomain
      };
      let response = await requestApi.processServerRequest(params, "WebV2", "POST", true);
      if (response.issuccess) {
        agencyData = response.data;
      }
    }
    return agencyData;
}

export function getLicenseErrorStatus(){
    return getSessionValue('licenseError');
}

export function getLicenseErrorMsg() {
    return getSessionValue('licenseErrorMsg');
}

export async function getHeaderNotification(){

    let notification = {
        show : false,
        text : "",
        icon : true,
        btnShow : false,
        btnText : "",
    }

    let licenseErrorStatus = getLicenseErrorStatus();
    let licenseErrorMsg = getLicenseErrorMsg();
    if(licenseErrorStatus === 'true' && licenseErrorMsg !== ""){ //show nofitfication when License has expired.
        notification.show = true;
            //notification.text = parseHtml("<p className=\"notification-text\">Action Needed! Your CRM account subscription has expired. Please subscribe to a plan to continue using your account.</p>");
        notification.text = parseHtml("<p className=\"notification-text\">"+licenseErrorMsg+"</p>");

        const curURL = window.location.href;
        if(curURL && (curURL.includes(`/#/subscription`))){
            notification.show = false;
        }

    }

    return notification;
}

export function formatDateTime(date,fstr = "MMM dd, yyyy, hh:mm:ss a"){
    if(date !== ""){
        return format(new Date(date), fstr);
    }
}

export function formatCurrency (value)  {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};
export function  tostring (value) {
        return value.toString();
};

export async function syncOperation(operation) {
    let prefix = getHostPrefix();
    const appid = getCookieByHost(prefix + 'loginappid');
    const usertableid = getCookieByHost(prefix + 'loginusertableid');
    let params = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION" : operation,
        "APPID": appid,
        "usertableid": usertableid,
    }
    let res = await requestApi.processServerRequest(params);
    if (res.issuccess) {
        
    }
}

export async function findTimeDiff(targetTime){
    const now = new Date();
    const timestamp = new Date(targetTime);
    const differenceInMs = now.getTime() - timestamp.getTime();
    const differenceInHours = parseInt(differenceInMs / (1000 * 60 * 60), 10);
    return differenceInHours;
}

export function findTimeDiffAsync(targetTime){
    const now = new Date();
    const timestamp = new Date(targetTime);
    const differenceInMs = now.getTime() - timestamp.getTime();
    const differenceInHours = parseInt(differenceInMs / (1000 * 60 * 60), 10);
    return differenceInHours;
}


export async function getCurrentPlanInfo() {
    let response = [];
    const params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GET_CURRENT_PLAN_INFO",
        isOtherApiReq: true,
    };

    try {
        let result = await requestApi.processServerRequest(params);
        if (result.issuccess) {
            let data = result.data;
            response = data;
        }
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
    }
    return response;
}

export const currencyFormatter = (contryCode = 'en-US', currency = 'USD', number,minFraction=0) => {

    let res = number;
    try{
        const formatter = new Intl.NumberFormat(contryCode, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: minFraction,
        });
        res = formatter.format(number);

    }catch(e){}

    return res;
}

export async function getRetelllToken(apiKey,AGENTID){
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "GETRETELLTOKEN",
        APIKEY : apiKey,
        AGENTID : AGENTID,
    };
    let response = await requestApi.processServerRequest(params, "WebV2");
    return response;
};

export async function updatewebAgentId(agId,webAgId){
    let params = {
        ENTITY: "AIPROCESSOR",
        OPERATION: "UPDATEWEBAGENT",
        AGENTID : agId,
        WEBAGENTID : webAgId,
    };
    let response = await requestApi.processServerRequest(params, "WebV2");
    return response;
};
export default Appcommon;
