import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { Button, Row, Col, Modal, Card, ModalHeader, ModalFooter, Label, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import unCheckedCircle from '../../../src/assets/utils/images/unchecked.png';
import checkedCircle from '../../../src/assets/utils/images/checked.png';
import requestApiService from '../../services/requestApi.service';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getOrganizationInfo, handleErrorResponse, showToast } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
class CustomDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domainName: '',
            isValid: false,
            isOpen: false,
            custom_domain_ip: '',
            setTextCopied: false,
            validDNS: false,
            isVerified: false,
            showPopup: false,
            showButtonLoading: false
        };
    }

    componentDidMount = async () => {
        this.getDNSRecords();
    };
    onInputChange = (e) => {
        const value = e.target.value;
        const isValid = value !== "" && value !== undefined;
        const isModalOpen = (value === undefined || value === "") ? false : this.state.isOpen;

        this.setState({
            domainName: value,
            isValid: isValid,
            isOpen: isModalOpen,
        });
    };
    handleSubmit = async (e) => {
        if (this.state.domainName.trim() !== '') {
            this.setState({ isOpen: true });
        }
        let accountId = getOrganizationInfo("Account_ID");
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_DNS_RECORDS",
            "domain_name": this.state.domainName,
            "account_id": accountId,
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            this.setState({
                custom_domain_ip: response.data,
                isValid: false
            })
        } else {
            handleErrorResponse(response.errormsg);
        }
    };


    getDNSRecords = async () => {
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_DNS_RECORDS",
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess && response.data.length > 0) {
            let verified = response.data[0][3] == "verified" ? true : false;
            let domainName =response.data[0][2];
            this.setState({
                validDNS: verified,
                domainName: domainName,
                isVerified: verified,
                isOpen: domainName !== "" ? true : false,
                custom_domain_ip: response.data[1]
            });
        }
    }

    checkDNSRecords = async () => {
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "CHECK_DNS_RECORDS",
            "domain_name": this.state.domainName,
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let errormsg = response.data['errormsg'];
            if (errormsg != "") {
                handleErrorResponse(errormsg);
            } else {
                let domain_name = this.state.domainName;
                showToast("Your Domain verified sucessfully.");
                setTimeout(() => {
                    this.setState({
                        validDNS: response.data['validDNS'],
                        domainName: domain_name,
                        isVerified: response.data['validDNS'],
                        isOpen: true,
                    });
                }, 1500)
            }
        } else {
            handleErrorResponse("Technical error Occured.Please try after some time.");
        }
    }
    copyUrl = () => {
        this.setState({
            setTextCopied: true
        })
    }

    customDomainConfigView = () => {
        return (
            <div className='agent-details agent_details-wid phone-numbers-container'>
                <div className="card-fields">
                    <span className="heading">Your Domains</span>
                    <span className="subheading">Manage Your Domains</span>
                </div>
                <div className='number-hover'>
                    <span className='cloud-number-name-number'>
                        <p className="name">{"Domain Name"}</p>
                        <p className="number">{this.state.domainName}</p>
                    </span>
                    <Col md={2} className='custom-display p-0'>
                        <Button autoComplete="off" color="danger" onClick={this.toggle}>
                            Remove Domain
                        </Button>
                    </Col>
                </div>
            </div>
        );

    }

    removeDomain = async () => {
        this.setState({
            showButtonLoading: true
        })
        let appid = getOrganizationInfo("appid");
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "DELETE_DNS_RECORDS",
            'account_appid': appid
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            this.setState({
                showButtonLoading: false
            })
            showToast("Domain Removed Successfully.");
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }

    };

    toggle = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    };

    getModalContent = () => {
        return (
            <div>
                <Modal isOpen={true} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Remove Domain</ModalHeader>
                    <div className='ms-3 mb-1 del-subaccount-header'>
                        Are you sure you want to permanently remove your domain? This action cannot be undone.
                    </div>
                    <div className='delete-sub-account-popup mb-1'>
                        <ModalFooter>
                            <Loading showLoader={this.state.showButtonLoading} mainContent={
                                <Fragment>
                                    <Button className='btn btn-danger' onClick={this.removeDomain}>
                                        Remove Domain
                                    </Button>
                                </Fragment>
                            } />
                        </ModalFooter>
                    </div>
                </Modal>
            </div>
        );
    };

    render() {
        return (
            <Fragment>
                <Common title="Custom Domain" titleContent={
                    <span>Custom Domain</span>
                } mainContent={
                    <div className='d-container container m-auto max-width-65'>
                        {!this.state.validDNS ?
                            <>

                                <div className='agency-details'>
                                    <div className='card-fields d-grid'>
                                        <span className="heading">Custom Domain</span>
                                        <span className='header-desc'>Easily integrate your custom domain for white labeling.</span>
                                    </div>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields d-grid'>
                                                <div className="dns-records-container">
                                                    <div className={`dns-checkbox ${this.state.isOpen ? 'dns-verified' : ''}`}>
                                                        <i className="fi fi-ss-check-circle"></i>
                                                    </div>
                                                    {/* <img className='domain-img' src={!this.state.isOpen ? unCheckedCircle : checkedCircle}></img> */}
                                                    <span className='heading'>Add Your Domain</span>
                                                </div>
                                                <span className='profile-desc cs-domain'>Name</span>
                                                <div>
                                                    <Row className='profile-desc cs-domain'>
                                                        <Col md={9}>
                                                            <input
                                                                type="text"
                                                                name="custom_domain_name"
                                                                id="custom_domain_name"
                                                                value={this.state.domainName}
                                                                autoComplete={"off"}
                                                                onChange={this.onInputChange}
                                                                className='form-control'
                                                                placeholder='app.yourdomain.com'
                                                            />
                                                        </Col>
                                                        <Col md={3} className='custom-row'>
                                                            <Button
                                                                className='domain-btn theme-bg-g'
                                                                color={this.state.isValid ? "dark" : "light"}
                                                                backgroundColor={this.state.isValid ? "light" : "dark"}
                                                                disabled={!this.state.isValid}
                                                                onClick={this.handleSubmit}
                                                            >
                                                                <FontAwesomeIcon icon={faLink} /> Connect Domain
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <span className='field-desc'>Example: app.yourdomain.com (Without specifying 'https://' or 'http://')</span>
                                                </div>

                                                <div className='card-fields d-grid mt-4'>
                                                <div className="dns-records-container">
                                                    <div className={`dns-checkbox ${this.state.isVerified ? 'dns-verified' : ''}`}>
                                                        <i className="fi fi-ss-check-circle"></i>
                                                    </div>
                                                    {/* <img className="domain-img" src={ ? checkedCircle : unCheckedCircle} alt="DNS Record Status" /> */}
                                                    <span className="heading">Add Your DNS Records</span>
                                                </div>

                                                {this.state.isOpen && (
                                                    <>
                                                        <div className=''>
                                                            <span className='profile-desc'>
                                                                Point your domain to our servers to make it work. You can use the following records.
                                                            </span>
                                                                <Row>
                                                                <Col md={9} className="mt-2">
                                                                        <CopyToClipboard onCopy={this.copyUrl} text={this.state.custom_domain_ip}>
                                                                            <Button className="w-100 btn-hover-shine custom-domain-record" color="light">
                                                                                A Record with {this.state.custom_domain_ip}
                                                                            </Button>
                                                                        </CopyToClipboard>
                                                                    </Col>
                                                                    <Col md={3} className='custom-row mt-2'>
                                                                        <Button className='theme-bg-g' autoComplete="off" color="dark" onClick={this.checkDNSRecords}>
                                                                            Check DNS Records
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <div className='dns-copy-text theme-c-g mt-2 ps-1'>
                                                                        {this.state.setTextCopied ? <span className=''>Copied!</span> : null}
                                                                </div>
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                 

                                <div className='agency-details hide'>
                                    <div className='card-fields d-grid'>
                                        <div className='dns-records-container'>
                                            <img className='domain-img' src={unCheckedCircle}></img>
                                            <span class="heading">Add Meta Tags</span>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            this.customDomainConfigView()
                        }

                    </div>
                } />
                {this.state.showPopup && this.getModalContent()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomDomain);
