import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Spinner } from "reactstrap";
import requestApi from "../../services/requestApi.service";
import { setSessionValue, showToast } from "../../Common/Appcommon";

const CheckoutForm = forwardRef((props, ref) => {
    let { isUsagePage, selectedPlan, paymentDetails, billingDetails, noofMonths } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [cardDetails, setCardDetails] = useState({});
    const [showCardElement, setShowCardElement] = useState(true);
    const [mainLoader, setMainLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isUsagePage && paymentDetails["status"] === "Active") {
            getCardDetails();
            setShowCardElement(false);
        }
    }, [isUsagePage]);

    const getCardDetails = async () => {
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "GET_CARD_DETAILS",
            isOtherApiReq: true,
        };
        try {
            setMainLoader(true);
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                setCardDetails(data);
                setMainLoader(false);
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }
    
    useImperativeHandle(ref, () => ({
        processPayment,
    }));

    /**
     * @function processPayment send to stripe via api
     * @param {*} event event trigger by on submit
     * @returns {void}
     */
    const processPayment = async () => {
        //event.preventDefault();
        setMainLoader(true);
        const cardElement = elements.getElement(CardElement);
        
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: billingDetails ? billingDetails.Name : "",
                email: billingDetails ? billingDetails.Email_id : ""
            }
        });

        if (error) {
            setError(error.message);
            setMainLoader(false);
            return;
        }

        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "CREATE_AUTH_INTENT",
            paymentMethodId: paymentMethod.id,
            billingDetails: billingDetails,
            selectedPlan: selectedPlan,
            noofMonths: noofMonths,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                let client_secret = data.client_secret ?? "";
                // Confirm the Payment Intent to authorize the payment
                const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(client_secret);
                if (confirmError) {
                    setError(confirmError.message);
                    setMainLoader(false);
                } else {
                    // Authorization was successful, proceed with capturing later
                    return capturePayment(paymentIntent.id);
                }
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
        return false;
    };

    /**
     * 
     * @param {String} paymentIntentId paymentid from stripe API
     * @returns {void}
     */
    const capturePayment = async (paymentIntentId) => {
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "CAPTURE_PAYMENT_INTENT",
            paymentIntentId: paymentIntentId,
            billingDetails: billingDetails,
            selectedPlan: selectedPlan,
            noofMonths: noofMonths,
            isAuthOnly: isUsagePage,
            isOtherApiReq: true,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess && response.data){
            if(isUsagePage){
                showToast("Method Change successfully");
            }else{
                setSessionValue("licenseError", "false");
                setSessionValue("licenseErrorMsg", "");
                showToast("Plan purchased successfully");
            }
            window.location.hash = "#/usage";
            //props.handleCheckOutBox();
            setMainLoader(false);
            return true;
        }
        return false;
    }

    const handleCardEditCall = () => {
        setShowCardElement(!showCardElement);
    }

    return (
        <Container className="checkout-box-container">
            <Form>
                <Row className="checkout-grp">
                    <Col md={12}>
                        <FormGroup className="input-group-text-box">
                            <Label>Card Details</Label>
                            {
                                showCardElement ? (
                                    <div className="checkout-card">
                                        <div className="border p-3 rounded">
                                            <CardElement />
                                        </div>
                                    </div>
                                ) : (
                                <InputGroup >
                                    <div className="input-group-text">
                                        <i class="fi fi-rr-credit-card"></i>
                                    </div>
                                    <div className="input-group-text input-group-text-width">
                                        <div className="checkout-span-group">
                                            <span>{cardDetails.brand}</span>
                                            <span>****</span>
                                            <span>****</span>
                                            <span>****</span>
                                            <span> {cardDetails.last4} </span>
                                        </div>
                                        <span className="ms-auto"> {cardDetails.exp_month}/{cardDetails.exp_year}</span>
                                    </div>
                                    <div className="input-group-text cursor-pointer">
                                        <i class="fi fi-rc-pencil" onClick={() => handleCardEditCall()}></i>
                                    </div>
                                </InputGroup>
                                )
                            }
                        </FormGroup>
                    </Col>
                </Row>

            {error && <div className="alert alert-danger">{error}</div>}
            </Form>
        </Container>
    );
});

export default CheckoutForm;