import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import Stripe from './Payment/index';

class Payment extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {

    };


    render() {
    
        return (
            <Fragment>
                <Common title={"Connect Stripe"} titleContent={
                    <span>Connect Stripe</span>
                } mainContent={
                        <Stripe />
                    
                } />


            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
