import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Slider } from "react-burgers";

import AppMobileMenu from "../AppMobileMenu";
import AppLogo from "../../assets/utils/images/logo-inverse.png";
import appLightLogo from "../../assets/utils/images/logo.png";
import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";
import { getAgencyInfo } from "../../Common/Appcommon";
import { useTheme } from "../../Context/ThemeContext";

const HeaderLogo = (props) => {
  const [active, setActive] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [activeSecondaryMenuMobile, setAtiveSecondaryMenuMobile] = useState(false);
  const [defaultLogo, setDefaultLogo] = useState(AppLogo);
  const { theme, toggleTheme } = useTheme();
  useEffect(() => {
    (async () => {
      let logoField = "Logo";
      let logo = AppLogo;
      if (theme === "dark-theme") {
        logoField = "Dark_Logo";
        logo = appLightLogo;
      }
      let agencyLogoInfo = getAgencyInfo(logoField);
      let agencyLogo = "";
      if (agencyLogoInfo !== "" && agencyLogoInfo !== undefined && agencyLogoInfo !== null) {
        //agencyLogo = agencyLogoInfo;
        if (agencyLogoInfo.indexOf(",") > 0) {
          let agentLArr = agencyLogoInfo.split(",");
          agencyLogo = agentLArr[1];
          logo = agencyLogo;
        }
      }
      setDefaultLogo(logo);
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, [theme]);

  const toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };


  return (
    <Fragment>
      <div className="app-header__logo">
        <div className="logo-src">
          <img src={defaultLogo} />
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
