import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useForm, Controller } from "react-hook-form";
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormText } from 'react-bootstrap';
import { getServerDomainURL } from './Appcommon';


const Form = (props) => {
    const { pname, fields, submitAction, formData: initialFormData, account_id } = props;
    const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const [formData, setFormData] = useState(initialFormData || {});
    const [isCopied, setTextCopied] = useState(false);
    const baseUrl = getServerDomainURL();
    let Url = "events/vmhooks.php?accountid=" + account_id;
    const webhookUrl = baseUrl + "/" + Url;

    useEffect(() => {
        setFormData(initialFormData || {});
    }, [initialFormData]);


    /*
        const numbers = [1, 2, 3, 4];
        reduce() is a method in JavaScript used to "reduce" an array into a single value, 
        typically an object, array, or even a number. 
        It applies a function to each item in the array and accumulates a result, which is passed on to the next iteration.
        // Using reduce to sum the numbers
        const sum = numbers.reduce((acc, num) => {
            return acc + num;
        }, 0);  // Initial accumulator value is 0
    */

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitProviderCredentials = async (e, name) => {
        await submitAction(name, formData);
    }
    const copyUrl = () => {
        setTextCopied(true);
    }

    return (
        <Fragment>
            <form id={`agency-form-${pname}`} name={'agency-' + pname} onSubmit={handleActionFormSubmit((data) => submitProviderCredentials(pname, data))}>
                <div className='field-details'>
                    <Row>
                        {
                            fields.map((field) => {
                                const { name, label, type } = field;
                                let fname = pname + "_" + name;
                                return (
                                    <Fragment>

                                        <div>
                                            <Col md={12} className={(pname === "vapi" || pname === "bland" || pname === "retell") ? 'provider-top' : "field-section mt-2"}>
                                                <label className={'form-label'} htmlFor={fname}>{label}</label>
                                                <Controller
                                                    name={fname}
                                                    control={control}
                                                    rules={{ required: (formData[fname] === undefined || formData[fname] === "") ? "Please provide " + label : "" }}
                                                    render={({ field }) => {
                                                        return (
                                                            <input
                                                                {...field}
                                                                type={type}
                                                                name={fname}
                                                                id={fname}
                                                                value={formData[fname]}
                                                                autoComplete={"off"}
                                                                onChange={(e) => {
                                                                    onInputChange(e);
                                                                    field.onChange(e);
                                                                }}
                                                                className={`form-control ${errors[fname] ? 'is-invalid' : ''}`}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors[fname] && <em className={`error invalid-feedback`}>{errors[fname].message}</em>}
                                            </Col>

                                        </div>

                                    </Fragment>
                                )
                            })
                        }
                        {
                            (pname === "vapi" || pname === "bland" || pname === "retell") && (
                                <Col md={12} className='provider-top'>
                                    <Label>Server URL (Messaging)</Label>
                                    <div className="d-flex align-items-center">
                                        <Input value={webhookUrl} className={`google-ads-webhook-url`} disabled={true} />
                                        <CopyToClipboard onCopy={copyUrl} text={webhookUrl}>
                                            <Button className='theme-bg-g'>
                                                <FontAwesomeIcon icon={faCopy} />
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                    {isCopied && (
                                        <FormText color="success" className="mb-2 ">Text has been copied.</FormText>
                                    )}
                                </Col>
                            )
                        }
                        <div className={pname === "vapi" || pname === "bland" || pname === "retell" ? "d-flex justify-center mt-4" : 'form-footer d-block text-end mt-3'}>
                            <Button size="lg" className='theme-bg-g'>
                                {pname === "vapi" || pname === "bland" || pname === "retell" ? "Save Credentials" : "Save"}
                            </Button>
                        </div>
                    </Row>
                </div>

            </form>
        </Fragment>
    )
}

export default Form;
