import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label } from "reactstrap";
import { getTemplatesFormDetails, handleErrorResponse, loadFormData, populateFormDetails, prepareLabelNameMapper, prepareTableData, showToast, getSessionValue, getOrganizationInfo, findTimeDiff, findTimeDiffAsync } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';
import ListTable from '../../Common/ListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Common from '../Common';
import { useForm, Controller } from "react-hook-form";
import requestApiService from '../../services/requestApi.service';
import NoRecordFound from '../Common/NoRecordFound';
import aiData from "../../assets/utils/images/agent_no_records.png"

const Users = (props) => {
    let { accountInfo } = props;
    let data_row = {};
    let appid = "";
    if (accountInfo !== undefined) {
        data_row = accountInfo.data_row;
        appid = data_row.appid;
    }
    let [userFormData, setUserFormData] = useState({});
    let [userListFields, setUserListFields] = useState({});
    let [nlMapper, setNLMapper] = useState({});
    const { control, handleSubmit, formState: { errors },reset } = useForm();
    const [showLoading, setShowLoading] = useState(false);
    const [data, setData] = useState([]);
    const [column, setColumns] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [inputData, setInputData] = useState({});
    const [showButtonLoading, setshowButtonLoading] = useState(false);
    let [pflData, setPflData] = useState([]);
    let [proVal, setProVal] = useState(null);
    useEffect(() => {
        (async () => {
            await loadSubAccountUsers();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const resendInvite = async (row) => {
        let {Name, Emailid, Account_Status} = row;
        await inviteUser(Name, Emailid, true);
    }

    const loadSubAccountUsers = async () => {
        setShowLoading(true);
        let formDetail = await populateFormDetails("table_6", appid);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Active User");
        let usersData = await loadFormData("table_6", formDetail, selectedTemplate, appid);
        if (usersData.issuccess) {
            let { formData, listFields } = usersData;
            let nameLabelMapper = await prepareLabelNameMapper(formDetail[1]);
            for (let fi = 0; fi < formData.length; fi++) {
                let data = formData[fi];
                let accountLabel = nameLabelMapper['Account_Status'];
                let dataValue = data[accountLabel];
                let createdOn = data['Created On'];
                createdOn = createdOn.replace(" ", "T");
                createdOn = createdOn + "Z";
                let diffHrs = findTimeDiffAsync(createdOn);
                if (dataValue === "Invited" && diffHrs >= 48) {
                    dataValue = "Expired";
                    data[accountLabel] = dataValue;
                }
                formData[fi] = data;
            }
            let tableData = await prepareTableData(formData, listFields);
            let columns = tableData.columns;
            for (let cl = 0; cl < columns.length; cl++) {
                let column = columns[cl];
                if (column.alias === "Account_Status") {
                    column['cell'] = (row) => {
                        let dataValue = row.Account_Status;
                        let isExpired = dataValue === "Expired";
                        return (
                            <Fragment>
                                {isExpired &&
                                    <div className="account-status-info d-flex align-center">
                                        <span>{dataValue}</span>
                                        <Button className="theme-bg-g ml-2" onClick={e => resendInvite(row)}>
                                            <i class="fi fi-rr-paper-plane"></i>
                                        </Button>
                                    </div>
                                }
                                {
                                    !isExpired &&
                                    <div>
                                        <span>{dataValue}</span>
                                    </div>
                                }
                            </Fragment>
                        )
                    }
                }
                columns[cl] = column;
            }
            setData(tableData.data);
            setColumns(columns);
            setUserFormData(formData);
            setUserListFields(listFields);
            setNLMapper(nameLabelMapper);
        }   
        setShowLoading(false);
    }


    const toggleModal = () => {
        //let fromAddress = getOrganizationInfo("From_Address");
        //if (!isModalOpen && appid != "" && (fromAddress === null || fromAddress === undefined || fromAddress === "")) {
        //    showToast("Action Required! Please configure Transactional Email in profile settings before sending invites.");
        //    return;
        //}
        if (!isModalOpen) {
            setshowButtonLoading(false);
            setInputData({});
            reset();
        }
        ftchPfl();
        setProVal(null);
        setModalOpen(!isModalOpen);
    };
    const toggle = () => {
        setInputData({});
        toggleModal();
    }

    const handleInputChange = (e) => {
        let tmpInputData = { ...inputData };
        tmpInputData[e.target.name] = e.target.value;
        setInputData(tmpInputData);
    };

    const sendInviteUser = async() => {
        await inviteUser(inputData.userName, inputData.userEmail);
    }
    
    const inviteUser = async (userName, userEmail, isResend = false) => {
        if(proVal == null && !isResend){
            showToast("ERROR! Select User's Profile Name", 'top-right', 'error');
            return;
        }
        let inviteEmail = getSessionValue('loginemail');
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "INVITE_USER",
            "Invited_User": inviteEmail,
            "New_Username": userName,
            "New_UserMail": userEmail,
            "New_Profile_Data" : proVal,
        };
        if (appid !== "") {
            params['invite_user_sub_account'] = appid;
            //params['isSubAccount'] = true;
        }
        if(isResend){
            params["RESEND_INVITE"] = true;
        }
        setshowButtonLoading(true);
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if (response.issuccess) {
            setInputData({});
            showToast("Invite Sent! Check the user’s inbox.");
            await loadSubAccountUsers();
            if(!isResend){
                toggleModal();
            }
        } else {
            if (response.errormsg !== "") {
                handleErrorResponse(response.errormsg);
            } else {
                handleErrorResponse("User not invited!");
            }
            setshowButtonLoading(false);
        }
    }
    const ftchPfl = async() => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GETPROFILEDETAILS",
        };
        let response = await requestApiService.processServerRequest(params, "WebV2");
        if (response.issuccess) {
            let formData = response.data;
            if(formData != null && formData != "" && formData !== "undefined"){
                setPflData(formData);
            }
        }
    }
    const handleCheckboxChange = (data) => {
        setProVal(data);
    };

    const getModalContent = () => {
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={toggle}>Invite User
                        <div className='md-2 del-subaccount-header'>
                            Invite users to access the software. Invite email expires in 2 days.
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            <form id="agency-form" name={'agency'} onSubmit={handleSubmit((data, event) => sendInviteUser())}>
                                <Label>Name</Label>
                                <Controller
                                    name="userName"
                                    control={control}
                                    rules={{ required: (inputData.userName === undefined || inputData.userName === "") ? "Please provide Username" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="userName"
                                            autoComplete="off"
                                            placeholder="Enter User Name..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            value={inputData.userName}
                                            className={`form-control mb-2 ${errors.userName ? 'is-invalid' : ''}`}
                                        />
                                    )}
                                />
                                {errors.userName && <em className="mb-2 error invalid-feedback">{errors.userName.message}</em>}

                                <Label>Email </Label>
                                <Controller
                                    name="userEmail"
                                    control={control}
                                    rules={{ required: (inputData.userEmail === undefined || inputData.userEmail === "") ? "Please provide Email" : "" }}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            id="userEmail"
                                            type="email"
                                            autoComplete="off"
                                            placeholder="Enter Email Id..."
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                field.onChange(e);
                                            }}
                                            value={inputData.userEmail}
                                            className={`form-control mb-2 ${errors.userEmail ? 'is-invalid' : ''}`}
                                        />
                                    )}
                                />
                                {errors.userEmail && <em className="error invalid-feedback">{errors.userEmail.message}</em>}
                                <Label>Profile Name </Label>
                                <div className='inv-usr-pro-check'>
                                    {pflData.length > 0 && pflData.map((data, index) => (
                                        <Label className='inv-usr-chk-lbl' check key={index}>
                                        <input 
                                            type="radio" 
                                            className="form-check-input-custom" 
                                            id={`exampleCustomCheckbox${index}`} // Unique id for each checkbox
                                            name="profile" 
                                            value={data["table_2_id"]}
                                            onChange={() => handleCheckboxChange(data)}
                                        />
                                        {data["Profile_Name"]}
                                        </Label>
                                    ))}
                                </div>
                                <ModalFooter>
                                    <Loading showLoader={showButtonLoading} mainContent={
                                        <Fragment>
                                            <Button className='container-footer-save theme-bg-g'>
                                                Invite User
                                            </Button>
                                        </Fragment>
                                    } />
                                </ModalFooter>
                            </form>
                        </Fragment>
                    </ModalBody>

                </Modal>
            </>
        );
    }


    return (
        <Fragment>
            {appid !== "" &&
                <Fragment>
                    <div className='table-list'>
                        <div className='list-view-header w-100 mb-2 d-flex'>
                            <Row className='w-100'>
                                <Col md={6} className='rdw-image-left'>
                                    <div class="d-grid">
                                        <span class="heading">{"Users"}</span>
                                        <span class="header-desc">{""}</span>
                                    </div>
                                </Col>
                                <Col md={6} className='rdw-image-right'>
                                    <div className=''>
                                        <Button className='theme-bg-g' size="lg" color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Invite User</Button>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Loading showLoader={showLoading} mainContent={
                            <Fragment>
                                {data.length > 0 && column.length > 0 &&
                                    <ListTable data={data} columns={column} />
                                }
                                {
                                    data.length === 0 && <NoRecordFound
                                        img={aiData}
                                        isOnlyContent={true}
                                        content={"No Users Found."}
                                    />
                                }
                            </Fragment>
                        }
                        />
                    </div>
                </Fragment>
            }
            {appid === "" &&
                <Common title={"Users"}
                    titleContent={
                        <span>Users<span class="count">{data.length}</span></span>
                    }
                    titleBody={
                        <div className=''>
                            <Button size="lg" className='theme-bg-g' color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Invite User</Button>
                        </div>
                    }
                    mainContent={
                        <Fragment>
                            <div className='main-table-list'>
                                <Loading showLoader={showLoading} mainContent={
                                    <Fragment>
                                        {data.length > 0 && column.length > 0 &&
                                            <ListTable data={data} columns={column} />
                                        }
                                        {
                                            data.length === 0 && <NoRecordFound
                                                img={aiData}
                                                isOnlyContent={true}
                                                content={"No Users Found."}
                                            />
                                        }
                                    </Fragment>
                                }
                                />
                            </div>
                        </Fragment>
                    }
                />
            }
            {isModalOpen && getModalContent()}

        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
