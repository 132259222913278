import React, { Fragment, createRef } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Common from '../Common';
import UsageDashboard from "./UsageDashboard";
import "./assets/index.css";
import requestApi from '../../services/requestApi.service';
import { parseJSON, getCredentials, confirmBox, showToast, getOrganizationInfo, getAgencyInfo, getCurrentPlanInfo } from '../../Common/Appcommon';
import CheckoutForm from '../Subscription/CheckoutForm';

class Usage extends React.Component {
    formname = "table_37";
    agency_appid = getAgencyInfo("appid");
    accountid = getOrganizationInfo("Account_ID");
    constructor(props){
        super(props);
        this.state = {
            formData : [],
            dateFilter : [],
            summary: [],
            summaryData : [],
            showFormLoader : true,
            mainLoader: true,
            licenseInfo : [],
            paymentDetails : {},
            currentPlanInfo: {},
            modalOpen: false,
            stripePromise: null,
            currentPage: 1,
            refSubmitBtn: createRef()
        };
    }

    componentDidMount = async () => {
        this.getLiceneInfo();
        let currentplan = await getCurrentPlanInfo();
        this.getSummaryBoxValue();
        let stripe = await getCredentials();
        this.setState({
            stripePromise: stripe,
            mainLoader: false,
            currentPlanInfo: currentplan
        })
    };

    getSummaryBoxValue = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_USAGE_SUMMARY",
            FORMNAME: this.formname,
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                this.setState({
                    summaryData: data
                });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }

    handleSubmitClick = () => {
        if (this.state.refSubmitBtn.current) {
        this.state.refSubmitBtn.current.click(); 
        }
    };

    getUsageDataForListView = async () => {
        let { dateFilter } = this.state;
        if(dateFilter.length === 0){
            dateFilter[0] = (() => { const date = new Date(); date.setDate(date.getDate() - 29); return date; })();
            dateFilter[1] = new Date();
        }
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_USAGE_LIST_DATA",
            FORMNAME: this.formname,
            datefilter: dateFilter,
            isOtherApiReq: true,
        };
    
        try {
            this.setState({ showFormLoader: true });
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                this.setState({ formData: data, showFormLoader: false });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }   
    
    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            this.setState({ dateFilter: [startDate, endDate] }, async () => {
                await this.getUsageDataForListView();
            });
        }
    }

    getLiceneInfo = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_LICENSE_INFO",
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data
                let payment_details = data[0]['paymentdetails'];
                if(payment_details === ""){
                    payment_details = {};
                }else{
                    payment_details = parseJSON(payment_details)
                }
                this.setState({
                    paymentDetails: payment_details,
                    licenseInfo: data,
                    showFormLoader: false
                });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }

    customFunctions = async (name, pageNo = 0) => {
        if(name === "confirm_cancel"){
            let confirm = await confirmBox("Are you sure you want to cancel?","Press 'OK' to confirm your subscription cancellation.");
            if(confirm){
                this.setState({
                    mainLoader: true
                })
                let isCancelled = await this.processCancelRequest();
                if(isCancelled){
                    showToast("Cancelled Successfully")
                    await this.getLiceneInfo();
                }
                this.setState({
                    mainLoader: false,
                })
            }
            return confirm;
        }else if(name === "update_btn"){
            //this.toggleModal();
            this.setState({
                currentPage: pageNo,
            })
        }else if(name === "cancel_btn"){
            this.setState({
                currentPage: pageNo,
            })
        }else if(name === "trail_page"){
            this.setState({
                currentPage: pageNo,
            })
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }));
    }
    
    processCancelRequest = async () => {
        let cancelRequest = false;
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "CANCEL_CARD",
            accountid: this.accountid,
            agency_appid: this.agency_appid,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                cancelRequest = true;
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
        return cancelRequest;
    }

    getCardUpdateModal = () => {
        let {modalOpen, stripePromise, paymentDetails} = this.state;
        return(
            <Fragment>
                <Modal isOpen={modalOpen}>
                    <ModalHeader toggle={this.toggleModal}>
                        Update Billing Config
                    </ModalHeader>
                    <ModalBody>
                        {
                            stripePromise && 
                            <Elements stripe={stripePromise}>
                                <CheckoutForm 
                                    isUsagePage={true} 
                                    handleCheckOutBox={this.toggleModal} 
                                    paymentDetails={paymentDetails}
                                />
                            </Elements>
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }

    render() {
        let {formData, summaryData, showFormLoader, mainLoader, paymentDetails, currentPlanInfo, licenseInfo, currentPage, refSubmitBtn } = this.state;
        let bodyHeader = "Billing & Usage";
        if(currentPage === 3){
            bodyHeader = "Cancel Subscription";
        }else if(currentPage === 2){
            bodyHeader = "Manage Subscription";
        }
        return (
            <Fragment>
                {
                    <Common
                        title={"Usage"}
                        titleContent={
                            <span>{bodyHeader}</span>
                        } 
                        titleBody={
                            <Fragment>
                                {
                                    currentPage === 2 || currentPage === 4 && 
                                    <Button  onClick={e => this.handleSubmitClick()} className="theme-bg-g m-1" >Update</Button>
                                }
                            </Fragment>
                        }
                        mainLoader={mainLoader}
                        mainContent={
                            <UsageDashboard
                                showFormLoader={showFormLoader}
                                summaryData={summaryData}
                                formData={formData}
                                handleDateChange={this.handleDateChange}
                                currentPlanInfo={currentPlanInfo}
                                paymentDetails={paymentDetails}
                                licenseInfo={licenseInfo}
                                customFunctions={this.customFunctions}
                                refSubmitBtn={refSubmitBtn}
                            />
                        } 
                    />
                }
                {
                    this.getCardUpdateModal()
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
