import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import favIcon from "./assets/utils/images/favicon.png";
import { getAgencyInfo } from "./Common/Appcommon";
const Link = (props) => {
  const [favicon, setFavicon] = useState(favIcon);

  useEffect(() => {
    if (props.icon !== "" && props.icon !== undefined && props.icon !== null) {
      setFavicon(props.icon);
    }
    
  }, [props]);

  return (
    <div>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
    </div>
  );
};

export default Link;
