/* eslint max-len: 0 */

import dollar from "../images/dollar.svg";
import timer from "../images/timer.svg";
import robot from "../images/robot.svg"
import offer from "../images/offer.svg"
import emoji from "../images/emoji-smile.svg"

export const providers = [
  {
    name: "vapi",
    label: "VAPI",
    description:"For using your Vapi account",
    url: "https://vapi.ai/",
    fields : [
      {"name" : "api_key","label": "Private Key","type":"text"},
      {"name" : "pub_key","label": "Public Key","type":"text"},
    ]
  },
  {
    name: "bland",
    label: "Bland AI",
    description:"For using your Bland AI.",
    url: "https://www.bland.ai/",
    fields : [
      {"name" : "api_key","label": "Private Key","type":"text"},
    ]
  },
  {
    name: "retell",
    label: "Retell AI",
    description:"For using your Retell AI.",
    url: "https://www.retellai.com/",
    fields : [
      {"name" : "api_key","label": "Private Key","type":"text"},
    ]
  }
];

export const providersSelectList = [
  
  {
    label : "Vapi",
    value : "vapi",
  },
  {
    label : "Bland AI",
    value : "bland",
  },
  {
    label : "Retell AI",
    value : "retell",
  },
];


export const plansCycle = [
  {
      label : "Monthly",
      value : 30,
      isHide : false,
  },
  {
      label : "Quarterly",
      value : 90,
      isHide : true,
  },
  {
      label : "Half Yearly",
      value : 180,
      isHide : true,
  },
  {
      label : "Annually",
      value : 360,
      isHide : false,
  }
];
export const stepConfigurations = [
  {
      title: 'Plan Info',
      fields: [
          { name: 'plan_name', label: 'Plan Name', type: 'text', placeholder: 'Ex: Startup', icon: "", requiredmsg: "Plan Name is required", description: ""},
          { name: 'plan_description', label: 'Plan Description', type: 'text', placeholder: 'About this plan...', icon: "", requiredmsg: 'Plan Description is required', description: "" },
          { name: 'plan_cost', label: 'Monthly Charges', type: 'number', placeholder: 'Ex: 499', icon: dollar, requiredmsg: 'Plan Cost is required', description: "", suffix: "/month"},
          { name: 'annual_discount', label: 'Annual Discount', type: 'number', placeholder: 'Ex: 10', icon: offer, requiredmsg: 'Annual Discount is required', description: "Percentage", suffix: "%"},
          { name: 'free_minutes', label: 'Free Minutes', type: 'number', placeholder: 'Ex: 1000', icon: timer, requiredmsg: 'Initial Free Minutes is required' },
          { name: 'addon_cost_per_call', label: 'Extra Minute Charges', type: 'number', placeholder: 'Ex: 0.20', icon: dollar, requiredmsg: 'Extra Minute Charges is required'},
          { name: 'is_popular', label: 'Mark as Most Popular', type: 'toggle', placeholder: '', icon: "", requiredmsg: ''},
      ]
  },
  /* {
      title: 'Initial Services',
      fields: [
          { name: 'phone_numbers', label: 'Initial Phone Numbers', type: 'number', placeholder: 'Ex: 5', icon: "", requiredmsg: 'Initial Phone Numbers are required'},
          { name: 'agents', label: 'Initial Agents', type: 'number', placeholder: 'Ex: 10', icon: robot, requiredmsg: 'Initial Agents are required' },
          { name: 'addon_cost_per_number', label: 'Additional Cost Per Phone Number', type: 'number', placeholder: 'Ex: 5', icon: dollar, requiredmsg: ''},
          { name: 'addon_cost_per_agent', label: 'Additional Cost Per Agents', type: 'number', placeholder: 'Ex: 100', icon: dollar, requiredmsg: ''}
      ]
  }, */
  {
      title: 'Additional Services',
      fields: [
        { name: 'cus_desc_0', label: 'Custom Description 1', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_1', label: 'Custom Description 2', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_2', label: 'Custom Description 3', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_3', label: 'Custom Description 4', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_4', label: 'Custom Description 5', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_5', label: 'Custom Description 6', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_6', label: 'Custom Description 7', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_7', label: 'Custom Description 8', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_8', label: 'Custom Description 9', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
        { name: 'cus_desc_9', label: 'Custom Description 10', type: 'text', placeholder: '', icon: "", requiredmsg: '', icon: emoji, group: "custom_desc", description: "Click Emoji to add to Emoji for Plan Page"},
      ]
  }
];