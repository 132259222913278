import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  DropdownToggle, DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownItem
} from 'reactstrap';
import HeaderLogo from "../AppLogo";
import { useTheme } from "../../Context/ThemeContext";
import Link from "../../Link";
import { getAgencyInfo } from "../../Common/Appcommon";

const Header = (props) => {
  const { theme, toggleTheme } = useTheme();
  let {
    headerBackgroundColor,
    enableMobileMenuSmall,
    enableHeaderShadow,
    titleContent,
    titleBody,
    headerNotifyShow
  } = props;
  let agencyLogoInfo = getAgencyInfo('FavIcon');
  let agencyLogo = "";
  if (agencyLogoInfo !== "" && agencyLogoInfo !== undefined && agencyLogoInfo !== null) {
    //agencyLogo = agencyLogoInfo;
    if (agencyLogoInfo.indexOf(",") > 0) {
      let agentLArr = agencyLogoInfo.split(",");
      agencyLogo = agentLArr[1];
    }
  }
  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div"
          className={cx("app-header", {"header-notify" : headerNotifyShow}, headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          appear={true} timeout={1500} enter={false} exit={false}>
          <div>
            <Link icon={agencyLogo} />
            <HeaderLogo theme={theme} />
            <div className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall,
            })}>
              <div className="app-header-left">
                <div className="app-header-title">{titleContent}</div>
              </div>
              <div className="app-header-right">
                <div className="d-flex align-center">
                  <div className="">
                    <Fragment>
                      <UncontrolledButtonDropdown className='metismenu-item unset-btn-group'>
                        <DropdownToggle color="link" className="p-0" >
                          <div className="theme-icon pr-2"><i class={theme === "light-theme" ? `light-bg fi fi-bs-brightness` : "dark-bg fi fi-bs-moon-stars"}></i></div>
                        </DropdownToggle>
                        <DropdownMenu end className="rm-pointers dropdown-menu-sm">
                          <DropdownItem className="">
                            <a className='d-flex align-center' onClick={(e) => {toggleTheme('light-theme')}}>
                              <i class={`light-bg fi fi-bs-brightness pr-1`}></i>
                              Light
                            </a>
                          </DropdownItem>
                          <DropdownItem className="">
                            <a className='d-flex align-center' onClick={(e) => {toggleTheme('dark-theme')}}>
                              <i class={`light-bg fi-bs-moon-stars pr-1`}></i>
                              Dark
                            </a>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Fragment>
                  </div>
                  <div className="col-md-12">{titleBody}</div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
