import React, { Fragment,createRef } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import "./assets/index.css";
import PricingPage from './PricingPage';
import { getCurrentPlanInfo } from '../../Common/Appcommon';
import { Button } from 'reactstrap';

class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainLoader: true,
            currentPlanInfo: {},
            refSubmitBtn: createRef(),
            showSubBtn: false,
        };
    }
    async componentDidMount(){
        let currentplan = await getCurrentPlanInfo();
        this.setState({
            currentPlanInfo: currentplan,
            mainLoader: false
        })
    }

    handleSubmitClick = () => {
        if (this.state.refSubmitBtn.current) {
            this.state.refSubmitBtn.current.click(); 
        }
    };

    customFunction = (name) => {
        if(name === "showSubBtn"){
            this.setState((prevState) => ({
                showSubBtn: !prevState.showSubBtn
            }));            
        }
    }

    render() {
        let {currentPlanInfo, mainLoader,refSubmitBtn, showSubBtn} = this.state;

        return (
            <Fragment>
                <Common
                    title={"Subscription"}
                    titleContent={
                        <span>Subscription</span>
                    } 
                    titleBody={
                        <Fragment>
                            {
                                showSubBtn &&
                                <Button 
                                    onClick={e => this.handleSubmitClick()} 
                                    className="theme-bg-g m-1">
                                        Subscribe
                                </Button>
                            }
                        </Fragment>
                    }
                    mainLoader={mainLoader}
                    mainContent={
                        <Fragment>
                            <PricingPage 
                                currentPlanInfo={currentPlanInfo} 
                                refSubmitBtn={refSubmitBtn} 
                                customFunction={this.customFunction}/>
                        </Fragment>
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);