import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { Container } from 'reactstrap';
import UsageSummary from "./UsageSummary";
import ListTable from "../../Common/ListTable";
import DateFilter from "../../Common/DateFilter";
import Loading from "../../Common/Loading";
import CurrentPlan from "./CurrentPlan";
import PricingPage from "../Subscription/PricingPage";
import CancelPage from "./CancelPage";
import ManageSubscription from "../Subscription/ManageSubscription";

const UsageDashboard = (props) => {
    const {formData, summaryData, showFormLoader, currentPlanInfo, paymentDetails, licenseInfo, customFunctions, refSubmitBtn} = props;

    /**
     * 1 - home page
     * 2 - manange subscription page
     * 3 - cancel subscription page
     * 4 - subscription page
     */
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(Object.keys(paymentDetails).length === 0){
            setCurrentPage(4);
            customFunctions("trail_page", 4);
        }else{
            setCurrentPage(1);
            customFunctions("trail_page", 1);
        }
    }, [paymentDetails]);

    const columns = [
        {
            name: (
                <div>
                    S.No
                </div>
            ),
            selector: (row, index) => index + 1,
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "60px !important",
        },
        {
            name: (
                <div>
                    <i class="fi fi-rr-calendar-days header-font-icon"></i>
                    Date
                </div>
            ),
            selector: row => row["date"],
            sortable: true,
        },
        {
            name: (
                <div>
                    <i class="fi fi-rr-time-quarter-to header-font-icon"></i>
                    Total Calls
                </div>
            ),
            id: "minutes",
            selector: row => row["total_calls"],
            sortable: true,
        },
        {
            name: (
                <div>
                    <i class="fi fi-rr-time-quarter-to header-font-icon"></i>
                    Total Minutes
                </div>
            ),
            id: "minutes",
            selector: row => row["minutes"],
            sortable: true,
        },
      
        {
            name: (
                <div><i class="fi fi-bs-dollar header-font-icon"></i>
                    Cost
                </div>
            ),
            selector: row => row["amount"],
            sortable: true,
        }
    ];

    const customFunction = (name) => {
        if(name === "cancel_btn"){
            let pageNo = currentPage === 3 ? 1 : 3;
            customFunctions(name, pageNo);
            setCurrentPage(pageNo);
        }else if(name === "update_btn"){
            let pageNo = currentPage === 2 ? 1 : 2;
            customFunctions(name, pageNo);
            setCurrentPage(pageNo);
        }else if(name === "confirm_cancel"){
            return customFunctions(name);
        }
    }

    const getCancelPageContent = () => {
        return (
            <CancelPage 
                customFunctions={customFunction} 
                licenseInfo={licenseInfo}
            />
        )
    }

    const UsageSummaryPageContainer = () => {
        return(
            <div className="usage-dashboard-container">
                <div className="usage-summary-container">
                    <UsageSummary summaryData={summaryData} />
                </div>
                <div className="usage-summary-container">
                    <CurrentPlan 
                        currentPlanInfo={currentPlanInfo} 
                        paymentDetails={paymentDetails}
                        licenseInfo={licenseInfo}
                        customFunctions={customFunction} 
                    />
                </div>
                <div className="usage-list-view">
                    <div className="list-view-header mt-4 ms-2">
                        <div className="usage-list-header mt-3 fw-bold">
                            Usage Log
                        </div>
                        <div className="date-filter">
                            <DateFilter handleDateChange={props.handleDateChange} defaultSelectIndex={3}/>
                        </div>
                    </div>
                    <div className="list-view-box ms-1 mr-1 mb-5">
                        <Loading 
                            showLoader={showFormLoader} 
                            mainContent={
                                <div className="usage-report m-3">
                                    <ListTable columns={columns} data={formData} />
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }

    const getPlanPageContainer = () => {
        return (
            <PricingPage refSubmitBtn={refSubmitBtn} isTrail={true} currentPlanInfo={currentPlanInfo} customFunction={customFunction}/>
        )
    }
 
    return(
        <Fragment>
            <Container fluid>
                {
                    currentPage === 1 && UsageSummaryPageContainer()
                }
                {
                    currentPage === 2 && <ManageSubscription customFunction={customFunction} isUsagePage={true} selectedPlan={currentPlanInfo} refSubmitBtn={refSubmitBtn}/>
                }
                {
                    currentPage === 3 && getCancelPageContent()
                }
                {
                    currentPage === 4 && getPlanPageContainer()
                }
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageDashboard);
