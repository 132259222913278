import React, {Component, Fragment} from "react";
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody} from "reactstrap";
import info from "../Components/Agents/assets/images/info.svg"

const PopOverItem = (props) =>{
    
    let placement = "";
    let title = "";
    let body = "";
    let className = "";
    if(props.placement !== ""){
        placement = props.placement;
    }

    if(props.title !== ""){
        title = props.title;
    }

    if(props.body !== ""){
        body = props.body;
    }
    if(props.className !== ""){
        className = props.className;
    }
    return (
        <Fragment>
            <img src={info} id={"Popover-" + props.id} className={`popover-info-btn`}/>
            <UncontrolledPopover placement={placement} trigger="legacy" fade={false} target={"Popover-" + props.id}>
            {
                title !== "" && 
                <PopoverHeader>{title}</PopoverHeader>
            }
            { 
                body !== "" && 
                <PopoverBody>
                    {body}
                </PopoverBody>
            }
            </UncontrolledPopover>
        </Fragment>
    );
}

export default PopOverItem;