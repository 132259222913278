import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { Form, Row, Col, Label, Input, Container, Button } from "reactstrap";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import PopOverItem from "../../Common/PopOverItem"

const AgentMainContent = (props) => {
    const { rowData: initialRowData, fieldNames} = props;
    const { handleSubmit, control, reset, formState: { errors } } = useForm();

    useEffect(() => {
        reset(initialRowData);
    }, [initialRowData, reset]);

    const configViewFieldSave = [
        {
            label: "AI Agent Name",
            name: "Agent Name",
            datalabel: fieldNames['Agent_Name'],
            type: "text",
            description: "",
            placeholder: "Name Here",
            required: true,
            isDisabled: true,
        },
        {
            label: "Greetings",
            name: "Call Start Message",
            datalabel: fieldNames['Call_Start_Message'],
            type: "textarea",
            description: "The first message that the AI Agent will say.",
            placeholder: "First Message Here",
            required: true,
            isDisabled: true,
        },
        {
            label: "Knowledge Base",
            name: "System Prompt",
            datalabel: fieldNames['System_Prompt'],
            type: "textarea",
            description: "Give AI agents intelligence with information (context, role, personality, instructions, etc.)",
            placeholder: "System Prompt Here",
            required: false,
            isDisabled: true,
        }
    ];

    const onSubmit = (data) => {
        props.onSubmit(data);
    };

    const handleChange = (e) => {
        let {name, value} = e.target;
        props.onChange(name, value)
    };

    return (
        <Fragment>
            <Container fluid>
                <div className='agent-config-container'>
                    <div className='agent-config-form'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {configViewFieldSave.map((field, index) => {
                                const { label, name, datalabel, type, placeholder, required, description, isDisabled } = field;

                                return (
                                    <Row key={index} className="mb-3">
                                        <Col md="12" lg="12">
                                            <Label className="d-flex align-items-center label" htmlFor={name}>
                                                {label}
                                                {
                                                    description &&
                                                    <PopOverItem 
                                                        id={index}
                                                        body={description}
                                                    />
                                                }
                                            </Label>
                                            <Controller
                                                name={name}
                                                control={control}
                                                rules={{ required: required ? `${label} is required` : false }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type={type}
                                                        placeholder={placeholder}
                                                        value={initialRowData[datalabel]}
                                                        className={`${errors[name] ? 'is-invalid' : (name === "Call Start Message" ? "agent-greeting" : '')}`}
                                                        onChange={e => {handleChange(e)}}
                                                        disabled={isDisabled}
                                                    />
                                                )}
                                            />
                                            {errors[name] && (
                                                <em className="text-danger">
                                                    {errors[name].message}
                                                </em>
                                            )}
                                        </Col>
                                    </Row>
                                );
                            })}

                            <Row>
                                <Col md="4">
                                    { false && <Button className="mb-2 me-2 btn-icon submit-btn" type="submit">
                                        Save
                                    </Button>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Container>
        </Fragment>
    );
};




const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMainContent);
