import React, { Fragment, useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { Button, ButtonGroup, Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getCredentials, parseJSON } from "../../Common/Appcommon";
import GobackButton from "../../Common/GoBack";
import CheckoutForm from './CheckoutForm';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import requestApi from "../../services/requestApi.service";
import ManageSubscription from "../Subscription/ManageSubscription";

const PricingPage = (props) => {
    const [stripePromise, setStripePromise] = useState(null);
    const [showBuyBtn, setShowBuyBtn] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plansList, setPlansList] = useState(null);
    const [planSelected, setPlanSelected] = useState(1);
    const [showCheckout, setShowCheckout] = useState(false);
    const {isTrail, currentPlanInfo,refSubmitBtn, customFunction} = props;
    
    useEffect(() => {
        (async () => {
            await getPlanPricing();
            const stripe = await getCredentials();
            setStripePromise(stripe);
            setShowBuyBtn(typeof stripe === 'object' && Object.keys(stripe).length > 0);
        })();
    }, []);

    const getPlanPricing = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_SUB_PLAN_PRICING",
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                setPlansList(response.data);
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }


    const toggleCheckout = (plan = {}) => {
        setShowCheckout((prevShowCheckout) => !prevShowCheckout);
        setSelectedPlan(plan);
        customFunction("showSubBtn", 4)
    };

    const onRadioBtnClick = (selectedplan) => {
        setPlanSelected(selectedplan)
    }

    const getPlanButtons = () => {
        const plansCycle = [
            {
                label : "Monthly",
                value : 1,
                isHide : false,
            },
            {
                label : "Quarterly",
                value : 3,
                isHide : true,
            },
            {
                label : "Half Yearly",
                value : 6,
                isHide : true,
            },
            {
                label : "Annually",
                value : 12,
                isHide : false,
            }
        ];
        return (
            <>
                <ButtonGroup className="mb-2">
                    {
                        plansCycle.map(({label, value, isHide}, index) => {
                            let key = "btn_no_" + index;
                            return (
                                <Button 
                                    key={key}
                                    className={`btn-outline-2x btn-square ${isHide ? "hide" : ""}`} 
                                    outline 
                                    color="light" 
                                    name={value}
                                    onClick={() => onRadioBtnClick(value)} 
                                    active={planSelected === value}
                                >
                                    {label}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </>
        );
    }

    return (
        <Fragment>
            { 
                showCheckout ? (
                        <ManageSubscription  
                            isUsagePage={false} 
                            selectedPlan={selectedPlan} 
                            refSubmitBtn={refSubmitBtn} 
                            noofMonths={planSelected}
                        />
                    ) : ( 
                        <div className='account-pricing-container'>
                        {
                            !isTrail &&  
                            <GobackButton/>
                        }
                        <div className='pricing-body m-3'>
                            <div className='plans-btn'>
                                {getPlanButtons()}
                            </div>
                            <div className='plans-list m-3'>
                                { 
                                    <Row lg="12">
                                        {
                                            plansList && plansList.map((plan, index) => {
                                                let {plan_id, plan_name, plan_cost, plan_description, annual_discount, is_popular, other_features} = plan;
                                                if (planSelected === 12) {
                                                    plan_cost =  Math.round(plan_cost - (plan_cost * (annual_discount / 100)));
                                                }
                                                if(other_features !== ""){
                                                    other_features = parseJSON(other_features);               
                                                }
        
                                                let isCurrentPlan = (currentPlanInfo.subscription_status === "Active" && plan_id === currentPlanInfo.plan_id) ?? false;
        
                                                return (
                                                    <Col lg="4">
                                                        <Card className={`main-card mb-3 plan-card ${is_popular === "1" ? "selected-plan" : (isCurrentPlan ? "current-plan" : "")}`}>
                                                            <div className='plan-details'>
                                                                <div>
                                                                    <div className='plan-title col-md-12'>
                                                                        <div className='col-md-6'>
                                                                            <span className='plan-name'>
                                                                                {plan_name}
                                                                            </span>
                                                                        </div>
                                                                        <div className='col-md-6 plan-popular'>
                                                                            {
                                                                                is_popular === "1" && 
                                                                                <span className='recommended-tag'>
                                                                                    Most Popular
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='plan-title-desc'>
                                                                        {plan_description}
                                                                    </div>
                                                                </div>
                                                                <div className='plan-price'>
                                                                    ${plan_cost}/m
                                                                </div>
                                                                <div className='plan-features'>
                                                                    {
                                                                        other_features && other_features.map((val, index) => {
                                                                        let iconKey = Object.keys(val).find(key => key.startsWith('cus_icon_'));
                                                                        let descKey = Object.keys(val).find(key => key.startsWith('cus_desc_'));
                                                                        let icon = val[iconKey];
                                                                        let desc = val[descKey];
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        desc !== "" && <div className='plan-feature-description' key={index}>
                                                                                            <span className='plan-feature-text'>
                                                                                                {icon}
                                                                                            </span>
                                                                                            <span className='plan-feature-text'>
                                                                                                {desc}
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                {
                                                                    showBuyBtn && 
                                                                    <div className='plan-footer'>
                                                                        <Button className='w-100 theme-bg-g' onClick={e => {e.stopPropagation(); toggleCheckout(plan)}} disabled={!stripePromise || isCurrentPlan}>
                                                                            {isCurrentPlan ? "Current Plan" : "Subscribe " + plan_name}
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                }
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);