import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import Tabs from "react-responsive-tabs";
import backIcon from "../../assets/utils/images/back.svg"
import ProviderCredentials from './ProviderCredentials';
import AccountGeneral from './AccountGeneral';
import Pricing from './Pricing/index.js';
import Users from '../Settings/Users.js';
import TooltipItem from '../../Common/ToolTip.js';

const SubAccountDetails = (props) => {
    let { accountInfo, backToViewAction, openSubAccountView } = props;
    const subtables = [
        {
            name: "General",
            component:
                <AccountGeneral accountInfo={accountInfo} />,
        },
        {
            name: "Provider Credentials",
            component:
                <ProviderCredentials accountInfo={accountInfo} />,
        },
        {
            name: "Pricing",
            component:
                <Pricing accountinfo={accountInfo} isSubAccountView={true} />
        },
        {
            name: "Users",
            component:
                <Users accountInfo={accountInfo} />,
        }
    ];

    const getSimpleTabs = () => {

        return subtables.map(({ name, component }, index) => ({
            key: index,
            title: name,
            getContent: () => component,
        }));
    }

    return (
        <Fragment>
            <div className='subaccount-detailed-view'>
                <div className='account-details'>

                    <Row>
                        <Col md={6} className='rdw-image-left'>
                            <div className=''>
                                <span class="card-heading" >
                                    {accountInfo.account_name}
                                </span>
                            </div>
                        </Col>
                        <Col md={6} className=' rdw-image-right'>
                            <div className='ml-2'>
                                <div className='back-action d-flex align-center'>
                                    <a className='cursor-pointer' onClick={(e) => { backToViewAction() }}>
                                        <span className='d-flex align-center'>
                                            <i class="fi fi-br-arrow-left pr-1"></i> <span>Back to List View</span>
                                        </span>
                                    </a>
                                    <span className='pl-1'>|</span>
                                    <TooltipItem id={'2'} text={"Access `" + accountInfo.account_name + "` account"} content={<a onClick={(e) => { openSubAccountView(accountInfo) }} className='pl-1 cursor-pointer'>Access Account <i class="fi fi-bs-arrow-up-right-from-square"></i></a>} />

                                </div>

                            </div>
                        </Col>
                    </Row>

                </div>
                <div className='agent-details agent-other-details'>
                    <Row>
                        <Col md="12">
                            <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                                showMore={true}
                                transform={true}
                                showInkBar={true}
                                selectedTabKey={0}
                                transformWidth={600}
                                items={getSimpleTabs()} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountDetails);
