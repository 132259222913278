import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { parseJSON, formatDate, getSessionValue, currencyFormatter, getLicenseInfo } from "../../Common/Appcommon";
import requestApi from "../../services/requestApi.service";

const CurrentPlan = ({currentPlanInfo, licenseInfo, paymentDetails, customFunctions}) => {
    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        (async () => {
            await getLastPayment();
        })();
    }, []);
    

    let {plan_name, plan_cost, other_features} = currentPlanInfo;
    let {modeofpayment, status, paymentid, upcoming_plans} = paymentDetails;
    let subscriptionActive = status === "Active";

    const btnClick = async (name) => {
        if(name === "upgrade_btn" || name === "subscribe_btn"){
            window.location.hash = '#/subscription';
        }else if(name === "payments"){
            window.location.hash = '#/payments';
        }else{
            customFunctions(name);
        }
    }
        
    const getLastPayment = async () => {
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "GET_LAST_PAYMENT",
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                setSummaryData(data);
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }

    if(other_features !== undefined && other_features !== null && other_features !== ""){
        other_features = parseJSON(other_features);
    }else{
        other_features = []
    }
    let license_end_date = getLicenseInfo("license_end_date");
    let next_payment_date = "";

    if(license_end_date !== ""){
        // Convert the string to a Date object
        let dateObj = new Date(license_end_date);
        // Add one day to the date
        dateObj.setDate(dateObj.getDate() + 1);
        // Convert back to a string in YYYY-MM-DD format
        next_payment_date = dateObj.toISOString().split('T')[0];
    }

    let isSubAccountView = getSessionValue("isSubAccountView");

    const buttons = [
        {
            label: "Upgrade Plan",
            name: "upgrade_btn",
            classNames: !isSubAccountView && subscriptionActive ? "theme-bg-g m-1" : "hide",
            isDisabled : !subscriptionActive
        },
        {
            label: "Manage Subscription",
            name: "update_btn",
            classNames: !isSubAccountView && subscriptionActive ? "theme-bg-g m-1" : "hide",
        },
        {
            label: "Subscribe Plan",
            name: "subscribe_btn",
            classNames: subscriptionActive ? "hide" : "theme-bg-g m-1",
            isDisabled : subscriptionActive
        },
        {
            label: subscriptionActive ? "Cancel Subscription" : "Cancelled",
            name: "cancel_btn",
            classNames: !isSubAccountView && subscriptionActive ? "cancel-sub-btn" : "hide",
            isDisabled : !subscriptionActive
        },
    ];

    next_payment_date = formatDate(next_payment_date, "dd-mmm-yyyy").toUpperCase();
    plan_cost = currencyFormatter('en-US', 'USD', plan_cost, 0);

    let subscription_status = "";
    let status_class = "";
    let subHeader = "";
    let subBody = "";

    if(upcoming_plans && upcoming_plans.length > 0){
        let up_cycle = upcoming_plans[0]['frequency'] ?? "";
        let up_plan_cost = upcoming_plans[0]['plan_cost'] ?? "";
        up_cycle = up_cycle.toLowerCase();
        subHeader = "Upcoming plan: $" + up_plan_cost + " /month, billed " + up_cycle;
        subBody = "Your plan change has been received and will take effect from the next billing cycle " + next_payment_date + ".";
    }
    
    if(subscriptionActive){
        subscription_status = "Active";
        status_class = "text-success";
        if(summaryData["paymentstatus"] === "failed"){
            subHeader = "Your recent payment failed!";
            subBody = "Please check your card details or try a different payment method. For further assistance, contact our support team.";    
            subscription_status = "PAYMENT FAILED";
            status_class = "text-danger";
        }
        modeofpayment = modeofpayment.toUpperCase();
    }else if(status === "Inactive"){
        subscription_status = "CANCELED";
        status_class = "text-danger";
        subHeader = "Your subscription has been canceled.";
        subBody = "Your cancellation will take effect at the end of your current billing period on " + next_payment_date + ".";
        modeofpayment = "NA";
        next_payment_date = "NA"
    };

    return(
        <Fragment>
            <div className="current-plan-container m-3 ">
                {
                    <>
                        {
                            subHeader && 
                            <div className='card-fields d-grid current-plan-notification'>
                                <span class="header-desc m-auto " >{subHeader}</span>
                                <span className='header-desc m-auto'>{subBody}</span>
                            </div>
                        }
                        <Row className={`billing-content-wrapper me-2 ms-1 mt-5 mb-4 `}>
                            <Col md={'12'}>
                                <div className={`billing-subscription-info mb-3`}>
                                    <span className={`fw-bold`}>Subscription Details</span>
                                    {
                                        false && paymentid !== "" && 
                                        <span className={`ms-auto fw-normal`}>Payment ID : {paymentid}</span>
                                    }
                                </div>
                                <div className={`billing-subscription-table`}>
                                    <table className={'le-table no-border-table table table-bordered table-hover billing-subscription-table'}>
                                        <thead> 
                                            <tr>
                                                <td className={'text-left text-uppercase'}>Plan Name</td>
                                                <td className={'text-center text-uppercase'}>Status</td>
                                                <td className={'text-center text-uppercase'}>Charges</td>
                                                <td className={'text-center text-uppercase'}>Frequency</td>
                                                <td className={'text-center text-uppercase'}>Next Payment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th className={'text-left'}>{plan_name}</th>
                                                <th className={'text-center fw-bold ' + status_class}>{subscription_status}</th>
                                                <th className={'text-center'}>{plan_cost}/month</th>
                                                <th className={'text-center'}>{modeofpayment}</th>
                                                <th className={'text-center'}>{next_payment_date}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={`text-center mt-5 mb-4`}>
                                    {
                                        buttons && buttons.map(({label, name, classNames, isDisabled}, index) => {
                                            let key = "btn_list_" + index;
                                            return(
                                                <Button key={key} name={name} className={classNames + " m-2"} onClick={e => {btnClick(name)}} disabled={isDisabled}>
                                                    {label}
                                                </Button>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </Fragment>
    )

} 

export default CurrentPlan;