import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col,Modal,ModalBody,ModalHeader,ModalFooter, Button, Label,Input } from "reactstrap";
import requestApi from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import { handleErrorResponse, showToast } from '../../Common/Appcommon';

const AccountGeneral = (props) => {
    let { accountInfo } = props;
    const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const [formData, setFormData] = useState(accountInfo);
    const [showPopup ,setShowPopup] = useState(false);
    const [isDelete ,setDelete] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showButtonLoading , setshowButtonLoading] = useState(false);
    const [orgName , setOrgName] = useState('');
    formData["appid"] = formData.data_row.appid !== undefined && formData.data_row.appid !== "" ? formData.data_row.appid : "";

    const subaccountDetails = async () => {

    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleInputChange = (e) =>{
        let value = e.target.value;
        setOrgName(value);
        if(value !== undefined && value !== "" && value === formData["account_name"]){
            setDelete(true);
        }else{
            setDelete(false);
        }
    }
    const toggle = () => {
        toggleModal();
    }

    const toggleModal = () => {
        setShowPopup(!isModalOpen);
        setModalOpen(!isModalOpen);
        setOrgName('');
        setDelete(false);
    }

    const getModalContent = () => {
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={toggle}>Delete Sub Account</ModalHeader>
                    <div className='ms-3 mb-2 del-subaccount-header'>
                    Are you sure you want to permanently delete your Account? This action cannot be undone.
                    </div>
                    <div className='bb-1'></div>
                    <ModalBody>
                        <Fragment>
                            <Label className='mb-4'>To confirm, please type your Sub Account name: </Label>
                            <span className='ms-2 font-bold'>{formData['account_name']}</span>
                            <Controller
                                name="orgName"
                                control={control}
                                rules={{ required: "Organization Name is required" }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        id="orgName"
                                        autoComplete="off"
                                        placeholder="Enter Sub Account Name "
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            field.onChange(e);
                                        }}
                                        value={orgName}
                                        className={`form-control ${errors.orgName ? 'is-invalid' : ''}`}
                                    />
                                )}
                            />
                            {errors.orgName && <em className="mb-2 error invalid-feedback">{errors.orgName.message}</em>}
                        </Fragment>
                    </ModalBody>
                    <div className='delete-sub-account-popup mb-1'>
                        <ModalFooter>
                            <Loading showLoader={showButtonLoading} mainContent={
                                    <Fragment>
                                        <Button disabled={!isDelete} className='btn btn-danger' onClick={deleteTheAccount}> 
                                        Delete Sub Account
                                        </Button>
                                    </Fragment>
                            } />
                        </ModalFooter>
                    </div>

                </Modal>
            </>
        );
    };
    
    const deleteTheAccount = async() => {
        let {data_row} = accountInfo;
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'DELETE_SUBACCOUNT',
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNT_APPID" : data_row.appid
        };
        setshowButtonLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast("Success! Your account has been deleted.");
            setshowButtonLoading(false);
            toggleModal();
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }else{
            let errormsg = response.errormsg ?? "Your account not deleted.Please try after some time."
            handleErrorResponse(errormsg);
            setshowButtonLoading(false);
            toggleModal();
        }
    }

    return (
        
        <Fragment>
            <div className='account-general-info'>
                <form id={`subaccount-form`} name={'subaccount'} onSubmit={handleActionFormSubmit((data) => subaccountDetails(data))}>
                    <div className='field-details'>
                        <div className='general-div'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >Sub Account Name</span>
                                    <span className='agents-general-desc'>Max 100 characters limit</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"account_name"}
                                        control={control}
                                        rules={{ required: formData["account_name"] === undefined ? "Please provide Company Name" : "" }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"account_name"}
                                                    id={"account_name"}
                                                    value={formData["account_name"]}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    className={`form-control ${errors["account_name"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["account_name"] && <em className={`error invalid-feedback`}>{errors["account_name"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='general-div hide'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >APP ID</span>
                                    <span className='agents-general-desc'>Sub Account ID</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"appid"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"appid"}
                                                    id={"appid"}
                                                    value={formData["appid"]}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    disabled
                                                    className={`form-control ${errors["appid"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["appid"] && <em className={`error invalid-feedback`}>{errors["appid"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='agency-details hide'>
                            <div className='card-fields d-flex'>
                                <Col md={2}>
                                <div className='d-grid'>
                                    <span class="heading" >Assistants Limit </span>
                                    <span className='header-desc'>Max number of assistants</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"assistants"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"assistants"}
                                                    id={"assistants"}
                                                    value={formData["assistants"] === undefined ? 10 : formData["assistants"]}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    disabled
                                                    className={`form-control ${errors["assistants"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["assistants"] && <em className={`error invalid-feedback`}>{errors["assistants"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='general-div'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >Delete Sub Account </span>
                                    <span className='agents-general-desc'>Are you sure you want to permanently delete your Account? This action cannot be undone.</span>
                                </div>
                                </Col>
                                <Col className='pt-3' md={3}>
                                <a onClick={toggleModal} className="btn btn-danger btn-hover-shine">
                                    Delete Sub Account
                                </a>
                                </Col>
                            </div>                            
                        </div>
                        {showPopup && getModalContent()}
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountGeneral);
