import React, { Fragment, useEffect, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import cx from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AppSidebar from '../../Layout/AppSidebar';
import AppHeader from '../../Layout/AppHeader';
import Loading from '../../Common/Loading';
import { getHeaderNotification, setSessionValue } from '../../Common/Appcommon';

const Common = (props) => {
    const [notification, setNotification] = useState({show:false})
    let { mainContent, titleContent, title, titleBody, mainLoader } = props;
    if (title !== undefined && title !== "") {
        document.title = title;
    }

    useEffect(() => {

        async function getNotification() {
            let headernotification = await getHeaderNotification();
            setNotification(headernotification);
        }
        getNotification();
    }, [])

    const oncloseAction = () => {
        setSessionValue("licenseError", "false");
        setNotification(false);
    }

    let loaderType = "ball-clip-rotate";
    return (
        <Fragment>
            <div className={cx('header-notification', {
                'hide': !notification.show
            })}>
                <div className={'header-notification-body'}>
                    <>
                        {notification.icon && <FontAwesomeIcon icon={faInfoCircle} />}
                        {notification.text}
                    </>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={(e) => { oncloseAction(e) }} ></button>
            </div>
            {titleContent && <AppHeader titleContent={titleContent} titleBody={titleBody} headerNotifyShow={notification.show}/>}
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <Loading showLoader={mainLoader} mainContent={
                        <div className="app-main__inner app-normal__inner">
                            <div className='app-main-content'>
                                {mainContent}
                            </div>
                        </div>
                    } />

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Common);
