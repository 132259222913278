import React, { Fragment, Component } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import queryString from "query-string";

//internal
import siteRequestApiService from "../../services/siteRequestApi.service";
import AppLogo from "../../assets/utils/images/logo.png";
import Link from "../../Link.js";
import { fetchAgencyDetails, getCurrentDomain, getLogoutURL, showToast } from "../../Common/Appcommon";
import '../site.css';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.recoverFormRef = React.createRef();
    const uriParams = queryString.parse(props.location.search);
    this.resetToken = false;
    if (uriParams.params !== undefined) {
      this.resetToken = uriParams.params;
    }
    this.state = {
      errorNewPwd: { message: "", isValid: true },
      errorCnfPwd: { message: "", isValid: true },
      errorValidation: { message: "", isValid: true },
      btnEditable: false,
      email: false,
      pid: false,
      brandLogo: "",
      agencyLogo: [],
    };
  }

  componentDidMount = async () => {
    document.title = 'Reset your password';
    let agencyData = await fetchAgencyDetails();
    if (agencyData.length > 0) {
      this.setState({
        agencyLogo: agencyData
      })
    } else {
      this.setState({
        brandLogo: AppLogo
      })
    }
    if (this.resetToken != undefined) {
      let queryString =
        "&params=" + this.resetToken + "&operation=validateToken";
      let response = await siteRequestApiService.processRequest(
        "resetPassword",
        queryString,
        {}
      );
      if (response.status != undefined) {
        if (!response.status) {
          this.setState({
            btnEditable: true,
          });
          showToast(
            response.message,
            "top-right",
            response.status ? "success" : "error",
            undefined,
            5000
          );
        } else {
          this.state.email = response.data.email;
          this.state.pid = response.data.pid;
        }
      }
    }
  };

  resetAction = async (e) => {
    e.preventDefault();
    let newPwdVal = this.recoverFormRef.current.querySelector(
      "input[name='newpassword']"
    );
    console.log("new password:", newPwdVal.value);

    let cnfPwdVal = this.recoverFormRef.current.querySelector(
      "input[name='cnfpassword']"
    );
    console.log("conf password:", cnfPwdVal.value);

    let newpwdError = { message: "", isValid: true };
    let cnfpwdError = { message: "", isValid: true };
    let validationError = { message: "", isValid: false };

    if (newPwdVal.value == "") {
      newpwdError.message = "New password can't be empty!";
      newpwdError.isValid = false;
    }

    if (cnfPwdVal.value == "") {
      cnfpwdError.message = "Confirm password can't be empty!";
      cnfpwdError.isValid = false;
    }

    if (cnfpwdError.isValid && newpwdError.isValid) {
      if (cnfPwdVal.value !== newPwdVal.value) {
        validationError.message = "New & Confirm password should be same!";
        validationError.isValid = false;
      } else {
        validationError.isValid = true;
      }
    }
    if (validationError.isValid) {
      // console.log("Password validation is success!");
      let queryString =
        "productid=" +
        this.state.pid +
        "&pwd=" +
        cnfPwdVal.value +
        "&email=" +
        this.state.email +
        "&operation=ResetPassword";
      let queryParams = {
        "productid": this.state.pid,
        "pwd": cnfPwdVal.value,
        "email": this.state.email,
        "operation": "ResetPassword",
        "domain": getCurrentDomain()
      }
      let response = await siteRequestApiService.processRequest(
        "resetPassword",
        "",
        queryParams
      );
      if (response.status != undefined) {
        showToast(
          response.message,
          "top-right",
          response.status ? "success" : "error",
          undefined,
          5000
        );
        if (response.status) {
           window.location.href = getLogoutURL();
        }
      }
    }
    newPwdVal.value = "";
    cnfPwdVal.value = "";
    this.setState({
      errorNewPwd: newpwdError,
      errorCnfPwd: cnfpwdError,
      errorValidation: validationError,
    });
  };
  render() {
    let { brandLogo, agencyLogo } = this.state;
    let favIcon = "";
    if (agencyLogo.length > 0) {
      brandLogo = agencyLogo[2];
      favIcon = agencyLogo[1];
    }
    if(brandLogo === ""){
      return (
        <Fragment>...</Fragment>
      )
    }
    return (
      <Fragment>
        <div className="h-100">
          <Fragment>
            <Link icon={favIcon} />
            <div className="h-100 bg-dark-shadow bg-animation">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                  <div className="app-logo-inverse mx-auto mb-3 text-center" >
                    <img src={brandLogo} />
                  </div>
                  <div className="modal-dialog w-100">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="h5 modal-title">
                          Reset Password
                        </div>
                      </div>
                      <div className="modal-body">
                        <div ref={this.recoverFormRef}>
                          <Form>
                            <Row form>
                              <Col md={12}>
                                <FormGroup>
                                  <Label for="newPasswordLbl">New Password</Label>
                                  <Input
                                    className={
                                      !this.state.errorNewPwd.isValid
                                        ? "is-invalid form-control-blue"
                                        : "form-control-blue"
                                    }
                                    type="password"
                                    name="newpassword"
                                    id="newpassword"
                                    placeholder="New Password"
                                  />
                                  {!this.state.errorNewPwd.isValid && (
                                    <Label className="errors" for="errorNewPassword">
                                      {this.state.errorNewPwd.message}
                                    </Label>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <FormGroup>
                                  <Label for="cnfPasswordLbl">Confirm Password</Label>
                                  <Input
                                    className={
                                      !this.state.errorCnfPwd.isValid
                                        ? "is-invalid form-control-blue"
                                        : "form-control-blue"
                                    }
                                    type="password"
                                    name="cnfpassword"
                                    id="cnfpassword"
                                    placeholder="Confirm Password"
                                  />
                                  {!this.state.errorCnfPwd.isValid && (
                                    <Label className="errors" for="errorCnfPassword">
                                      {this.state.errorCnfPwd.message}
                                    </Label>
                                  )}
                                  {!this.state.errorValidation.isValid && (
                                    <Label className="errors" for="errorValidation">
                                      {this.state.errorValidation.message}
                                    </Label>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="divider" />
                            <div className="mt-4 d-flex align-items-center">
                              <div className="ms-auto">
                                <Button size="lg" className="theme-bg-g" type="submit" color="dark"
                                  disabled={this.state.btnEditable}
                                  onClick={(e) => {
                                    this.resetAction(e);
                                  }}
                                >
                                  Reset Password
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}
