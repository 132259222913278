import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DropdownToggle, DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownItem, Button
} from 'reactstrap';
import Select from "react-select";
import { getSessionValue, parseJSON, setSessionValue } from "../../Common/Appcommon";
import backIcon from "../../assets/utils/images/back.svg"

class VerticalSubAccountList extends Component {
  constructor(props) {
    super(props);
    let menuList = getSessionValue('menus');
    let menus = (menuList !== '' && menuList !== undefined && menuList !== 'undefined' && menuList !== null) ? parseJSON(menuList) : [];
    let accountMenu = menus[2] !== undefined ? menus[2] : [];
    let accountList = [];
    let selectAccount = {value : "Choose One", label : "Switch to Sub Account"};
    let sappid = getSessionValue("subAccountAppid");
    accountMenu.map((account) => {
      let appid = account[0];
      let companyname = account[1];
      accountList.push({
        value: appid, label: companyname
      });
      if (appid === sappid) {
        selectAccount = { value: appid, label: companyname };
      }
    });
    this.state = {
      menus: menus,
      activeLinkTo: '/#',
      userProfileName: '',
      selectedAccount: selectAccount,
      accountList: accountList,
    };
  }
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  onHandleAccountChange = (selectedAccount) => {
    this.setState({
      selectedAccount
    });
    setSessionValue("isSubAccountView", true);
    setSessionValue("subAccountAppid", selectedAccount.value);
    window.location = "#/login_check";
  }

  backToAgencyViewAction = () => {
    setSessionValue("isSubAccountView", false);
    setSessionValue("subAccountAppid", "");
    window.location = "#/login_check";
  }

  render() {
    let { menus, selectedAccount, accountList } = this.state;
    if (menus.length === 0) {
      return <Fragment></Fragment>
    }
    let isSubAccountView = getSessionValue("isSubAccountView");
    return (
      <Fragment>
        <div className="metismenu vertical-nav-menu agency-subaccount-menu mb-2">
          <ul className="metismenu-container">
            {
              accountList.length > 0 &&
              <Fragment>
                <div>
                  {isSubAccountView === "true" &&
                    <div className='back-action text-left'>
                      <a className='cursor-pointer d-flex align-center' onClick={(e) => { this.backToAgencyViewAction() }}>
                      <i class="fi fi-br-arrow-left pr-1"></i> Back to Agency
                      </a>
                    </div>
                  }
                  <Select
                    placeholder={'---Choose One---'}
                    name="selected_form"
                    id="selected_form"
                    className={`basic-select`}
                    classNamePrefix="sub-select wa-select"
                    value={selectedAccount}
                    onChange={e => {
                      this.onHandleAccountChange(e);
                    }}
                    options={accountList}
                  />
                </div>
              </Fragment>
            }
          </ul>
        </div>
      </Fragment>
    )
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerticalSubAccountList));
