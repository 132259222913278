import Vapi from "@vapi-ai/web";
import { RetellWebClient, } from "retell-client-js-sdk";
import { BlandWebClient } from "bland-client-js-sdk";
import { populateFormDetails, getTemplatesFormDetails, loadFormData,prepareLabelNameMapper,updatewebAgentId } from '../../Common/Appcommon';

import {getSessionValue,showToast} from '../../Common/Appcommon';
class ProviderDetails {

}

export function getProviderKey(proName,keyName = ""){
    proName = proName.toLowerCase();
    let apiKeys = JSON.parse(getSessionValue(proName+"_api_keys"));
    let proVal = "";
    if(keyName !== ""){
        if(keyName.toLocaleLowerCase() == "public"){
            keyName = proName +"_pub_key";
        }else if(keyName.toLocaleLowerCase() == "private"){
            keyName = proName +"_prv_key";
        }
    }else{
        keyName = proName +"_api_key";
    }
    if(apiKeys !== undefined && apiKeys[keyName] !== undefined){
        proVal = apiKeys[keyName];
    }
    return proVal;
};

export async function providerConn(proName, agentId= ""){
    let proCon = "";
    if(proName == "vapi"){
        let pubKey = getProviderKey(proName,"public");
        proCon = new Vapi(pubKey);
    }else if(proName == "bland"){
        if(agentId !== null){
            let pubKey = getProviderKey(proName);
            let sesTkn = await generateBlandSessionToken(pubKey,agentId);
            if(sesTkn !== null){
                proCon = new BlandWebClient(
                    agentId, 
                    sesTkn
                )
            }
        }          
    }else if(proName == "retell"){
        proCon = new RetellWebClient();
    }
    return proCon;
};

export async function generateBlandAgent (aId,apiKey,phNum,rowData,nameLabelMapper) {
    let aDetails = await getAgDetails(apiKey,phNum);
    let response = await fetch(`https://api.bland.ai/v1/agents`, {
        method: "POST",
        body: JSON.stringify({
            prompt : rowData[nameLabelMapper["System_Prompt"]],
            first_sentence: rowData[nameLabelMapper["Call_Start_Message"]], 
            max_duration: aDetails["max_duration"],
            model:aDetails["model"], 
            voice : aDetails['voice'], 
            analysis_schema : aDetails["analysis_schema"] ,
            metadata : aDetails["metadata"],
            pathway_id : aDetails["pathway_id"],
            language : aDetails["language"],
            webhook : aDetails["webhook"],
            tools : aDetails["custom_tools"],
            dynamic_data : aDetails["dynamic_data"],
            interruption_threshold : aDetails["interruption_threshold"],
            keywords : aDetails["keywords"],
        }),
        headers: {
            "Content-type": "application/json",
            "Authorization": apiKey 
        }
    });
    if (response.ok) {
        let data = await response.json();
        let  res = await updatewebAgentId(aId,data.agent.agent_id);
        return data.agent.agent_id;
    }else {
        console.error("Failed to update agent:", response.status, response.statusText);
        return null;
    }
};

export async function generateBlandSessionToken (apiKey,agentId) {
    let response = await fetch(`https://api.bland.ai/v1/agents/${agentId}/authorize`, {
        method: "POST",
        headers: {
            "Authorization": apiKey
        }
    });
    if (response.ok) {
        let data = await response.json();
        return data.token;
    }else {
        console.error("Failed to update agent:", response.status, response.statusText);
        return null; 
    }
};

export async function updateBlandAgent(apiKey,agentId,phNum,rowData,nameLabelMapper) {
    let aDetails = await getAgDetails(apiKey,phNum);
    let response = await fetch(`https://api.bland.ai/v1/agents/${agentId}`, {
        method: "POST",
        body: JSON.stringify({
            prompt: rowData[nameLabelMapper["System_Prompt"]],
            first_sentence: rowData[nameLabelMapper["Call_Start_Message"]],            
            max_duration: aDetails["max_duration"],
            model:aDetails["model"], 
            voice : aDetails['voice'], 
            analysis_schema : aDetails["analysis_schema"] ,
            metadata : aDetails["metadata"],
            pathway_id : aDetails["pathway_id"],
            language : aDetails["language"],
            webhook : aDetails["webhook"],
            tools : aDetails["custom_tools"],
            dynamic_data : aDetails["dynamic_data"],
            interruption_threshold : aDetails["interruption_threshold"],
            keywords : aDetails["keywords"],

        }),
        headers: {
            "Content-type": "application/json",
            "Authorization": apiKey
        }
    });
    if (response.ok) {
        let data = await response.json();
        return agentId; 
    } else {
        console.error("Failed to update agent:", response.status, response.statusText);
        return null; 
    }
}
export async function getAgDetails (apiKey,phoneNumber) {
    let response = await fetch(`https://api.bland.ai/v1/inbound/${phoneNumber}`, {
        method: "GET",
        headers: {
            "Authorization": apiKey
        }
    });
    if (response.ok) {
        let data = await response.json();
        return data;
    } else {
        console.error("Failed to get agent:", response.status, response.statusText);
        return null; // Returns null if the update fails
    }
   
};


export async function fetchPhNum (searchStr,formname) {
    let phNum = "";
    let skey = formname+"_id";
    let fDetail = await populateFormDetails("table_247");
    let nameLabelMapperList = await prepareLabelNameMapper(fDetail[1]);
    let { templates, selectedTemplate } = await getTemplatesFormDetails(fDetail,"Recently Created");
    let adFilterSearch = "nondate@table_250_0@"+skey+"@is@"+searchStr+"@AND#";
    let phData = await loadFormData("table_247", fDetail, selectedTemplate,"",adFilterSearch);
    if (phData.issuccess) {
        let { formData} = phData;
        let phInfo = formData[0];
        phNum = phInfo[nameLabelMapperList["Phone_Number"]];
    }
    return phNum;
}

export async function getAssistantDetails(apiKey, agentId) {
    try {
        const response = await fetch(`https://api.vapi.ai/assistant/${agentId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${apiKey}`, // Ensure 'Bearer' prefix if required
                "Content-Type": "application/json"
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            let errorText = await response.json();
            errorText = errorText.message;
            showToast("ERROR! Agent " + errorText,'top-right','error');
            return null;
        }
    } catch (error) {
        showToast("ERROR!" +error,'top-right','error');
        return null;
    }
}

export default ProviderDetails;