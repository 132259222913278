import React, { useState, Fragment } from 'react';
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import Common from '../Common';
import { Button, Input, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../Common/Loading';
import { useEffect } from 'react';
import ListTable from '../../Common/ListTable';
import { populateFormDetails, getTemplatesFormDetails, loadFormData, prepareLabelNameMapper, prepareTableData } from '../../Common/Appcommon';
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';
const Contacts = (props) => {
    const [showLoading, setShowLoading] = useState(false);
    const [data, setData] = useState([]);
    const [column, setColumns] = useState([]);
    const [searchStr, setSearchString] = useState('');
    let isAddContact = false;

    let appid = "";
    let data_row = {};
    let { accountInfo } = props;
    if (accountInfo !== undefined) {
        data_row = accountInfo.data_row;
        appid = data_row.appid;
    }

    useEffect(() => {
        const loadContacts = async () => {
            setShowLoading(true);
            await fetchContacts("");
        };
        loadContacts();
    }, []);



    const fetchContacts = async (searchValue) => {
        let formDetail = await populateFormDetails("table_74", appid);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let usersData = await loadFormData("table_74", formDetail, selectedTemplate, appid, "", true, [], searchValue);
        if (usersData.issuccess) {
            setShowLoading(false);
            let { formData, listFields } = usersData;
            let nameLabelMapper = await prepareLabelNameMapper(formDetail[1]);
            let tableData = await prepareTableData(formData, listFields);
            setData(tableData.data);
            setColumns(tableData.columns);
        }
    }
    const toggleModal = () => {
    };
    const handleInputChange = (e) => {
        let value = e.target.value;
        setSearchString(value);
        setShowLoading(true);
        if (value !== undefined && value !== null) {
            fetchContacts(value);
        }
    };

    const getHeaderTitleBody = (isAddContact) => {
        if (!isAddContact) {
            return (<div className='d-flex align-center justify-end'>
                <Col md={7}>
                    <div className='le-form-row'>
                        <div className="input-with-icon">
                            <span className="search-icon">
                                <i class="icon-color fi fi-rs-search"></i>
                            </span>
                            <Input
                                id="userName"
                                autoComplete="off"
                                placeholder="Search by name"
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                                value={searchStr}
                                className='search-input'
                            />
                        </div>
                    </div>
                </Col>
                <Col md={5} className='ml-2'>
                    <Button size="lg" className='theme-bg-g compact-padding' color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Add Contact</Button>
                </Col>
            </div>)
        } else {
            return (
                <div>
                    <Button size="lg" className='theme-bg-g' color="dark" onClick={toggleModal}><FontAwesomeIcon icon={faPlus} /> Add Contact</Button>
                </div>
            )
        }
    }

    return (
        <Fragment>
            <Common title={"Contacts"}
                titleContent={
                    <span>Contacts<span class="count">{data.length}</span></span>
                }
                titleBody={getHeaderTitleBody(isAddContact)}
                mainContent={
                    <Fragment>
                        <div className='main-table-list'>
                            <Loading showLoader={showLoading} mainContent={
                                <Fragment>
                                    {data.length > 0 && column.length > 0 &&
                                        <ListTable data={data} columns={column} />
                                    }
                                    {
                                        data.length === 0 &&
                                        <NoRecordFound
                                            img={aiData}
                                            isOnlyContent={true}
                                            content={"No Numbers Found."}
                                        />
                                    }
                                </Fragment>
                            } />
                        </div>
                    </Fragment>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
