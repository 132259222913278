import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Common from '../Common';
import {
    Row,
    Col,
    Container,
    Button,
    Card,
    CardBody
} from "reactstrap";

import "./assets/css/index.css"
import CratioFileUploader from '../Common/CratioFileUploader';
import { faPlus, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { getLinkData, handleErrorResponse, setLinkData, showToast, updateOrganizationInfo, uploadFileIntoS3, confirmBox, parseJSON, setSessionValue, getSessionValue, updateAgencyInfo } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
const General = () => {
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors },trigger } = useForm();
    const saveBtnRef = useRef(null);
    const [formData, setFormData] = useState({});
    const [verified, setVerified] = useState(false);
    const [verifyStatus, setVerifyStatus] = useState(false);
    //let [linkData, setLinkData] = useState([]);
    let [variationList, setVariationsList] = useState([]);
    let [variationCount, setVariationCount] = useState(0);
    const maxVariactionCount = 4;
    useEffect(() => {
        (async () => {
            await fetchAgencyDetails();
            await loadSenderData();
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        onAddMoreVariants(variationCount);
        return () => {
            // this now gets called when the component unmounts
        };
    }, [variationCount]);

    const fetchAgencyDetails = async () => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_AGENCY_DETAILS",
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let data = formresponse.data;
            let helpInfo = data['help_info'];
            if (helpInfo !== "" && helpInfo !== null && helpInfo.length > 0) {
                let linkData = helpInfo;
                setLinkData(linkData);
                setVariationCount(helpInfo.length);
                //setLinkData([...helpInfo]);
            } else {
                setVariationCount(1);
            }
            setFormData(data);
        }
    }

    const submitAgencyDetails = async () => {
        await trigger('agent_email');
        formData['help_info'] = getLinkData();
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "SAVE_AGENCY_DETAILS",
            "formData": formData
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let orgDetail = formresponse.data;
            updateOrganizationInfo(orgDetail);
            let agencyDetails = [];
            agencyDetails.push(orgDetail);
            updateAgencyInfo(agencyDetails);
            showToast("Success! Saved successfully!");
            window.location.reload();
        }
    }

    const removeVariations = async (removedIndex) => {
        delete variationList.splice(removedIndex, 1);
        let tmpLinkData = getLinkData();
        let tmpArray = [];
        let newindex = -1;
        for (let i = 0; i < tmpLinkData.length; i++) {
            let data = tmpLinkData[i];
            if (i !== removedIndex) {
                newindex = newindex + 1;
                let newdata = {};
                for (let key in data) {
                    if (key.indexOf('help-name') > -1) {
                        newdata['help-name-' + newindex] = data[key];
                    } else if (key.indexOf('help-link') > -1) {
                        newdata['help-link-' + newindex] = data[key];
                    }
                }
                tmpArray.push(newdata);
            }
        }
        setLinkData(tmpArray)
        setVariationCount(prevCount => prevCount - 1);
        setVariationsList(variationList);
    }

    const addVariation = () => {
        if (variationCount <= maxVariactionCount) {
            setVariationCount(prevCount => prevCount + 1);
            onAddMoreVariants(variationCount + 1);
        }
    };

    const onAddMoreVariants = (variationCount) => {
        if (variationCount <= maxVariactionCount) {
            let data = getLinkData();
            let vl = [];
            for (let vi = 0; vi < variationCount; vi++) {
                let nl = "help-name-" + vi;
                let ll = "help-link-" + vi;
                vl.push(
                    <div className='d-flex align-center'>
                        <Col md={6}>
                            <input type="text"
                                defaultValue={data[vi] ? data[vi][nl] : ''}
                                name={nl}
                                onChange={(e) => {
                                    onLinkInputChange(e, vi);
                                }}
                                className="form-control"
                                key={vi} />
                        </Col>
                        <Col md={6} className='d-flex align-center'>
                            <input type="text"
                                defaultValue={data[vi] ? data[vi][ll] : ''}
                                name={ll}
                                onChange={(e) => {
                                    onLinkInputChange(e, vi);
                                }}
                                className="form-control"
                                key={vi} />
                            {
                                variationCount > 1 && <a className={`cursor-pointer input-variation-remove ml-2`} onClick={() => removeVariations(vi)}>
                                    <FontAwesomeIcon icon={faWindowClose} fontSize={2} className={`mr-1 theme-c-g`} />
                                </a>
                            }
                        </Col>
                    </div>
                )
            }
            setVariationsList(vl);
        }
    }

    const onLinkInputChange = async (e, vi) => {
        let linkData = getLinkData();
        const { name, value } = e.target;
        if (linkData[vi] === undefined) {
            linkData[vi] = {};
        }
        linkData[vi][name] = value;
        setLinkData(linkData);
    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const onSaveBtnClick = () => {
        saveBtnRef.current.click();
    }

    const handleFileUpload = async (e, name) => {
        let { formInputData } = await uploadFileIntoS3(e, formData, name);
        setFormData(formInputData);
    };
      
    const verifyEmail = async () => {
        const isValidEmail = await trigger('sender_email');
        if (!isValidEmail) {
            return;
        }
        let fromName = formData.sender_name;
        let fromAddress = formData.sender_email;
        if (fromName !== undefined && fromName !== "" && fromAddress !== undefined && fromAddress !== "") {
            let params = {
                "ENTITY": "SenderAddress",
                "OPERATION": "SAVE",
                "fromaddress": fromAddress,
                "fromname": fromName,
            };
            let response = await requestApiService.processServerRequest(params);
            if (response.issuccess) {
                setVerified(true);
                showToast('Email Sent! Please verify your email.')
            } else {
                handleErrorResponse("Error! Something went wrong.");
            }
        } else {
            handleErrorResponse('Please provide Sender Name And Email Address.')
        }
    }
    const resendEmailVerification = async () => {
        if (formData['status'] === "Verified") {
            showToast(" Your email is already verified.", "", "error")
            return
        };
        let fromAddress = formData.sender_email;
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "RESEND",
            "fromaddress": fromAddress,
        }
        let response = await requestApiService.processServerRequest(params);
        if (!response.issuccess) {
            handleErrorResponse("Error! Something went wrong.", "", "error");
        } else {
            showToast("Email Resent! Check your inbox.")
        }

    }

    const deleteVerifiedEmail = async () => {

        let confirm = await confirmBox("Are you sure you want to delete?", "Press Delete to confirm",'warning','Delete','Cancel');
        if (!confirm) {
            return;
        }
        let fromAddress = formData.sender_email;
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "DELETE",
            "fromaddress": fromAddress,
        }
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            showToast("Deleted! The provided email ID has been removed.");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            showToast("Error! Something went wrong.", "", "error");
        }
    }

    const loadSenderData = async () => {
        let params = {
            "ENTITY": "SenderAddress",
            "OPERATION": "GETALL",
            "isWhatsapp": true
        };
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            if (data.length > 0 && data[0][0] !== undefined) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    sender_name: data[0][0]['fromname'],
                    sender_email: data[0][0]['fromaddress']
                }));
                if (data[0][0]['status'] !== undefined && data[0][0]['status'] === "Verified") {
                    setVerifyStatus(true);
                }
                setVerified(true);
            }
        }
    }

    return (
        <Fragment>
            <Common title="Profile Settings" titleContent={
                <div className='d-grid'>
                    <span>Profile Settings</span>

                </div>
            }
                titleBody={
                    <Fragment><Button onClick={(e) => { onSaveBtnClick(e) }} className="btn-hover-shine theme-bg-g custom-display" color="light"><i class="fi fi-br-disk"></i> <span>Save Profile</span></Button></Fragment>
                }
                mainContent={
                    <Container className='m-auto max-width-65'>
                        <form id="agency-form" name={'agency'} onSubmit={handleActionFormSubmit((data, event) => submitAgencyDetails(event))}>
                            <div className='agency-details'>
                                <div className='card-fields pb-3 d-grid'>
                                    <span class="heading layout-title" >Agency Profile</span>
                                    <span className='header-desc'>Manage your agency information</span>
                                </div>
                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Primary Contact</span>
                                                <span className='header-desc'>To notify you about activities related to your customer's account (such as app usage, payment issues, etc.)</span>
                                            </div>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="agent_name">Contact Name</label>
                                                    <Controller
                                                        name="agent_name"
                                                        control={control}
                                                        rules={{ required: formData.agent_name === undefined ? "Please provide Name" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="agent_name"
                                                                    id="agent_name"
                                                                    value={formData.agent_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.agent_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.agent_name && <em className={`error invalid-feedback`}>{errors.agent_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2 hide'>
                                                    <label className={'form-label'} htmlFor="agent_email">Email Address</label>
                                                    <Controller
                                                        name="agent_email"
                                                        control={control}
                                                        rules={{ required: formData.agent_email === undefined ? "Please provide Email Address" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="email"
                                                                    name="agent_email"
                                                                    id="agent_email"
                                                                    value={formData.agent_email}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.agent_email ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.agent_email && <em className={`error invalid-feedback`}>{errors.agent_email.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label'} htmlFor="agent_mobile">Phone Number (with country code)</label>
                                                    <Controller
                                                        name="agent_mobile"
                                                        control={control}
                                                        rules={{
                                                            validate: (value) => {
                                                                let mobileNumber = (value === undefined || value === null || value === "") ? formData.agent_mobile : value.trim();
                                                                if (!mobileNumber || mobileNumber.length < 13) {
                                                                    return "Please provide a valid mobile number with country code";
                                                                }}}}
                                                                                                                                                                        
                                                        render={({ field }) => {
                                                            return (
                                                            <input
                                                                {...field}
                                                                type="text"
                                                                name="agent_mobile"
                                                                id="agent_mobile"
                                                                maxLength={14}
                                                                value={formData.agent_mobile}
                                                                autoComplete={"off"}
                                                                onChange={(e) => {
                                                                    const { value } = e.target;
                                                                    if (/^[^a-zA-Z]*$/.test(value)) {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }
                                                                }}                                                                                                                           
                                                                className={`form-control ${errors.agent_mobile ? 'is-invalid' : ''}`}
                                                            />
                                                            );
                                                        }}
                                                    />
                                                    {errors.agent_mobile && <em className={`error invalid-feedback`}>{errors.agent_mobile.message}</em>}
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>


                                </div>
                            </div>
                            <div className='agency-details'>
                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Billing Information</span>
                                                <span className='header-desc'>This information will be used for generating subscription invoices on your behalf.</span>
                                            </div>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="billing_name">Billing Name</label>
                                                    <Controller
                                                        name="billing_name"
                                                        control={control}
                                                        rules={{ required: formData.billing_name === undefined  && formData.billing_name  === '' ? "Please Billing Name" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="billing_name"
                                                                    id="billing_name"
                                                                    value={formData.billing_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.billing_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.billing_name && <em className={`error invalid-feedback`}>{errors.billing_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label'} htmlFor="agent_email">Email Address</label>
                                                    <Controller
                                                        name="agent_email"
                                                        control={control}
                                                        rules={{
                                                            required: formData.agent_email === undefined,
                                                            pattern: {
                                                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                                              message: "Please provide a valid Email Address"
                                                            }
                                                          }}                                                          
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="email"
                                                                    name="agent_email"
                                                                    id="agent_email"
                                                                    value={formData.agent_email}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.agent_email ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.agent_email && <em className={`error invalid-feedback`}>{errors.agent_email.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="address_1">Address Line 1</label>
                                                    <Controller
                                                        name="address_1"
                                                        control={control}
                                                        rules={{ required: formData.address_1 === undefined ? "Please provide Address Line 1" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="address_1"
                                                                    id="address_1"
                                                                    value={formData.address_1}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.address_1 ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.address_1 && <em className={`error invalid-feedback`}>{errors.address_1.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="address_2">Address Line 2</label>
                                                    <Controller
                                                        name="address_2"
                                                        control={control}
                                                        rules={{ required: formData.address_2 === undefined ? "Please provide Address Line 2" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="address_2"
                                                                    id="address_2"
                                                                    value={formData.address_2}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.address_2 ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.address_2 && <em className={`error invalid-feedback`}>{errors.address_2.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="city_name">City</label>
                                                    <Controller
                                                        name="city_name"
                                                        control={control}
                                                        rules={{ required: formData.city_name === undefined ? "Please provide City" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="city_name"
                                                                    id="city_name"
                                                                    value={formData.city_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.city_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.city_name && <em className={`error invalid-feedback`}>{errors.city_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="zip_code">Zip Code</label>
                                                    <Controller
                                                        name="zip_code"
                                                        control={control}
                                                        rules={{ required: formData.zip_code === undefined ? "Please provide Zip Code" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="zip_code"
                                                                    id="zip_code"
                                                                    value={formData.zip_code}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.zip_code ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.zip_code && <em className={`error invalid-feedback`}>{errors.zip_code.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="state_name">State</label>
                                                    <Controller
                                                        name="state_name"
                                                        control={control}
                                                        rules={{ required: formData.state_name === undefined ? "Please provide State" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="state_name"
                                                                    id="state_name"
                                                                    value={formData.state_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.state_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.state_name && <em className={`error invalid-feedback`}>{errors.state_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="country_name">Country</label>
                                                    <Controller
                                                        name="country_name"
                                                        control={control}
                                                        rules={{ required: formData.country_name === undefined ? "Please provide Country" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="country_name"
                                                                    id="country_name"
                                                                    value={formData.country_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.country_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.country_name && <em className={`error invalid-feedback`}>{errors.country_name.message}</em>}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>

                            </div>
                            <div className='agency-details'>

                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Brand Information</span>
                                                <span className='header-desc'>Enables you to rebrand the app with your own brand name.</span>
                                            </div>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="brand_name">Brand Name</label>
                                                    <Controller
                                                        name="brand_name"
                                                        control={control}
                                                        rules={{ required: formData.brand_name === undefined ? "Please provide Name" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="brand_name"
                                                                    id="brand_name"
                                                                    value={formData.brand_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.brand_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.brand_name && <em className={`error invalid-feedback`}>{errors.brand_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="brand_website">Brand Website</label>
                                                    <Controller
                                                        name="brand_website"
                                                        control={control}
                                                        rules={{ required: formData.brand_website === undefined ? "Please provide Website" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    {...field}
                                                                    type="text"
                                                                    name="brand_website"
                                                                    id="brand_website"
                                                                    value={formData.brand_website}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.brand_website ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.brand_website && <em className={`error invalid-feedback`}>{errors.brand_website.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label'} htmlFor="brand_logo">Brand Logo (Dark)</label>
                                                    <CratioFileUploader name="brand_logo" fileName={formData['brand_logo'] !== undefined && formData['brand_logo'] !== null ? formData['brand_logo']['fileName'] : ""} mediaUrl={formData['brand_logo'] !== undefined && formData['brand_logo'] !== null ? formData['brand_logo']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} />
                                                    <span className='field-desc'>Recommended size: 120px (width) x 24px (height), 5:1 aspect ratio</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label'} htmlFor="brand_logo">Brand Logo (Light)</label>
                                                    <CratioFileUploader name="brand_logo_light" fileName={formData['brand_logo_light'] !== undefined && formData['brand_logo_light'] !== null  ? formData['brand_logo_light']['fileName'] : ""} mediaUrl={formData['brand_logo_light'] !== undefined && formData['brand_logo_light'] !== null ? formData['brand_logo_light']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} />
                                                    <span className='field-desc'>Recommended size: 120px (width) x 24px (height), 5:1 aspect ratio</span>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label'} htmlFor="brand_favicon">Brand Favicon</label>
                                                    <CratioFileUploader name="brand_favicon" fileName={formData['brand_favicon'] !== undefined && formData['brand_favicon'] !== null ? formData['brand_favicon']['fileName'] : ""} mediaUrl={formData['brand_favicon'] !== undefined && formData['brand_favicon'] !== null ? formData['brand_favicon']['mediaUrl'] : ""} errors={{}} handleFile={handleFileUpload} />
                                                    <span className='field-desc'>Recommended size: 96px (width) x 96px (height), 1:1 aspect ratio</span>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </div>

                            </div>
                            <div className='agency-details'>

                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Transactional Email</span>
                                                <span className='header-desc'>This email address will be used to send notifications to your customers on your behalf (e.g., app usage, payment issues, user invites, password resets, etc.)</span>
                                            </div>
                                            <Row>
                                                <Col md={10} className='field-section'>
                                                    <label className={'form-label'} htmlFor="sender_name">Sender Name</label>
                                                    <Controller
                                                        name="sender_name"
                                                        control={control}
                                                        rules={{ required: formData.sender_name === undefined ? "Please provide Sender Name" : "" }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    disabled={verified}
                                                                    {...field}
                                                                    type="text"
                                                                    name="sender_name"
                                                                    id="sender_name"
                                                                    value={formData.sender_name}
                                                                    autoComplete={"off"}
                                                                    onChange={(e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.sender_name ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {errors.sender_name && <em className={`error invalid-feedback`}>{errors.sender_name.message}</em>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={8} className="field-section mt-2">
                                                    <label className="form-label" htmlFor="sender_email">
                                                        Sender Email Address
                                                    </label>
                                                    <Controller
                                                        name="sender_email"
                                                        control={control}
                                                        rules={{
                                                            required: formData.sender_email === undefined,
                                                            pattern: {
                                                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                                                message: "Please provide valid sender Email Address"
                                                            }
                                                        }}
                                                        render={({ field }) => {
                                                            return (
                                                                <input
                                                                    disabled={verified}
                                                                    {...field}
                                                                    type="email"
                                                                    name="sender_email"
                                                                    id="sender_email"
                                                                    value={formData.sender_email}
                                                                    autoComplete={"off"}
                                                                    onChange={async (e) => {
                                                                        onInputChange(e);
                                                                        field.onChange(e);
                                                                    }}
                                                                    className={`form-control ${errors.sender_email ? 'is-invalid' : ''}`}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={4} className='sender-div field-section'>
                                                    <div className='d-flex'>
                                                        <div className='cursor-pointer' style={{ height: '1px' }}>
                                                            {verified ? (
                                                                <>
                                                                    {!verifyStatus ? (
                                                                        <>
                                                                            <div className='badge bg-warning me-2'>Unverified</div>
                                                                            <div onClick={loadSenderData} className="badge bg-success me-2">
                                                                                <i className='pe-7s-refresh-2'></i>
                                                                            </div>
                                                                            <div className='badge bg-info me-2' onClick={resendEmailVerification}>
                                                                                <i class="fi fi-rs-paper-plane"></i>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="badge bg-success me-2">Verified</div>
                                                                    )}
                                                                    <div className="badge bg-danger" onClick={deleteVerifiedEmail}>Delete</div>
                                                                </>
                                                            ) : (
                                                                <span onClick={verifyEmail} className='badge bg-success me-2'>Verify</span>
                                                            )}
                                                        </div>

                                                    </div>

                                                </Col>
                                                {errors.sender_email && (
                                                        <em className="error invalid-feedback d-block">{errors.sender_email.message}</em>
                                                )}
                                            </Row>
                                            <div className='mt-4'>
                                                <label className={'form-label'}>
                                                    Steps to verify your email address:
                                                </label>
                                                <li className='sender-list'>1. Enter the sender name and email address.</li>
                                                <li className='sender-list'>2. You’ll receive a verification email from Amazon Web Services.</li>
                                                <li className='sender-list'>3. Click the link in the email to verify your address.</li>
                                                <li className='sender-list'>4. After verification, click "Sync" to connect the email to the platform.</li>
                                                <li className='sender-list'>5. Once synced, the status will update to "Connected.</li>
                                            </div>
                                            <div className='mt-4'>
                                                <label className={'form-label'}>
                                                    Note:
                                                </label>
                                                <li className='sender-list'>Check your spam folder if the email doesn’t appear in your inbox, as it may be there. </li>
                                                <li className='sender-list'>If not received, click the "Resend" button to send it again.</li>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>

                            </div>
                            <div className='agency-details'>

                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Support Links</span>
                                                <span className='header-desc'>Offer links to support your customers, such as support email, knowledge base, and community links.</span>
                                            </div>
                                            <Row>
                                                <Col md={10} className='field-section mt-2'>
                                                    <label className={'form-label hide'} htmlFor="support_links">Support Links</label>
                                                    <div className='d-flex align-center'>
                                                        <Col md={6}>
                                                            <label className='form-label' >Name</label>
                                                        </Col>
                                                        <Col md={6}>
                                                            <label className='form-label' >Link</label>
                                                        </Col>
                                                    </div>
                                                    {
                                                        variationList.length > 0 &&
                                                        variationList.map((variation) => {
                                                            return variation;
                                                        })
                                                    }
                                                    {
                                                        variationList.length < maxVariactionCount &&
                                                        <div className={'button-panel'}>
                                                            <a className={`cursor-pointer option-addmore ${variationCount >= maxVariactionCount ? 'btn-disabled' : ''}`} onClick={(e) => { addVariation(e) }}>
                                                                <FontAwesomeIcon className='theme-c-g' icon={faPlus} /> <span className='theme-c-g'>Add More</span>
                                                            </a>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>


                                </div>
                            </div>
                            <div className='hide'>
                                <button ref={saveBtnRef} class="btn btn-outline-primary">Save & Activate</button>
                            </div>
                        </form>
                    </Container>

                } />


        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
