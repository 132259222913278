import axios from "axios";


class siteRequestApiService {
  constructor() {}

  async processRequest(operation,uriParams, params = []) {
   /* let requestUrl = process.env.REACT_APP_SERVER_URL;
      let appHost = window.location.hostname;
      if(appHost.indexOf("cratio.com") > 0){
          requestUrl = process.env.REACT_APP_SITE_HOSTING_URL;
      }*/
    let appHost = window.location.hostname;
    let protocol = window.location.protocol;
    let requestUrl = protocol + "//" + appHost+"/server";
    let request = axios.create({
      baseURL: requestUrl,
    });
    let path="/signinprocess_n.php";
    if(operation == "resetPassword"){
        path="/forgotpwdprocess_n.php";
    } else if (operation == "SIGNUP"){
        path = "/dacamweb/signupprocessor.php";
        params = { 'in' : JSON.stringify(params) };
    } else if (operation == "glogin"){
      path="/gsigninprocess.php";
    }
      let res = await request.post(path+"?" + uriParams, params,
        {
            withCredentials :true,
            headers:{
            'content-type' : 'multipart/form-data'
          }
        }
    );
    let responseData = res.data;
    let isSuccess = true;
    let errorMsg = "";
    if(operation === "SIGNUP"){
      if(responseData[0] === "" || responseData[0] === null || responseData[0] === "null"){
        isSuccess = true;
      } else {
        isSuccess = false;
        errorMsg = responseData[0];
      }
      let serverRes = {
        issuccess : isSuccess,
        errormsg : errorMsg,
        data : responseData[2]
      };
      return serverRes;
    }
        
    return res.data;
  }
}

export default new siteRequestApiService();
