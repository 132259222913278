import React, { useState, useEffect } from 'react';

const TextLoading = (props) => {
    const [text, setText] = useState(''); // Track the text to display
    const message = props.message; // Text to type out
    const typingSpeed = 150; // Speed of typing, in milliseconds

    useEffect(() => {
        let index = 0; // Start at the beginning of the message

        const intervalId = setInterval(() => {
            setText((prev) => prev + message[index]); // Add one character at a time
            index += 1;

            // Stop the interval once we reach the end of the message
            if (index === message.length) {
                clearInterval(intervalId);
            }
        }, typingSpeed);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, []);

    return <div style={{ fontSize: '1.5em' }}>{text}</div>;
}

export default TextLoading;
