import React, { Fragment,useEffect, useRef, useState,forwardRef, useImperativeHandle } from "react";
import { Controller,useForm } from "react-hook-form";
import {Card, CardBody, CardHeader, Container,Row,Col,Spinner} from "reactstrap";
import { connect } from "react-redux";
import { updateOrganizationInfo,getCredentials,getOrganizationInfo } from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from '../Subscription/CheckoutForm';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Payments from "../../Components/Subscription/Payments";
import stripe from "./assets/images/stripe.png";

import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import GobackButton from "../../Common/GoBack";


const ManageSubscription = (props) => {
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors } } = useForm();
    const { selectedPlan, refSubmitBtn, isUsagePage, noofMonths, customFunction } = props;
    const [formData, setFormData] = useState({});
    const [variationCount, setVariationCount] = useState(0);
    const [stripePromise,setStripePromise] = useState();
    const [paymentDetails,setPaymentDetails] = useState({});
    const [orgDeatails,setOrgDetails] =  useState({});
    const [updatedFormData,setUpdatedFormData] = useState({});
    const [mainLoader, setMainLoader] = useState(false);
    const callPaymentFunc = useRef();

    useEffect( async () => {
        let stripe = await getCredentials();
        setStripePromise(stripe);
        return () => {
        };
    }, [variationCount]);
   
    useEffect( async () => {
        setMainLoader(true);
        try {
            let payDetails = await getPaymentDetails();
            if (payDetails) {
                try {
                    payDetails = JSON.parse(payDetails);
                    if (Object.keys(payDetails).length > 0) {
                        setPaymentDetails(payDetails);
                    }
                } catch (error) {
                    console.error("Invalid JSON string:", error);
                }
            }
            let orgData = getOrganizationInfo("all");
            setOrgDetails(orgData); 
            if (selectedPlan) {
                setFormData((prevData) => ({
                    ...prevData,
                    ...selectedPlan,
                }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setMainLoader(false); 
        }
    }, []);


    const submitSubsciptionDetails = async (event) => {
        event.preventDefault();
        let fData = [];
        let newformData = { ...selectedPlan, ...updatedFormData }; 
        fData.push(newformData);
        await updateBillInfo();
        if (callPaymentFunc.current) {
            let result = await callPaymentFunc.current.processPayment();
        }
    }

    const updateBillInfo = async () =>{
        orgDeatails.refill_min = paymentDetails?.refill_min ?? "";
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_BILLING_INFO",
            "ORG_INFO": orgDeatails,
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let orgDetail = formresponse.data;
            updateOrganizationInfo(orgDetail);
        }
    }
    const getPaymentDetails = async () => {
        let params = {
            "ENTITY": "STRIPE_PROCESSOR",
            "OPERATION": "GET_PAYMENTDETAILS",
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let decodeddata = formresponse.data;
            decodeddata = base64_decode(decodeddata);
            return decodeddata;
        }
    }

    const onInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onPayInputChange = async (e) => {
        const { name, value } = e.target;
        setPaymentDetails((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setUpdatedFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onBillInputChange = async (e) => {
        const { name, value } = e.target;
        setOrgDetails((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setUpdatedFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    return(
        <Fragment>
            {
                mainLoader ? (
                    <Fragment>
                        <div className="manage-sub-loader">
                            <Spinner size="sm"/>
                        </div>
                    </Fragment>
                ) : ( 
                    <Container className='m-auto max-width-65'>
                        <GobackButton name={isUsagePage ? "update_btn" : ""} customFunction={customFunction}/>
                        <form id="manage-sub-form" name={'managesub'} onSubmit={handleActionFormSubmit((data, event) => submitSubsciptionDetails(event))}>
                            <div className='agency-details'>
                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Plan Information</span>
                                            </div>
                                            <div className="mnge-sub-grp">
                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="plan_name">Plan</label>
                                                        <Controller
                                                            name="plan_name"
                                                            control={control}
                                                            rules={{ required: formData.plan_name === undefined ? "Please provide Plan" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        readOnly= {true}
                                                                        type="text"
                                                                        name="plan_name"
                                                                        id="plan_name"
                                                                        value={formData.plan_name}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.plan_name ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.plan_name && <em className={`error invalid-feedback`}>{errors.plan_name.message}</em>}
                                                    </Col>

                                                    <Col md={6} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="plan_cost">Charges</label>
                                                        <Controller
                                                            name="plan_cost"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="plan_cost"
                                                                        id="plan_cost"
                                                                        maxLength={14}
                                                                        readOnly= {true}
                                                                        value={formData.plan_cost}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.plan_cost ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.sub_charge && <em className={`error invalid-feedback`}>{errors.sub_charge.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="free_minutes">Free Minutes</label>
                                                        <Controller
                                                            name="free_minutes"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="free_minutes"
                                                                        id="free_minutes"
                                                                        maxLength={14}
                                                                        readOnly= {true}
                                                                        value={formData.free_minutes}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.free_minutes ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.free_minutes && <em className={`error invalid-feedback`}>{errors.free_minutes.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="addon_cost_per_call">Add-on Minute Charges</label>
                                                        <Controller
                                                            name="addon_cost_per_call"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="addon_cost_per_call"
                                                                        id="addon_cost_per_call"
                                                                        maxLength={14}
                                                                        readOnly= {true}
                                                                        value={formData.addon_cost_per_call}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.addon_cost_per_call ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.addon_cost_per_call && <em className={`error invalid-feedback`}>{errors.addon_cost_per_call.message}</em>}
                                                    </Col>
                                                </Row>

                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={12} className='field-section mt-2'>
                                                        <label className={'form-label'} htmlFor="refill_min">Refill Add-on Minutes</label>
                                                        <Controller
                                                            name="refill_min"
                                                            control={control}
                                                            defaultValue={paymentDetails?.refill_min ? parseInt(paymentDetails.refill_min, 10) : ''} // Ensure number is parsed
                                                            rules={{
                                                                required: paymentDetails?.refill_min ? false : "This field is required",
                                                                validate: {
                                                                    inRange: (value) => {
                                                                        if(value === "" || value === undefined){
                                                                            value = paymentDetails?.refill_min;
                                                                        }
                                                                        const numberValue = parseInt(value, 10);
                                                                        if (isNaN(numberValue)) return "Please enter a valid number";
                                                                        if (numberValue < 100) return "Refill Minutes must be at least 100";
                                                                        if (numberValue > 500) return "Refill Minutes cannot exceed 500";
                                                                        return true;
                                                                    },
                                                                },
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="refill_min"
                                                                        id="refill_min"
                                                                        min="100"
                                                                        max="500"
                                                                        value={paymentDetails?.refill_min ?? ''}                                                            autoComplete="off"
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value;
                                                                            if (inputValue === '' || !isNaN(inputValue)) {
                                                                                field.onChange(inputValue); 
                                                                                onPayInputChange(e); 
                                                                            }
                                                                        }}
                                                                        className={`form-control ${errors.refill_min ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.refill_min && <em className={`error invalid-feedback`}>{errors.refill_min.message}</em>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className='agency-details'>
                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-6'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Payments & Billing Information</span>
                                            </div>
                                            <div className="mnge-sub-grp">
                                                <Row className={"manage-sub-row"} ml={2} me ={2}>
                                                {
                                                    stripePromise &&
                                                    <Elements stripe={stripePromise}>
                                                        <CheckoutForm 
                                                            isUsagePage={isUsagePage} 
                                                            handleCheckOutBox={true} 
                                                            paymentDetails={paymentDetails}
                                                            ref={callPaymentFunc} 
                                                            billingDetails={orgDeatails}
                                                            selectedPlan={selectedPlan}
                                                            noofMonths={noofMonths}
                                                        />
                                                    </Elements>
                                                }
                                                </Row>
                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="Name">Bill to name</label>
                                                        <Controller
                                                            name="Name"
                                                            control={control}
                                                            rules={{ required: (!orgDeatails?.Name || orgDeatails.Name === '') ? "Please provide Billing Name" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Name"
                                                                        id="Name"
                                                                        value={orgDeatails?.Name || ''}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Name ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Name && <em className={`error invalid-feedback`}>{errors.Name.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="Email_id">Email</label>
                                                        <Controller
                                                            name="Email_id"
                                                            control={control}
                                                            rules={{ required: (!orgDeatails?.Email_id || orgDeatails.Email_id === '') ? "Please provide Email Address" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="email"
                                                                        name="Email_id"
                                                                        id="Email_id"
                                                                        value={orgDeatails?.Email_id || ''}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Email_id ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.email && <em className={`error invalid-feedback`}>{errors.email.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="Street_1">Address Line 1</label>
                                                        <Controller
                                                            name="Street_1"
                                                            control={control}
                                                            rules={{
                                                                required: orgDeatails?.Street_1?.trim() === "" ? "Please provide Address Line 1" : false, // Check if empty or undefined
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Street_1"
                                                                        id="Street_1"
                                                                        value={orgDeatails?.Street_1 ?? ''}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Street_1 ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Street_1 && <em className={`error invalid-feedback`}>{errors.Street_1.message}</em>}
                                                    </Col>

                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="Street_2">Address Line 2</label>
                                                        <Controller
                                                            name="Street_2"
                                                            control={control}
                                                            rules={{
                                                                required: orgDeatails?.Street_2?.trim() === "" ? "Please provide Address Line 2" : false, // Check if empty or undefined
                                                            }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Street_2"
                                                                        id="Street_2"
                                                                        value={orgDeatails?.Street_2 ?? ''}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Street_2 ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Street_2 && <em className={`error invalid-feedback`}>{errors.Street_2.message}</em>}
                                                    </Col>
                                                </Row>
                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="City">City</label>
                                                        <Controller
                                                            name="City"
                                                            control={control}
                                                            rules={{ required: orgDeatails.City === undefined ? "Please provide City" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="City"
                                                                        id="City"
                                                                        value={orgDeatails.City}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.City ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.City && <em className={`error invalid-feedback`}>{errors.City.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="PinZip_Code">Zip Code</label>
                                                        <Controller
                                                            name="PinZip_Code"
                                                            control={control}
                                                            rules={{
                                                                required: orgDeatails.PinZip_Code === undefined ? "Please provide Zip Code" : "",
                                                                validate: {
                                                                    isNumber: (value) => {
                                                                        return !isNaN(paymentDetails?.refill_min) && paymentDetails?.refill_min !== '' || "Please enter a valid number"; // Check if value is a valid number
                                                                    },
                                                                    inRange: (value) => {
                                                                        const numberValue = parseInt(paymentDetails?.refill_min, 10);
                                                                        if (isNaN(numberValue)) return "Please enter a valid number";
                                                                        if (numberValue < 100) return "Refill Minutes must be at least 100";
                                                                        if (numberValue > 500) return "Refill Minutes cannot exceed 500";
                                                                        return true;
                                                                    },
                                                                }, 
                                                                
                                                                }}
                                                            
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="PinZip_Code"
                                                                        id="PinZip_Code"
                                                                        value={orgDeatails.PinZip_Code}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.PinZip_Code ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.PinZip_Code && <em className={`error invalid-feedback`}>{errors.PinZip_Code.message}</em>}
                                                    </Col>
                                                </Row >

                                                <Row className = {"manage-sub-row"} ml={2} me ={2}>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="State">State</label>
                                                        <Controller
                                                            name="State"
                                                            control={control}
                                                            rules={{ required: orgDeatails.State === undefined ? "Please provide State" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="State"
                                                                        id="State"
                                                                        value={orgDeatails.State}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.State ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.State && <em className={`error invalid-feedback`}>{errors.State.message}</em>}
                                                    </Col>
                                                    <Col md={6} className='field-section'>
                                                        <label className={'form-label'} htmlFor="Country">Country</label>
                                                        <Controller
                                                            name="Country"
                                                            control={control}
                                                            rules={{ required: orgDeatails.Country === undefined ? "Please provide Country" : "" }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <input
                                                                        {...field}
                                                                        type="text"
                                                                        name="Country"
                                                                        id="Country"
                                                                        value={orgDeatails.Country}
                                                                        autoComplete={"off"}
                                                                        onChange={(e) => {
                                                                            onBillInputChange(e);
                                                                            field.onChange(e);
                                                                        }}
                                                                        className={`form-control ${errors.Country ? 'is-invalid' : ''}`}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        {errors.Country && <em className={`error invalid-feedback`}>{errors.Country.message}</em>}
                                                    </Col>
                                                </Row>
                                                <span className = {"manage-sub-span"}>By confirming your subscription, you authorize {orgDeatails.Organization_Name} to charge a ${formData.plan_cost} monthly fee and add ${formData.addon_cost_per_call} for add-on minutes whenever your balance is low. You may cancel your subscription at any time.</span>
                                                <div className='agent-popover-footer'>
                                                    <img className="manage-sub-img" src={stripe}/>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className='agency-details mb-3'>
                                <div className='field-details'>
                                    <Card className='card-br'>
                                        <CardBody className='pb-2'>
                                            <div className='card-fields profile-title-info d-grid'>
                                                <span class="heading" >Invoice History</span>
                                            </div>
                                        <Payments/>
                                        </CardBody>
                                    </Card>
                                </div>

                            </div>
                            <div className='hide'>
                                <button ref={refSubmitBtn} class="btn btn-outline-primary">Subscribe</button>
                            </div>
                        </form>
                    </Container>
                )
            }      
        </Fragment>
    )

}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);