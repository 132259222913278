import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DropdownToggle, DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownItem
} from 'reactstrap';
import { getSessionValue, parseJSON } from "../../Common/Appcommon";
class BottomNav extends Component {
  constructor(props) {
    super(props);
    let menuList = getSessionValue('menus');
    let menus = (menuList !== '' && menuList !== undefined && menuList !== 'undefined' && menuList !== null) ? parseJSON(menuList) : [];
    this.state = {
      menus: menus,
      activeLinkTo: '/#',
      userProfileName: '',
    };
  }
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    let { menus } = this.state;
    if (menus.length === 0) {
      return <Fragment></Fragment>
    }
    let accountMenu = menus[1];
    return (
      <Fragment>
        <div className="metismenu vertical-nav-menu account-settings-menu">
          <ul className="metismenu-container">
            {
              accountMenu.map((menu) => {
                let icon = menu['icon'];
                let label = menu['label'];
                let to = menu['to'];
                let content = menu['content'];
                if (content === undefined) {
                  return (
                    <li className="metismenu-item">
                      <a className="metismenu-link" href={to}>
                        <i className={`metismenu-icon ${icon}`} />
                        {label}
                      </a>
                    </li>
                  )
                }
                return (
                  <Fragment>
                    <UncontrolledButtonDropdown className='metismenu-item unset-btn-group'>
                      <DropdownToggle color="link" className="p-0" >
                        <li className="metismenu-item">
                          <a  className="metismenu-link " href={to}>
                            <i className={`metismenu-icon ${icon}`} />
                            {label}
                          </a>
                        </li>
                      </DropdownToggle>
                      <DropdownMenu end className="rm-pointers dropdown-menu-md">
                        {
                          content !== null && content.map((help) => {
                            let label = help['label'];
                            let url = help['url'];
                            return (
                              <DropdownItem className="row">
                                <a target="_blank" className='d-flex col-md-12' href={url}>
                                  <span className="pl-1 col-md-12">{label}</span>
                                  <i className="fi fi-bs-arrow-up-right-from-square col-md-2"></i>
                                </a>
                              </DropdownItem>
                            );
                          })
                        }
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Fragment>
                )
              })
            }
          </ul>
        </div>
      </Fragment>
    )
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BottomNav));
