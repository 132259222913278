import React, { useState, useEffect } from 'react';
//External
import {
    Button,
    Input,
} from "reactstrap";
import cx from "classnames";
import requestApi from "../../services/requestApi.service";

const CratioFileUploader = props => {

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);
    const mediaErrorRef = React.useRef(null);
    const [fileName, setFileName] = useState(props.fileName);
    const [mediaError, setMediaError] = useState("");
    useEffect(() => {
        (async () => {
            setFileName(props.fileName);
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [props]);
    const isFormUploader = props.formUploader || false;
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        let fileName = fileUploaded.name;

        if (!isFormUploader) {
            setFileName(fileName);
            props.handleFile(event, props.name);
        } else {
            processFormFileUpload(fileUploaded);
        }
    };

    const scrollToMediaerror = () => {
        if (mediaErrorRef != null && mediaErrorRef.current != null) {
            mediaErrorRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const processFormFileUpload = async (file) => {
        let params = {
            "ENTITY": "processuploadfile",
            "OPERATION": "Uploading img",
            "imageupload": "1",
            "file": file,
            "isOtherApiReq": true,
            "fileparamname": "importpngimage"
        }
        let response = await requestApi.processFileUpload(params);

        if (response.issuccess) {
            setFileName(response.data);
            setMediaError("");
            props.attributes.onChange({}, { name: props.attributes['fieldName'], value: response.data }, props.attributes);
        } else {
            setMediaError(response.errormsg);
        }
    }

    let filesAccept = "";
    let btnLbl = "";
    let mediaUrl = "";

    if (isFormUploader) {
        filesAccept = props.attributes.filesAccept;
        btnLbl = props.attributes.label;
        mediaUrl = props.attributes.value;
    } else {
        filesAccept = props.filesAccept;
        btnLbl = props.btnLbl;
        mediaUrl = props.mediaUrl;
    }

    
    if (fileName !== "No file selected" && fileName !== "" && !isFormUploader) {
        if (mediaError !== "") {
            setMediaError("");
        }
    }
    if (mediaError !== undefined && mediaError !== '' && !isFormUploader) {
        scrollToMediaerror();
    }

    return (
        <>
            <div className={cx(``, {
                'is-invalid': mediaError !== "",
                'dInlineFlex': !isFormUploader
            })} ref={mediaErrorRef}>
                
                <div className="file-selection cursor-pointer" onClick={handleClick}>
                    <span className="field-name">Choose file</span>
                    <span className='file-name'>{fileName === "" ? "No file chosen" : fileName}</span>
                    <input name="file" id="exampleFile" type="file" class="form-control-file hide"></input>
                </div>
                <input
                    accept={filesAccept}
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </div>
            <em className={`error invalid-feedback ${mediaError !== "" ? '' : 'hide'}`}>{mediaError}</em>
        </>
    );
}
export default CratioFileUploader;
