import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { formatDateTime } from '../../Common/Appcommon';

const CallsOverview = ({ rowdata, lablename, nameLabelMapper }) => {
    const agentLabel = [nameLabelMapper['table_250_0_table_250_id'] || "", "AI Agent"];
    const agentNumLabel = [nameLabelMapper['Caller_No'] || "", "Phone Number"];
    const cusNameLabel = [nameLabelMapper['Receiver'] || "", "Customer Name"];
    const customernameLabel = [nameLabelMapper['displayNumber'] || "", "Customer Number"];
    const costLabel = [nameLabelMapper['Call_Cost'] || "", "Cost"];
    const startTimeLabel = [nameLabelMapper['Start_DateTime'] || "", "Started At"];
    const endTimeLabel = [nameLabelMapper['End_DateTime'] || "", "Ended At"];
    const durLabel = [nameLabelMapper['Durationsec'] || "", "Duration"];
    const callInformation = [agentLabel, agentNumLabel, cusNameLabel, costLabel, startTimeLabel, endTimeLabel, durLabel];
    const goalStatusLabel = [nameLabelMapper['Goal_Achievement'] || "", "Goal Status"];
    const ratingLabel = [nameLabelMapper['Remarks'] || "", "Rating"];
    const callSentimentLabel = [nameLabelMapper['Remarks'] || "", "Call Sentiment"];
    const endedReasonLabel = [nameLabelMapper['Call_End_Reason'] || "", "Ended Reason"];
    const callAnalysis = [goalStatusLabel, ratingLabel, callSentimentLabel, endedReasonLabel];

    const [formattedRowData, setFormattedRowData] = useState({});
    const [currentRating, setCurrentRating] = useState(0);
    const sentimentToRating = {
        Poor: 1,
        Neutral: 2.5,
        Good: 4,
        Excellent: 5
    };
    const sentimentToCall = {
        Poor : 'fi fi-rr-sad-tear',
        Neutral:'fi fi-rr-sad',
        Good:'fi fi-tr-smile-beam',
        Excellent:'fi fi-rr-grin-alt'    
    }
    useEffect(() => {
        let updatedData = {
            ...rowdata,
            [startTimeLabel[0]]: formatDateTime(rowdata[startTimeLabel[0]]),
            [endTimeLabel[0]]: formatDateTime(rowdata[endTimeLabel[0]]),
            [costLabel[0]]: "$" + rowdata[costLabel[0]],
            [goalStatusLabel[0]]: rowdata[goalStatusLabel[0]] === 'Yes' ? 'Complete' : 'Incomplete',
        };
        setFormattedRowData(updatedData);
        let initialRating = sentimentToRating[rowdata[ratingLabel[0]]] || 0;
        setCurrentRating(initialRating);
    }, [rowdata]);

    const ratingData = () => {
        const starStyle = { fontSize: '18px', margin: '2px', cursor: 'pointer' };
        return (
            <span>
                {[...Array(5)].map((_, index) => {
                    const starValue = index + 1;
                    const isHalfStar = currentRating === 2.5 && starValue === 3;

                    return (
                        <i
                            key={starValue}
                            className={
                                starValue <= currentRating
                                    ? 'fi fi-ss-star' // Full star
                                    : isHalfStar
                                    ? 'fi fi-ss-star-sharp-half-stroke' // Half star for Neutral
                                    : 'fi fi-rs-star' // Empty star
                            }
                            style={starStyle}
                            aria-hidden="true"
                        ></i>
                    );
                })}
            </span>
        );
    };


    const emoji = (value) =>{
        const starStyle = { fontSize: '14px', marginRight: '8px' };
        return (
            <span>
                <i className={sentimentToCall[value]} style={starStyle}></i> 
                {value}
            </span>     
        );
    }
    return (
        <Fragment>
            <div className='overview-container'>
                <div className='overview-header'>
                    <span className='call-overview-info'>Call Information</span>
                </div>

                {callInformation.map((field, index) => (
                    <div className='calls-overview-div' key={index}>
                        <span className='calls-overview-fieldlabel'>{field[1]}</span>
                        <span className='calls-overview-fieldvalue'>
                            {formattedRowData[field[0]]}
                        </span>
                    </div>
                ))}

                <div className='overview-header'>
                    <span className='call-overview-info'>Call Analysis</span>
                </div>

                {callAnalysis.map((field, index) => (
                    <div className='calls-overview-div' key={index}>
                        <span className='calls-overview-fieldlabel'>{field[1]}</span>
                        <span className='calls-overview-fieldvalue'>
                            {field[1] === 'Rating' ? ratingData() : field[1] === 'Call Sentiment' ? emoji(formattedRowData[field[0]]) : formattedRowData[field[0]]}
                        </span>
                    </div>
                ))}
            </div>
            
        </Fragment>
    );
};

export default CallsOverview;
