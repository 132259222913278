import React, { Fragment } from 'react';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import PhoneNumberContent from "./PhoneNumberContent";
import { showToast,processImportNumber,populateFormDetails,getTemplatesFormDetails,loadFormData,prepareLabelNameMapper, syncOperation } from '../../Common/Appcommon';
//import OpenImportModal from "./ImportTwillo";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Container, Button } from "reactstrap";
import SearchBox from "../Conversations/SearchBar";
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';

class PhoneNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            numbersList: [],
            pnlist: [],
            isContactOpen: false,
            rowData: "",
            isImportOpen: false,
            isLoading: true, 
            nameLabelMapper: {},
            formDetails:[],
        };
    }
    componentDidMount = async () => {
        await syncOperation("START_PN_SYNC");
        this.loadPhoneNumberDetails();
           
    };


    loadPhoneNumberDetails =  async () =>{
        let formDetail = await populateFormDetails("table_247", "");
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail);
        let usersData = await loadFormData("table_247", formDetail, selectedTemplate, "");
        if (usersData.issuccess) {
            let { formData, listFields } = usersData;
            let nameLabelMapperList = await prepareLabelNameMapper(formDetail[1]);

            this.setState({
                pnlist: formData,
                nameLabelMapper:nameLabelMapperList,
                filteredPn: formData,
                isLoading: false,
                formDetails :formDetail,
            });
        }
            
    }
    
    addTwilioMobileNumber = (number) => {
        let contactNumber = [...this.state.numbersList, number];
        this.setState({ numbersList: contactNumber });
        let message = "Error! Limit reached.";
        showToast(message, 'top-right', "error");

    }

    handleNumbersModal = (name) => {
        if (name === "addnumber") {
            this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
        } else {
           // this.setState(prevState => ({ isImportOpen: !prevState.isImportOpen }));
        }
    }

    handleSearch = (query) => {
        this.setState({ searchQuery: query });

        let filteredPn = this.state.pnlist.filter(phoneNumber => {
            const phone = phoneNumber[this.state.nameLabelMapper['Phone_Number']].toLowerCase();
            return phone.includes(query.toLowerCase());
        });

        this.setState({ filteredPn });
    };

    openPhoneConfig = (data) => {
        this.setState({
            isContactOpen: true,
            rowData: data
        });
    }

    backToPnNumber = () => {
        this.setState({
            isContactOpen: false,
            rowData: {}
        });
    }

    closeModal = (name) => {
        if (name === "addnumber") {
            this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
        } else {
            //this.setState(prevState => ({ isImportOpen: !prevState.isImportOpen }));
        }
    }

    handleImportNum =  async (importNumber,operation) =>{
        let isimport = await processImportNumber(importNumber,operation); 
                
        if(operation == "ADD"){
            this.closeModal("addnumber"); 
            this.loadPhoneNumberDetails();
        } else if(operation == "DELETE"){
            this.backToPnNumber();
        }
    }


    deletePN = async (importNumber) =>{
        this.handleImportNum(importNumber,"DELETE");
    }

    addImportedPN = async (importNumber) =>{
        this.handleImportNum(importNumber,"ADD");
    }
   
    

    getModalContent = () => {
        const { isModalOpen, isLoading, error,nameLabelMapper,filteredPn } = this.state;
        return (
            <Modal isOpen={isModalOpen} toggle={this.handleNumbersModal}>
                <ModalHeader toggle={() => this.closeModal("addnumber")}>
                    Add USA number
                </ModalHeader>
                <SearchBox className='search-box' moduleName="PhoneNumber" onSearch={this.handleSearch} />

                <ModalBody>
                    <Fragment>
                    <div className='number-list-wrap'>
                            {isLoading ? (
                                <div>Loading numbers...</div>
                            ) : error ? (
                                <div>{error}</div>
                            ) : filteredPn.length > 0 ? (
                                filteredPn.map((field, index) => (
                                    <div className='search-numbers' key={index} onClick={() => this.addImportedPN(field)}>
                                        <span>{field[nameLabelMapper['Phone_Number']]}</span>
                                    </div>
                                ))
                            ) : (
                                <div className='numbers-not-found'>No results found for your search.</div>
                            )}
                        </div>
                    </Fragment>
                </ModalBody>
                <ModalFooter>
                    {/*<button className='container-footer-save' onClick={() => this.addTwilioMobileNumber()}>
                        Add Number
                            </button>*/}
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const { isContactOpen, isModalOpen, pnlist, isImportOpen, isLoading, error,nameLabelMapper,formDetails } = this.state;
        return (
            <Fragment>
                {!isContactOpen && (
                    <Common
                        titleContent={
                            <span>
                                PhoneNumber
                                <span className='container-heading-count number-count'>
                                    {isLoading ? '...' : pnlist.length > 0 ? pnlist.length : 0}
                                </span>
                            </span>
                        }
                        mainContent={
                            <>
                                {isLoading ? (
                                    <div>Loading phone numbers...</div>
                                ) : error ? (
                                    <div>{error}</div>
                                ) : (
                                    <>
                                        {pnlist.length > 0 ? (
                                        <div className='agent-details agent_details-wid phone-numbers-container'>
                                            <div className="card-fields">
                                                <span className="heading">Your numbers</span>
                                                <span className="subheading">Manage phone number and calls</span>
                                            </div>
                                            {pnlist.map((data, index) => (
                                                <div className='number-hover' key={index} onClick={() => this.openPhoneConfig(data)}>
                                                    <span className='cloud-number-logo'>
                                                        <i class="fi fi-rr-mobile-button"></i>
                                                    </span>
                                                    <span className='cloud-number-name-number'>
                                                        <p className="name">{data[nameLabelMapper["Name"]]}</p>
                                                        <p className="number">{data[nameLabelMapper["Phone_Number"]]}</p>
                                                    </span>
                                                    <span className='settings-i-cont'>
                                                    <i class="fi fi-ss-settings"></i>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <NoRecordFound
                                        img={aiData}
                                        isOnlyContent={true}
                                        content={"No Numbers Found."}
                                    />
                                    )}

                                    </>
                                )}
                            </>


                        }
                        titleBody={
                            <div className='settings-phonenumber-titlebody'>
                                 {/*<button className='container-footer-save' onClick={() => this.handleNumbersModal("addnumber")}>
                                    <img className='container-footer-save-icon' src={addNumber} alt="Add Number" />
                                    Add Number
                                </button>
                                <button className='container-footer-cancel' onClick={() => this.handleNumbersModal("import")}>
                                    <img className='container-footer-save-icon' src={importNumber} alt="Import Number" />
                                    Import number
                                </button>*/}
                            </div>
                        }
                    />
                )}
                {isModalOpen && this.getModalContent()}
                {isContactOpen && <PhoneNumberContent rowData={this.state.rowData} backToPN={this.backToPnNumber} deletePN = {this.deletePN} pnLabelMapper = {nameLabelMapper} pnFormDetails ={formDetails}/>}
                {/*isImportOpen && <OpenImportModal isImportModalOpen={isImportOpen} handleNumbersModal={this.handleNumbersModal} closeModal={this.closeModal} />*/}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);
