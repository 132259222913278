import React, { Fragment } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { getSessionValue,clearSessionValue,getLogoutURL,clearLegacyCookie,handleErrorResponse } from '../../Common/Appcommon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoutIcon from "../../assets/utils/images/arrow-right.svg"
import requestApi from '../../services/requestApi.service';

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {

    };
    logoutApp = async (e) =>{
        let activeSessionCount = 1;
        let params = {
            "ENTITY" : "session_check",
        }
        let sessionResponse = await requestApi.processServerRequest(params);
        if(sessionResponse.issuccess){
            activeSessionCount = sessionResponse.data['activeSessions'];
            let params = {
                "ENTITY": "logout",
                'logoutfromall': false
            };
            clearLegacyCookie();
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                clearSessionValue();
                if(response.data != "" ){
                    window.location = response.data; 
                }else{
                    window.location = getLogoutURL();       
                }
            } else {
                handleErrorResponse(response.errormsg);
            }
        }
    }       

    render() {
        let userName = getSessionValue("loginuser");
        let userEmail = getSessionValue("loginemail");
        return (
            <Fragment>
                <Common titleContent={
                    <span>Profile</span>
                } mainContent={
                    <div className='container'>
                    <div className='agency-details'>
                        <div className='card-fields d-grid'>
                            <span class="heading">Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Email</span>
                            <span className='profile-desc'>{userEmail}</span>
                        </div>
                        <div className='card-fields hide'>
                            <span class="heading">Business Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div>
                        <button onClick={(e) => this.logoutApp(e)} className='btn btn-light theme-bg-g custom-display'><i class="fi fi-bs-sign-out-alt"></i><span className='pl-1'>Log out</span></button>
                        </div>
                    </div>
                    </div>
                } />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
