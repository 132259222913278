import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from "../AppLogo";

import { getAgencyInfo } from "../../Common/Appcommon";
import Link from "../../Link";


class SplitHeader extends React.Component {
  render() {
    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
      sideBarTitleContent,
      mainTitleContent,
      headerNotifyShow,
    } = this.props;
    let agencyLogoInfo = getAgencyInfo('FavIcon');
    let agencyLogo = "";
    if (agencyLogoInfo !== "" && agencyLogoInfo !== undefined && agencyLogoInfo !== null) {
      if (agencyLogoInfo.indexOf(",") > 0) {
        let agentLArr = agencyLogoInfo.split(",");
        agencyLogo = agentLArr[1];
      }
    }
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div"
            className={cx("app-header", {"header-notify" : headerNotifyShow}, headerBackgroundColor, {
              "header-shadow": enableHeaderShadow,
            })}
            appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <Link icon={agencyLogo} />
              <HeaderLogo />
              <div className={cx("app-header__content app-split-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                })}>
                <div className="app-header-left">
                  <div className="app-header-title">{sideBarTitleContent}</div>
                </div>
                {mainTitleContent}
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SplitHeader);
