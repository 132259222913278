import React, { Fragment } from "react";
import { Col, Card, CardBody, Container } from "reactstrap";
import DataTable from 'react-data-table-component';

const ListTable = (props) => {
  let { data, columns, isDashboard } = props

  const customStyles = {
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: '700',  // Custom font size for header cells
        fontWeight: 'bold',  // Optional: Make headers bold
        color: '#000000',
        alignItems: 'left'
      },
    },
    table: {
      style: {
        borderRadius: '10px',
        overflow: 'hidden'
      }
    },
    table: {
      style: {
        borderRadius: '10px',
        overflow: 'hidden'
      }
    },
    cells: {
      style: {
        fontSize: '14px',  // Custom font size for data cells
      },
    },
    rows: {
      style: {
        minHeight: '40px', // Ensure each row has a minimum height (optional)
      },
    },
    tableWrapper: {
      style: {
        //minHeight: '70vh', // Set a minimum height for the table
        //maxHeight: '70vh', // Set a minimum height for the table
      },
    },
    pagination: {
      style: {
        borderTop: '0',
        display: 'flex',
        justifyContent: 'center',  // Center the pagination component
        alignItems: 'center',
        paddingTop: '8px',
      },
    }
  };

  const paginationOptions = {
    noRowsPerPage: true, // Hides the "Rows per page" option
  };
  if (isDashboard) {

    if(!data){
      return (
        <Fragment>
          
        </Fragment>
      )
    }

    return (
      <Fragment>
        <div className="dashboard-table-container">
          <Col md="12">
            <Card className="main-card scrollable-table">
              <CardBody>
                <DataTable
                  data={data}
                  columns={columns}
                  pagination={data.length > 10}
                  fixedHeader
                  fixedHeaderScrollHeight="100vh"
                  className="list-data-table"
                  customStyles={customStyles}
                  paginationComponentOptions={paginationOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </div>
      </Fragment>
    )
  }
  return (
    <>
      <Fragment>
          <div className="p-3">
            <Col md="12">
              <Card className="main-card scrollable-table">
                <CardBody>
                  <DataTable
                    data={data}
                    columns={columns}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="100vh"
                    className="list-data-table"
                    customStyles={customStyles}
                    
                  />
                </CardBody>
              </Card>
            </Col>
          </div>
      </Fragment>
    </>
  )
}

export default ListTable;
