import React, { Fragment, useState, useEffect,useMemo } from 'react';
import usercontact from "./assets/images/transcript-contact.svg";
import SearchBox from "./SearchBar";
import { formatTime } from '../../Common/Appcommon';

const CallsTranscript = (props) => {
    let { rowdata, nameLabelMapper } = props; 
    let [parsedTranscript, setParsedTranscript] = useState([]); 
    let [filteredSummary, setFilteredSummary] = useState([]);
    let [searchTerm, setSearchTerm] = useState("");
    const mappedCallTranscript = useMemo(() => {
        return rowdata?.[nameLabelMapper?.["Call_Transcript"]];
    }, [rowdata, nameLabelMapper]);
   
    useEffect(() => {
        const handler = setTimeout(() => {
            try {
                if (mappedCallTranscript) {
                    let transdata = JSON.parse(mappedCallTranscript);
                    setParsedTranscript(transdata);
                } else {
                    setParsedTranscript([]);
                }
            } catch (error) {
                console.error("Failed to parse Call_Summary:", error);
                setParsedTranscript("");
            }
        }, 100); // Debouncing with 300ms

        return () => clearTimeout(handler); // Cleanup the timeout
    }, [mappedCallTranscript]);


    useEffect(() => {
        //if(parsedTranscript !== ""){
            loadTranscript();
        //}
    }, [parsedTranscript, searchTerm]); 

    let handleSearch = (term) => {
        setSearchTerm(term.toLowerCase()); 
    };

    let loadTranscript = () => {
        let filtered =[];        
        if(parsedTranscript !== ""){
            console.log("parsedTranscript 1 ");
            filtered = parsedTranscript.filter((field) =>
                field.message && field.message.toLowerCase().includes(searchTerm)
            );
        }
        setFilteredSummary(filtered);
    };

    return (
        <Fragment>
            <SearchBox onSearch={handleSearch} value={parsedTranscript}/>
            <div className='call-transcript-container'>
                {filteredSummary.length > 0 ? (
                    filteredSummary.map((field, index) => (
                        <div key={index}>
                            {field.role === "bot" && (
                                <div className='transcript-bot-msg'>
                                    <div className='transcript-dur'>
                                        <span className='transcript-bot-name'>AI Agent |</span>
                                        <span className='transcript-bot-dur-span'>{formatTime(field.secondsFromStart)}</span>
                                    </div>
                                    <div className='transcript-bot-msg-width'>
                                        <div>{field.message}</div>
                                    </div>
                                </div>
                            )}
                            {field.role === "user" && (
                                <div className='transcript-user-msg'>
                                    <div className='transcript-user-header'>
                                        <span>{formatTime(field.secondsFromStart)}</span>
                                        <span> | User</span>
                                    </div>
                                    <div className='transcript-bot-msg-width'>
                                        <div>{field.message}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className='mt-5'>No results found for your search.</div>
                )}
            </div>
        </Fragment>
    );
};

export default CallsTranscript;
