import React, { Fragment ,useEffect,useState} from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import cx from "classnames";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import AppSidebar from '../../Layout/AppSidebar';
import AppHeader from '../../Layout/AppHeader/SplitHeader';
import Loading from '../../Common/Loading';
import { getHeaderNotification } from '../../Common/Appcommon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const SplitCommon = (props) => {
    const [notification, setNotification] = useState({show:false})
    let { sideBarContent, mainContent, sideBarTitleContent, mainTitleContent, title, sideBarLoader, mainLoader } = props;
    if (title !== undefined && title !== "") {
        document.title = title;
    }
    useEffect(() => {

        async function getNotification() {
            let headernotification = await getHeaderNotification();
            setNotification(headernotification);
        }
        getNotification();
    }, [])
    let loaderType = "ball-clip-rotate";

    const oncloseAction = () =>{
        setNotification(false);
    }
    return (
        <Fragment>
            <div className={cx('header-notification', {
                'hide': !notification.show
            })}>
                <div className={'header-notification-body'}>
                    <>
                        {notification.icon && <FontAwesomeIcon icon={faInfoCircle} />}
                        {notification.text}
                    </>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={(e) => { oncloseAction(e) }} ></button>
            </div>
            <AppHeader
                sideBarTitleContent={sideBarTitleContent}
                mainTitleContent={mainTitleContent}
                headerNotifyShow={notification.show}
            />
            <div className={cx('app-main', {
                'header-notify-active': notification.show
            })}>
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner split-app-outer">
                        <div>
                            <div className={cx("app-inner-layout chat-layout", {
                                "open-mobile-menu": false,
                            })}>
                                <div className="app-inner-layout__wrapper">
                                    <div className='app-inner-layout__content'>
                                        <Loading className={`w-100`} showLoader={mainLoader} mainContent={
                                            <div className="w-100">
                                                {mainContent}
                                            </div>
                                        }/>
                                    </div>
                                    <div className="app-inner-layout__sidebar">
                                        <div className={`w-100 ${sideBarLoader ? 'hvh-100' : ''}`}>
                                            <Loading showLoader={sideBarLoader} mainContent={sideBarContent}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SplitCommon);
