import React, {Fragment} from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import {connect} from "react-redux";
import { validateLogin } from '../../Common/Appcommon';
class Login extends React.Component{
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        await validateLogin(this.props.history);
    };


    render() {
        return (
            <Fragment>
                <div >
                    Loading
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
