import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Common from '../Common';
import Select from "react-select";
import mobileIcon from "../Conversations/assets/images/mobile_img.svg";
import back_icon from "../Conversations/assets/images/back_icon.svg";
import { getReferenceList } from '../../Common/Appcommon';


import emojiIcon from "../Conversations/assets/images/emoji.svg";

import {
    Row,
    Col,
    Container,
    Button
} from "reactstrap";


const PhoneNumberContent = (props) => {
    const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const saveBtnRef = useRef(null);
    let { rowData, backToPN, deletePN, pnLabelMapper, pnFormDetails } = props;

    let [pnFormData, setFormData] = useState(rowData);
    let [defaultDpDown, setDefaultDpDown] = useState([]);
    let [agentDpDown, setAgentDpDown] = useState([]);
    useEffect(() => {
        if (rowData && !pnFormData) {
            setFormData(rowData);
        }
        (async () => {
            await fetchAgencyDetails();
        })();
    }, [rowData]);

    const fetchAgencyDetails = async () => {
        let fieldName = pnLabelMapper['table_250_0_table_250_id'];
        let defaultAgent = pnFormData[fieldName + "_id"];
        let fDetail = pnFormDetails[1];

        let refDetail = fDetail.filter(record => record[2] === 'table_250_0_table_250_id')[0];
        if(refDetail && refDetail[35] !== undefined){
            let res = await getReferenceList(refDetail[35], defaultAgent);
            if (res.issuccess) {
                let optionList = res.data;
                setAgentDpDown(optionList[0]);
                setDefaultDpDown(optionList[1]);
            }
        }
    }

    const submitAgencyDetails = async () => {

    }

    const onInputChange = (e) => {
        let { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [pnLabelMapper['Name']]: value  // Update based on the mapped name
        }));
    };


    const onSaveBtnClick = () => {
        saveBtnRef.current.click();
    }

    const handleFileClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleChange = async (option) => {
        setDefaultDpDown(option);
    }

    return (
        <Fragment>
            <Common title="Agency Details" titleContent={
                <div className='container-heading-title'>
                    <span >
                        <img className='mat-icon' src={back_icon} onClick={(e) => { backToPN(e) }} />
                    </span>
                    {<span>{pnFormData[pnLabelMapper['Name']]} ( {pnFormData[pnLabelMapper['Phone_Number']]} )</span>}
                </div>
            }
                titleBody={
                    <Fragment>{/*<Button onClick={(e) => { onSaveBtnClick(e) }} className="mb-2 me-2 btn-hover-shine" color="light">Save</Button>*/}</Fragment>
                }
                mainContent={
                    <Container>
                        <form id="phone-number-content-form" name={'phonenumber'}>
                            <div className='agency-details with-border'>
                                <div className='card-fields d-grid'>
                                    <span className="heading" >Icon</span>
                                    <span className='cloud-number-logo'>
                                        <i class="fi fi-rr-mobile-button"></i>
                                    </span>
                                </div>
                                <div className='field-details'>
                                    <Row>
                                        <Col md={6} className='field-section mt-2'>
                                            <label className={'form-label'} htmlFor="agentName">Name</label>
                                            <Controller
                                                name="agentName"
                                                control={control}
                                                disabled={true}
                                                rules={{ required: "This field is required" }}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        id="agentName"
                                                        name="agentName"
                                                        value={pnFormData[pnLabelMapper['Name']] || ''}
                                                        onChange={(e) => {
                                                            onInputChange(e);
                                                            field.onChange(e);
                                                        }}
                                                        className={`form-control ${errors.agentName ? 'is-invalid' : ''}`}
                                                    />
                                                )}
                                            />
                                            {
                                                errors.agentName && <em className='field-error'>{errors.agentName.message}</em>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className='field-section mt-2'>
                                            <label className={'form-label'} htmlFor="agentMobile">Phone number</label>
                                            <Controller
                                                name="agentMobile"
                                                control={control}
                                                disabled={true}
                                                rules={{ required: "This field is required" }}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        id="agentMobile"
                                                        name="agentMobile"
                                                        value={pnFormData[pnLabelMapper['Phone_Number']] || ''}
                                                        onChange={(e) => {
                                                            onInputChange(e);
                                                            field.onChange(e);
                                                        }}
                                                        className={`form-control ${errors.agentMobile ? 'is-invalid' : ''}`}
                                                    />
                                                )}
                                            />
                                            {
                                                errors.agentMobile && <em className='field-error'>{errors.agentMobile.message}</em>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='agency-details  with-border'>
                                <div className='card-fields d-grid'>
                                    <span className="heading" >AI Agent</span>
                                    <span className='header-desc'>Assign agent to phone number</span>

                                </div>
                                <div className='field-details'>
                                    <Row>
                                        <Col md={6} className='field-section'>
                                            <label className={'form-label'} htmlFor="brand_name">Select agent</label>
                                            <Select
                                                isDisabled={true}
                                                isClearable={false}
                                                onChange={(eve) => {
                                                    handleChange(eve)
                                                }}
                                                placeholder={"Choose a ai name"}
                                                options={agentDpDown}
                                                value={defaultDpDown}

                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='agency-details  with-border'>
                                <div className='card-fields d-grid'>
                                    <span className="heading" >Telephony provider</span>
                                    <span className='header-desc'>{pnFormData[pnLabelMapper['Provider']]}</span>

                                </div>

                            </div>
                            <div className='agency-details hide'>
                                <div className='card-fields d-grid'>
                                    <span className='header-desc'>Delete</span>
                                    <span className="heading" >Delete your phone number from your account</span>
                                </div>
                                <div className='field-details delete-number'>
                                    {/*<button className='container-footer-cancel' onClick={(e) => { deletePN(pnFormData) } }> 
                                        Delete phone number 
                                    </button>*/}
                                </div>
                            </div>
                            <div className='hide'>
                                {/*<button ref={saveBtnRef} onClick={handleActionFormSubmit((data, event) => submitAgencyDetails(event))} className="btn btn-outline-primary">Save & Activate</button>*/}
                            </div>
                        </form>
                    </Container>

                } />


        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberContent);
