import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { providers,providersSelectList } from './assets/css/constants';
import Form from '../../Common/Form';
import Select from "react-select";
import requestApi from '../../services/requestApi.service';
import { getAppidFromCookie, handleErrorResponse, parseJSON, showToast,setSessionValue } from '../../Common/Appcommon';

const ProviderCredentials = (props) => {
    const {accountInfo} = props;
    let {data_row} = accountInfo;
    //const { register, handleSubmit: handleActionFormSubmit, control, formState: { errors }, setError } = useForm();
    const [formData, setFormData] = useState({});
    const [selectProviderList, setSelectproviderList] = useState(providersSelectList[0]);
    const [provider , setProvider] = useState(providersSelectList[0].value);


    useEffect(async () => {
        await getproviderData();
    }, []);

    /*
        const numbers = [1, 2, 3, 4];
        reduce() is a method in JavaScript used to "reduce" an array into a single value, 
        typically an object, array, or even a number. 
        It applies a function to each item in the array and accumulates a result, which is passed on to the next iteration.
        // Using reduce to sum the numbers
        const sum = numbers.reduce((acc, num) => {
            return acc + num;
        }, 0);  // Initial accumulator value is 0
    */

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitProviderCredentials = async (name,formData) => {
        let providerData = formData;
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'SAVE_API_DETAILS',
            'PROVIDER_DATA' : providerData,
            'PROVIDER' : provider,
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNTID" : appid
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast("Success! Provider details saved successfully.");
            await getproviderData();
        }else{
            if(response.errormsg != ""){
                handleErrorResponse(response.errormsg);
            }else{
                handleErrorResponse("Deatils Not Saved.Please Provide Valid API Key.");
            }
        }
    }

    const getproviderData = async () => {
        
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'GET_API_DETAILS',
            "SUBACCOUNTID" : appid
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data['providerDetails'];
            let customProvider = response.data['provider'];
            if(data !== undefined && data.length > 0){
                data = parseJSON(data);
                setFormData(data);
                setProvider(customProvider); 
                if(customProvider === "vapi"){
                    setSelectproviderList(providersSelectList[0]);
                }else if(customProvider === "bland"){
                    setSelectproviderList(providersSelectList[1]);
                }else if(customProvider === "retell"){
                    setSelectproviderList(providersSelectList[2]);
                }
            }
        }
    }

    const syncProviderDetails = async() =>{
        let appid = (data_row.appid !== undefined && data_row.appid) !== "" ? data_row.appid : "";
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'SYNC_API_DETAILS',
            "SUBACCOUNTID" : appid
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast("Initiated! Syncing data…");
        }else{
            handleErrorResponse("Data not synced.Please try after some time.");
        }
    }

    const handleChange = async(option) =>{
        setSelectproviderList(option);
        setProvider(option.value);
        setFormData({});
    }

    return (
        <Fragment>

          
            <div className='provider-container'>
                <Select
                    isClearable={false}
                    onChange={(eve) => {
                        handleChange(eve)
                    }}
                    placeholder={"Choose a ai name"}
                    options={providersSelectList}
                    value={selectProviderList}
                    className='provider-creaditials-select basic-multi-select me-4'
                    classNamePrefix="wa-select"
                />
                <div>
                    <Row>
                        {providers.map((provider, index) => {
                            const { name, label, url, description, fields } = provider;
                            const {data_row} = accountInfo;
                            let providerName = selectProviderList.value;
                            if(name == selectProviderList.value){
                                return (
                                    <Fragment>
                                                                                            {
                                        (providerName != "") ? (
                                        <Col md={12}>
                                            <Card>
                                                <CardBody className='agency-details'>
                                                            <div className='col-md-12 card-fields pb-3 bb-1'>
                                                                <Row>
                                                                    <>
                                                                        <Col md={7}>
                                                                            <div class="d-grid">
                                                                                <span class="provider-header">{label} 
                                                                                    <a className="ps-2" tooltip="The providers API page" target='_blank' href={url}><i className="pe-7s-link"></i></a>
                                                                                </span>
                                                                                <span class="header-desc">{description}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={5}>
                                                                        <div className='d-flex justify-end'>
                                                                            {
                                                                                Object.keys(formData).length > 0 && 
                                                                                (
                                                                                <Button size="lg" className='theme-bg-g d-flex flex-end' onClick={syncProviderDetails}>
                                                                                Sync {providerName.toUpperCase()+' Account'}
                                                                                </Button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        </Col>
                                                                    </>
                                                                </Row>
                                                            </div>
                                                   
                                                    <div>
                                                        <Form submitAction={submitProviderCredentials} pname={name} fields={fields} formData={formData} account_id={data_row.account_id !== undefined && data_row.account_id !== "" ? data_row.account_id : ""}/>
                                                    </div> 
                                                </CardBody>
                                            </Card>
                                        </Col>
                                             )
                                        : 
                                        <div className='not-valid-provider'>
                                        <span>Coming Soon</span>
                                        </div>}
                                    </Fragment>
                                )
                            }
                           
                        })}
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCredentials);
