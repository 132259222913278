import React, { Fragment, Component } from "react";
import { fetchAgencyDetails, getCurrentDomain, showToast } from "../../Common/Appcommon";
import AppLogo from "../../assets/utils/images/logo.png";
import Link from "../../Link.js";
import siteRequestApiService from "../../services/siteRequestApi.service";

import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.recoverFormRef = React.createRef();
    this.state = {
      errorEmail: { message: "", isValid: true },
      isLoading: false,
      brandLogo: "",
      agencyLogo: [],
    };
  }

  componentDidMount = async () => {
    document.title = 'Forgot your password?';
    let agencyData = await fetchAgencyDetails();
    if (agencyData.length > 0) {
      this.setState({
        agencyLogo: agencyData
      })
    } else {
      this.setState({
        brandLogo: AppLogo
      })
    }
  };

  resetAction = async (e) => {
    e.preventDefault();
    let emailVal = this.recoverFormRef.current.querySelector(
      "input[type='email']"
    );

    let emailError = { message: "", isValid: true };

    if (emailVal.value == "") {
      emailError.message = "Email can't be empty!";
      emailError.isValid = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal.value)
    ) {
      emailError.message = "Provide valid email address!";
      emailError.isValid = false;
    }

    if (emailError.isValid) {
      this.setState({
        isLoading: true,
      });
      let subDomain = getCurrentDomain();
      let queryString =
        "productid=" +
        process.env.REACT_APP_SITE_PRODUCT_ID +
        "&email=" +
        emailVal.value +
        "&operation=SendResetLink";
        let queryParams = {
          "productid": process.env.REACT_APP_SITE_PRODUCT_ID,
          "email": emailVal.value,
          "operation": "SendResetLink",
          "domain" : subDomain
        }
      let response = await siteRequestApiService.processRequest(
        "resetPassword",
        "",
        queryParams
      );

      if (response.status != undefined) {
        this.setState({
          isLoading: false,
        });
        showToast(
          response.message,
          "top-right",
          response.status ? "success" : "error",
          undefined,
          5000
        );
      }
    }
    emailVal.value = "";
    this.setState({
      errorEmail: emailError,
    });
  };

  render() {
    let { brandLogo, agencyLogo } = this.state;
    let favIcon = "";
    if (agencyLogo.length > 0) {
      brandLogo = agencyLogo[2];
      favIcon = agencyLogo[1];
    }

    if(brandLogo === ""){
      return (
        <Fragment>...</Fragment>
      )
    }
    return (
      <Fragment>
        <div className="h-100">
          <Fragment>
            <Link icon={favIcon} />
            <div className="h-100 bg-dark-shadow bg-animation">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                  <div className="app-logo-inverse mx-auto mb-3 text-center" >
                    <img src={brandLogo} />
                  </div>
                  <div className="modal-dialog w-100">
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="h5 modal-title">
                          Forgot your Password?
                          <h6 className="mt-1 mb-0 opacity-8">
                            <span>Use the form below to recover it.</span>
                          </h6>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div ref={this.recoverFormRef}>
                          <Form>
                            <Row form>
                              <Col md={12}>
                                <FormGroup>
                                  <Label for="exampleEmail">Email</Label>
                                  <Input
                                    className={
                                      !this.state.errorEmail.isValid ? "is-invalid form-control-blue" : "form-control-blue"
                                    }
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email here..."
                                    autoComplete="off"
                                  />
                                  {!this.state.errorEmail.isValid && (
                                    <Label className="errors" for="errorEmail">
                                      {this.state.errorEmail.message}
                                    </Label>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="divider" />
                            <div className="mt-4 d-flex align-items-center">
                              <h6 className="mb-0">
                                <a href="#/login" target="_blank" className="btn-lg btn theme-c-g vm-btn-link hover-underline">
                                  Sign in existing account
                                </a>
                              </h6>
                              <div className="ms-auto">
                                <Button size="lg" className="theme-bg-g" type="submit" color="dark"
                                  disabled={this.state.isLoading}
                                  onClick={(e) => {
                                    this.resetAction(e);
                                  }}
                                >
                                  {this.state.isLoading
                                    ? "Please Wait..."
                                    : "Send Reset Link"}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}
