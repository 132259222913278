import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import WaveSurfer from 'wavesurfer.js';
import cx from "classnames";
import Tabs from "react-responsive-tabs";
import { Row, Col } from "reactstrap";
import CallsSummary from "./CallsSummary";
import CallsOverview from "./CallsOverview";
import CallsTranscript from "./CallsTranscript";
import Loading from '../../Common/Loading';
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';


const ConversationConfig = (props) => {
    let { rowData, nameLabelMapper } = props;
    const waveformRef = useRef(null);
    const [waveSurfer, setWaveSurfer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [tabItems, setTabItems] = useState({});
    const [showLoading, setShowLoading] = useState(false);
    let [audioUrl, setRecordingUrl] = useState("");

    const subtables = [
        {
            name: "Overview",
            biography: <CallsOverview rowdata={rowData} lablename={"Call Information"} nameLabelMapper={nameLabelMapper} />,
        },
        {
            name: "Summary",
            biography: <CallsSummary rowdata={rowData} lablename={"Summary"} nameLabelMapper={nameLabelMapper} />,
        },
        {
            name: "Transcript",
            biography: <CallsTranscript rowdata={rowData} lablename={"Summary"} nameLabelMapper={nameLabelMapper} />,
        },
        // {
        //     name: "Activity",
        //     biography:
        //         "Comming soon",
        // },
        // {
        //     name: "Comments",
        //     biography:
        //         "Comming soon",
        // },
    ];

    useEffect(() => {
        if (rowData !== undefined && rowData !== null && Object.keys(rowData).length > 0) {
            let callerLabel = nameLabelMapper['Record_url'] !== undefined ? nameLabelMapper['Record_url'] : "";
            let recordingUrl = rowData[callerLabel];
            if (recordingUrl !== undefined && recordingUrl !== "") {
                setShowLoading(true);
                let urlArr = recordingUrl.split(",");
                let url = urlArr[0];
                setRecordingUrl(url);
                waveformRef.current = WaveSurfer.create({
                    container: "#waveform",
                    waveColor: '#ddd',
                    progressColor: '#333',
                    height: 40,
                    responsive: true,
                    barWidth: 3,
                    barHeight: 4
                });

                waveformRef.current.load(url);

                // Event listener when the audio is loaded
                waveformRef.current.on('ready', () => {
                    setDuration(waveformRef.current.getDuration()); // Set the total duration
                    setShowLoading(false);
                });

                // Event listener during playback to update current time
                waveformRef.current.on('audioprocess', () => {
                    setCurrentTime(waveformRef.current.getCurrentTime()); // Update current time
                });

                waveformRef.current.on('finish', () => {
                    setIsPlaying(false); // Reset playback button to "play" when audio ends
                });

                setWaveSurfer(waveformRef.current);
                return () => {
                    waveformRef.current.destroy();
                    setWaveSurfer(null);
                };
            }
            setTabItems(getSimpleTabs());
        }
    }, [rowData]);

    const togglePlayback = () => {
        if (waveSurfer) {
            waveSurfer.playPause();
            setIsPlaying(!isPlaying);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const getSimpleTabs = () => {

        return subtables.map(({ name, biography }, index) => ({
            key: index,
            title: name,
            getContent: () => biography, // Render function if it's a component
        }));
    }

    const downloadAudioFile = () => {
        let startLabel = nameLabelMapper['Start_DateTime'] !== undefined ? nameLabelMapper['Start_DateTime'] : "";
        let startTime = rowData[startLabel].trim();
        startTime = startTime.replace(/ /g,"_");
        fetch(audioUrl)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a link element, use Blob to download the file
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', startTime+'.mp3'); // Set the name of the file
                document.body.appendChild(link);
                link.click();
                link.remove(); // Clean up the DOM
                window.URL.revokeObjectURL(url); // Release the blob URL after download
            })
            .catch((error) => console.error('Error downloading the file:', error));
    }

    if (rowData === undefined || rowData.length === 0) {
        return <Fragment><NoRecordFound
        img={aiData}
        isOnlyContent={true}
        content={"No Calls Found."}
    /></Fragment>
    }
    return (
        <Fragment>
            <div className='main-container'>

                <div className='agent-details conversation-details calls-details p-0'>
                    <Row>
                        <Col md="12">
                            <Tabs tabsWrapperClass="body-tabs" activeTab={"1"}
                                showMore={true}
                                transform={true}
                                showInkBar={true}
                                selectedTabKey={0}
                                transformWidth={400}
                                items={getSimpleTabs()} />
                        </Col>
                    </Row>
                </div>
                <Loading showLoader={showLoading} mainContent={
                    <Fragment><div className="agent-details sticky-component">
                        <audio-stream >
                            <div className={cx("audio-player", {
                                "hide": waveSurfer === null,
                            })}>
                                <button onClick={togglePlayback}>
                                    { false && <FontAwesomeIcon className='theme-c-g' icon={isPlaying ? faPauseCircle : faPlayCircle} />}
                                    {isPlaying ? <i class="icon-color fi fi-rr-pause"></i>  : <i class="icon-color le-form-row fi fi-rr-play"/>}
                                </button>
                                <div className="w-65">
                                    <div id="waveform" style={{ width: '100%' }}></div>
                                </div>
                                <span className="timer">{formatTime(currentTime)} / {formatTime(duration)}</span>
                                <span className="audio-player-download cursor-pointer" onClick={(e) => {downloadAudioFile()}}>
                                    {false &&<FontAwesomeIcon  className='theme-c-g' icon={faDownload} />}
                                    <i class="fi fi-rr-download"></i>
                                </span>
                            </div>
                            {waveSurfer === null && <div className='w-100 text-center'>
                                <span>No Recording's found.</span>
                            </div>}
                        </audio-stream>
                    </div>
                    </Fragment>
                } />

            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationConfig);
