import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';

class TooltipItem extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    render() {
        let {id} = this.props;
        return (
            <span>
                <span className="" color="primary" id={"Tooltip-"+id}>
                    {this.props.content}
                </span>
                <Tooltip placement={"top"} isOpen={this.state.tooltipOpen}
                    target={"Tooltip-"+id} toggle={this.toggle}>
                    {this.props.text}
                </Tooltip>
            </span>
        );
    }
}

export default TooltipItem;
